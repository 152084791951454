import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext, CustomerPortalContext } from '../Contexts';

import { Container } from '../Components/Container/Container';
import { Title } from '../Components/Title/Title';
import { Paragraph } from '../Components/Paragraph/Paragraph';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { CustomerEditForm } from '../Components/CustomerEditForm/CustomerEditForm';
import { PrimaryButton } from '../Components/Button/Button';
import { PORTAL_VIEWS, useSwitchView } from '../views';

export function PageAccount() {
  const { customer } = useContext(AuthContext);
  const { currencies, countries } = useContext(CustomerPortalContext);
  const { t } = useTranslation();
  const { switchView } = useSwitchView();

  if (!customer) {
    return null;
  }

  return (
    <>
      {customer && (
        <Container>
          <VerticalSpacer>
            <Title level={1} title={t('account_title')} />
            <Paragraph text={t('account_info_text')} />
            <CustomerEditForm customer={customer} currencies={currencies} countries={countries} />
            <Title level={1} title={t('dashboard_change_password')} />
            <Paragraph text={t('dashboard_change_password_text')} />
            <PrimaryButton onClick={() => switchView(PORTAL_VIEWS.passwordchange)}>
              {t('dashboard_change_password')}
            </PrimaryButton>
          </VerticalSpacer>
        </Container>
      )}
    </>
  );
}
