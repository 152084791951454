import React from 'react'
import classnames from 'classnames'

type ImageProps = {
  src : string
  alt : string
  layoutClass? : string
}

export function Image({ src, alt, layoutClass } : ImageProps) {
  return <BaseImage {...{ src, alt, layoutClass }} />
}

type BaseImageProps = {
  src : string
  alt : string
  layoutClass? : string
}

function BaseImage({ src, alt, layoutClass } : BaseImageProps) {
  return <img className={classnames({ [ `${layoutClass}` ]: layoutClass })} {...{ src, alt }} />
}
