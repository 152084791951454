import { OrderContext, AuthContext, ShoppingCartContext } from '../../Contexts';
import { getPlanPrice, getPlanPriceTax } from '../../Utilities/ProductUtilities';
import { formatPriceWithCurrency } from '../../Utilities/PriceUtilities';
import { getOrderCurrency } from '../../Utilities/OrderUtilities';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Plan } from '../../interfaces';

type PlanPriceProps = {
  plan: Plan;
};

export function PlanPricePortal({ plan }: PlanPriceProps) {
  const { t } = useTranslation();
  const { order } = useContext(OrderContext);
  const { customer } = useContext(AuthContext);
  const currency = getOrderCurrency({ ...order, customer });
  const price = getPlanPrice(plan, currency);
  const duration = plan?.duration;
  const duration_period_in = plan?.duration_period_in;
  const billing_taxrate = customer.billing_taxrate;
  const { including_tax } = useContext(ShoppingCartContext);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {billing_taxrate != 0 && (
        <>
          <div className="row" style={{ marginRight: 5, display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 5 }}>{duration}</div>
            {t(`plan_price_${plan.duration_period_in}_${duration === 1 ? 'single' : 'plural'}`)}
          </div>
          {including_tax && <div style={{ marginRight: 5 }}>{formatPriceWithCurrency(getPlanPriceTax(plan, currency, billing_taxrate) ?? NaN, currency)}</div>}
          {!including_tax && <div style={{ paddingRight: 5 }}>{formatPriceWithCurrency(price ?? NaN, currency)}</div>}
        </>
      )}
      {billing_taxrate == 0 && (
        <div>
          {`
      ${duration}
      ${t(`plan_price_${duration_period_in}_${duration === 1 ? 'single' : 'plural'}`)}

     -  ${formatPriceWithCurrency(price ?? NaN, currency)}
    `}
        </div>
      )}
    </div>
  );
}
//     - ${formatPriceWithCurrency(getPlanPriceTax(plan, currency, billing_taxrate) ?? NaN, currency)} (${billing_taxrate}%)
