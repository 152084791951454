import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../Components/Container/Container';
import { Title } from '../Components/Title/Title';
import { Paragraph } from '../Components/Paragraph/Paragraph';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { BaseSubscriptionOverview } from '../Components/BaseSubscriptionOverview/BaseSubscriptionOverview';
import { ExtraSubscriptionOverview } from '../Components/ExtraSubscriptionOverview/ExtraSubscriptionOverview';
import { AuthContext } from '../Contexts';

export function PageSubscriptions() {
  const { t } = useTranslation();
  const { customer } = React.useContext(AuthContext);

  if (!customer || typeof customer.subscriptions.base.product !== 'object') {
    return <span>{t('subscriptions_no_customer')}</span>;
  }
  return (
    <>
      <Container>
        <VerticalSpacer>
          <Title level={1} title={t('subscriptions_title')} />
          <Paragraph text={t('subscriptions_info_text')} />
          {customer && (
            <>
              <BaseSubscriptionOverview subscriptions={[customer.subscriptions.base]} showActionButtons />
              <ExtraSubscriptionOverview subscriptions={customer.subscriptions.extras} showActionButtons />
            </>
          )}
        </VerticalSpacer>
      </Container>
    </>
  );
}
