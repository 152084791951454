import { useContext } from 'react';
import { DesignContext } from '../../Contexts';

type ParagraphProps = {
  text: string;
};

export function Paragraph({ text }: ParagraphProps) {
  const { globals } = useContext(DesignContext);

  return (
    <p
      style={{
        fontSize: 16,
        fontFamily: globals['font-family'],
        color: globals['text-color'],
      }}
    >
      {text}
    </p>
  );
}
