import { useContext, useState } from 'react';
import styles from './PortalCartFlowExtraRemove.module.scss';
import { OrderContext, AuthContext, DesignContext } from '../../Contexts';
import { Steps } from 'antd';
import { SecondaryButton } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import classNames from 'classnames';
import moment from 'moment';
import { PlanPricePortal } from '../PlanPrice/PlanPricePortal';
import { FinishNoPay } from '../../Pages/Checkout';
import { Plan, Product } from '../../interfaces';

type ProductGridProps = {
  activationDate: number;
  expirationDate: number;
  plan: Plan;
  product: Product;
  unavailableProducts?: any;
  showStartOptions?: boolean;
  allowStartImmediate?: boolean;
};

enum BasePlanWizard {
  planDetails = 0,
  finish = 1,
}

export function PortalCartFlowExtraRemove({ product, activationDate, expirationDate, plan }: ProductGridProps) {
  const { shopSelectExtraProductRemove } = useContext(OrderContext);
  const { t } = useTranslation();
  const { customer } = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [image] = product.external.images;

  const {
    globals,
  } = useContext(DesignContext);

  useEffectOnce(() => {
    shopSelectExtraProductRemove(product, customer, plan);
  });

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  return (
    <>
      <Steps style={{ fontFamily: globals['font-family'],marginTop:20  }} current={currentStep} items={[{ title: t('cart_remove_details') }, { title: t('cart_finalize_removal') }]} />
      <div>
        {currentStep === BasePlanWizard.planDetails && (
          <div
            style={{
              border: '2px solid #dedede',
              padding: 20,
              borderRadius: 5,
              backgroundColor: '#fff',
              marginTop: 20,
              marginBottom: 20,
            }}
            className={classNames(styles.component)}
          >
            <div className={styles.imageContainer}>{image && <img className={styles.image} src={image.url} alt={product.name} />}</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div>
                <b>{product.name}</b>
              </div>
              <div style={{ fontSize: 16 }}>{`
            ${activationDate ? moment.unix(activationDate).format('lll') : '-'} - ${expirationDate ? moment.unix(expirationDate).format('lll') : '-'}`}</div>
              <div
                className={styles.actionContainer}
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'row',
                  fontSize: 16,
                }}
              >
                <PlanPricePortal {...{ plan: plan }} />
              </div>
            </div>
          </div>
        )}
        {currentStep === BasePlanWizard.finish && <FinishNoPay />}
      </div>
      <div className={styles.modalFooter}>
        <SecondaryButton
          onClick={() => {
            handleNext();
          }}
        >
          Confirm Removal
        </SecondaryButton>
      </div>
    </>
  );
}
