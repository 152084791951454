import { useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './PendingBaseSubscriptionCard.module.scss';
import { isMobile } from 'react-device-detect';

import { PlanPricePortal } from '../PlanPrice/PlanPricePortal';
import { AuthContext } from '../../Contexts';
import moment from 'moment';
import { Product } from '../../interfaces';

type BaseSubscriptionCardProps = {
  subscription: any;
};

export function PendingBaseSubscriptionCard({ subscription }: BaseSubscriptionCardProps) {
  const { t } = useTranslation();
  const { activation_date: activationDate, expiration_date: expirationDate } = subscription;
  const product = subscription.product as Product;
  const [image] = product.external.images;
  const plan = product.plans.plans.find((p) => p._id === subscription.plan._id);
  const { customer } = useContext(AuthContext);
  return (
    <>
      <div
        style={{
          border: '2px solid #dedede',
          padding: 20,
          borderRadius: 5,
          backgroundColor: '#fff',
          flexDirection: `${isMobile ? 'column' : 'row'}`,
        }}
        className={classNames(styles.component)}
      >
        <div className={styles.imageContainer}>{image && <img className={styles.image} src={image.url} alt={product.name} />}</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 2,
          }}
        >
          <div style={{ flexDirection: 'row', display: 'flex', fontSize: 22 }}>
            <b>{product.name}</b>
          </div>
          <div style={{ display: 'flex' }}>
            {/* {(activationDate || expirationDate) && (
              <div style={{ fontSize: 16 }}>{`
            ${activationDate ? moment.unix(activationDate).format('lll') : '-'} - ${expirationDate ? moment.unix(expirationDate).format('lll') : '-'}`}</div>
            )} */}
          </div>
          <div
            className={styles.actionContainer}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'row',
            }}
          >
            <PlanPricePortal {...{ plan }} />
          </div>
          {customer.subscriptions.pending_base != undefined && expirationDate > moment().unix() && (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div style={{ textAlign: 'center', marginTop: 10, color: '#fff', paddingLeft: 10, paddingRight: 10, borderRadius: 5, backgroundColor: 'mediumseagreen', fontSize: 14, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                {t('subscriptions_start_on') + ': '} {moment.unix(customer.subscriptions.base.expiration_date).format('lll')}
              </div>
            </div>
          )}
          {customer.subscriptions.pending_base != undefined && expirationDate < moment().unix() && (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div style={{ textAlign: 'center', marginTop: 10, color: '#fff', paddingLeft: 10, paddingRight: 10, borderRadius: 5, backgroundColor: 'coral', fontSize: 14, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>{t('subscriptions_starts_after_renewal')}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
