import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const list = () => axios.get<{
  data: {
    countries: {
      _id: string;
      mapping_id: number;
      name: string;
      iso3: string
      iso2: string
    }[]
  }
}>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/common/countries`)

export const useCountries = () => useQuery(['countries', 'list'], async () => {
  return list()
    .then(r => Promise.resolve(r.data))
    .catch(e => Promise.reject(e))
})

export const listCountries = list;