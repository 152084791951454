import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Components/Container/Container';
import { LoginForm } from '../Components/LoginForm/LoginForm';
import { Title } from '../Components/Title/Title';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { AuthContext } from '../Contexts';
import { PORTAL_VIEWS, useSwitchView } from '../views';

export function LoginPage() {
  const { t } = useTranslation();
  const { switchView } = useSwitchView();
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedIn) {
      switchView(PORTAL_VIEWS.dashboard);
    }
  }, [isLoggedIn]);

  console.log(isLoggedIn);

  return (
    <Container>
      <VerticalSpacer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          <div style={{ width: '50%', marginTop: 25 }}>
            <Title level={1} title={t('login_title')} />
            <div style={{ marginTop: 20 }}>{t('login_info')}</div>
            <LoginForm />
          </div>
        </div>
      </VerticalSpacer>
    </Container>
  );
}
