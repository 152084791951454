import { useContext, useState } from 'react';
import { ProductCard } from '../ProductCardBase/ProductCardBase';
import styles from './PortalCartFlowBase.module.scss';
import { OrderContext, AuthContext, DesignContext } from '../../Contexts';
import { Steps } from 'antd';
import { PrimaryButton, SecondaryButton } from '../Button/Button';
import { FinishNoPay, Pay, PaymentMethod } from '../../Pages';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import { Container } from '../Container/Container';
import { PortalCartOverview } from '../PortalCartOverviewBase/PortalCartOverviewBase';
import { useTranslation } from 'react-i18next';
import { getOrderCurrency } from '../../Utilities/OrderUtilities';
import { useEffectOnce } from 'react-use';
import { getCurrencyCreditPriceNoTax, getPlanPrice } from '../../Utilities/ProductUtilities';
import { Product, OrderTypes } from '../../interfaces';

type ProductGridProps = {
  items: Product[];
  unavailableProducts?: string[];
  showStartOptions?: boolean;
  allowStartImmediate?: boolean;
};

enum BasePlanWizard {
  changePlan = 0,
  planDetails = 1,
  paymentMethod = 2,
  payNow = 3,
}

export function PortalCartFlowBase({ items, unavailableProducts = [], showStartOptions = false, allowStartImmediate = false }: ProductGridProps) {
  const { order, setOrderType } = useContext(OrderContext);
  const { customer } = useContext(AuthContext);
  const existingSubscriptionPlan = customer && customer.subscriptions.base.plan;
  const { subscriptionRules } = useContext(OrderContext);

  const { t } = useTranslation();
  const {
    globals,
    forms: { inputs },
  } = useContext(DesignContext);
  const [currentStep, setCurrentStep] = useState(0);

  useEffectOnce(() => {
    setOrderType(OrderTypes.BaseSubscriptionChange);
  });

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNext = () => {
    if (currentStep <= 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const canAdvance = () => {
    if (currentStep === BasePlanWizard.changePlan && order.products.find((x) => x.product.type === 'BASE') === undefined) {
      return false;
    }

    return true;
  };

  const PaymentMethodSelection = () => {
    return (
      <Container>
        <VerticalSpacer size="xxl">
          <PaymentMethod onDone={() => {}} onPrevious={() => {}} showActionBar={false} />
        </VerticalSpacer>
      </Container>
    );
  };

  const isPriceHigherThenZero = () => {
    var test = order.products.find((x) => x.product.type === 'BASE');
    if (test !== undefined) {
      var price = test.plan.prices.find((p) => p.currency === order.billingCurrency).price;
      if (test.product.settings.trial_days > 0) {
        return false;
      } else {
        var currentprice = customer.subscriptions.base.plan.prices.find((price) => price.currency === customer.billing_currency)?.price;
        var oldprices = getCurrencyCreditPriceNoTax(customer) + currentprice;
        return price - oldprices > 0;
      }
    } else {
      return false;
    }
  };

  const isPlanSameAsBasePlan = (plan: any) => {
    const currency = getOrderCurrency({ ...order, customer });
    const price = getPlanPrice(plan, currency);
    const productprice = existingSubscriptionPlan.prices.find((p) => p.currency == currency).price;

    if (existingSubscriptionPlan.duration_period_in == plan.duration_period_in && existingSubscriptionPlan.duration == plan.duration) {
      if (subscriptionRules.allow_downgrades && price < productprice) {
        return true;
      } else {
        if (price >= productprice) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <Steps style={{ fontFamily: globals['font-family'], marginTop:20 }} current={currentStep} items={[{ title: t('cart_base') }, { title: t('cart_order') }, { title: t('cart_payment') }]} />

      <div>
        {currentStep === BasePlanWizard.changePlan && (
          <div className={styles.component}>
            {items.map((product, index) => {
              let showProduct = false;
              if (product.plans !== undefined) {
                product.plans.plans.forEach((plan) => {
                  customer.subscriptions.base.product.plans.plans.forEach((plan_) => {
                    if (plan_.duration === plan.duration && plan_.duration_period_in === plan.duration_period_in) {
                      if (plan._id != plan_._id) {
                        const currency = getOrderCurrency({
                          ...order,
                          customer,
                        });
                        const productprice = plan.prices.find((p) => p.currency == currency).price;
                        const currentprice = existingSubscriptionPlan.prices.find((p) => p.currency == currency).price;
                        if (subscriptionRules.allow_downgrades && productprice < currentprice) {
                          showProduct = true;
                        } else {
                          if (productprice >= currentprice) {
                            showProduct = true;
                          }
                        }
                      }
                    }
                  });
                });
                var hasPlans = false;

                product.plans.plans.forEach((plan) => {
                  var test = isPlanSameAsBasePlan(plan);
                  if (test) {
                    hasPlans = true;
                  }
                });

                if (hasPlans && showProduct && product.type === 'BASE') {
                  return (
                    <ProductCard
                      key={`product-grid-${index}`}
                      {...{
                        product,
                        showStartOptions,
                        allowStartImmediate,
                        handleNext,
                      }}
                    />
                  );
                } else {
                  return null;
                }
              }
            })}
          </div>
        )}
        {currentStep === BasePlanWizard.planDetails && <PortalCartOverview />}
        {currentStep === BasePlanWizard.paymentMethod && isPriceHigherThenZero() && subscriptionRules.allow_partial_upgrades && <PaymentMethodSelection />}
        {currentStep === BasePlanWizard.paymentMethod && (!isPriceHigherThenZero() || !subscriptionRules.allow_partial_upgrades) && <FinishNoPay />}
        {currentStep === BasePlanWizard.payNow && <Pay />}
      </div>
      <div className={styles.modalFooter}>
        {currentStep !== BasePlanWizard.payNow && currentStep !== BasePlanWizard.paymentMethod && (
          <SecondaryButton
            key="submit"
            disabled={currentStep === BasePlanWizard.changePlan}
            onClick={() => {
              handlePrevious();
            }}
          >
            {t('checkout_previous_step')}
          </SecondaryButton>
        )}
        {currentStep === BasePlanWizard.paymentMethod && isPriceHigherThenZero() && (
          <SecondaryButton
            key="submit"
            onClick={() => {
              handlePrevious();
            }}
          >
            {t('checkout_previous_step')}
          </SecondaryButton>
        )}
        {currentStep === BasePlanWizard.paymentMethod && isPriceHigherThenZero() ? (
          <PrimaryButton
            onClick={() => {
              handleNext();
            }}
            disabled={order.payment === null}
          >
            {t('checkout_tabs_next_button')}
          </PrimaryButton>
        ) : (
          currentStep !== BasePlanWizard.paymentMethod && (
            // !isPriceHigherThenZero() &&
            <SecondaryButton
              disabled={!canAdvance()}
              onClick={() => {
                handleNext();
              }}
            >
              {t('checkout_next_step')}
            </SecondaryButton>
          )
        )}
      </div>
    </>
  );
}
