import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderContext, AuthContext } from '../../Contexts';
import { Product } from '../../interfaces/product';
import { PlanPricePortal } from '../PlanPrice/PlanPricePortal';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import styles from './AddExtraSubscription.module.scss';
import cx from 'classnames';
import { Radio } from 'antd';

type AddExtraSubscriptionProps = {
  product: Product;
  layoutClass?: string;
  disabled?: boolean;
  showStartOptions?: boolean;
  allowStartImmediate?: boolean;
  handleNext?: Function;
};

export function AddExtraSubscription({ product, layoutClass, handleNext = () => {} }: AddExtraSubscriptionProps) {
  const { t } = useTranslation();
  const { shopSelectExtraProduct } = useContext(OrderContext);

  const { customer } = useContext(AuthContext);
  const existingSubscriptionPlan = customer && customer.subscriptions.base.plan;
  const isPlanSameAsBasePlan = (plan: any) => {
    if (existingSubscriptionPlan.duration_period_in == plan.duration_period_in && existingSubscriptionPlan.duration == plan.duration) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className={cx(styles.component, {
        [`${layoutClass}`]: layoutClass,
      })}
    >
      {product.plans?.plans.map((plan, index) => {
        return (
          <div
            key={index}
            className={cx({
              [`${styles.selectedPlan}`]: plan.isSelected,
            })}
          >
            <VerticalSpacer size="xs">
              {isPlanSameAsBasePlan(plan) && (
                <Radio.Button
                  style={{
                    width: '100%',
                    height: 45,
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5,
                    borderWidth: 2,
                    marginBottom: 3,
                  }}
                  name={`${product._id}_plan`}
                  checked={plan.isSelected}
                  onChange={() => shopSelectExtraProduct(product, plan)}
                >
                  <PlanPricePortal {...{ plan }} />
                </Radio.Button>
              )}
            </VerticalSpacer>
          </div>
        );
      })}
    </div>
  );
}
