import React, { useState } from 'react';
import { DeviceCard } from '../DeviceCard/DeviceCard';
import styles from './DeviceGrid.module.scss';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { AuthContext, HashContext } from '../../Contexts';

const toAlphaNumeric = (input) => {
  if (input == '' || input == undefined || input == null) {
    return '';
  }
  input = input.toString().replace(/\s/g, '');
  return input.toString().replace(/[^A-Za-z0-9]/g, '');
};

const getDevices = async (client, deployment, service, customer) => {
  let path = 'https://cloudtv.akamaized.net/' + client + '/' + deployment + '/' + service + '/users/' + toAlphaNumeric(customer.username).split('').join('/') + '/devices.json';
  let response = await fetch(path);
  if (response.status == 200) {
    let data = await response.json();
    return data;
  } else {
    return [];
  }
};
const deleteDevice = (index) => {};

const updateDevicesJson = (client, deployment, customer, devices) => {
  let path = client + '/' + deployment + '/users/' + toAlphaNumeric(customer.username).split('').join('/');
  return fetch('https://jsonstorage.io/upload?path=' + encodeURIComponent(path) + '&name=devices.json', {
    method: 'POST',

    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(devices),
  }).catch((error) => {});
};

export function DeviceGrid() {
  const { customer } = React.useContext(AuthContext);
  const { client, deployment, service } = React.useContext(HashContext);
  const [devices, setDevices] = useState([] as any);
  const { t } = useTranslation();

  useEffectOnce(() => {
    getDevices(client, deployment, service, customer).then((dev) => {
      setDevices(dev);
    });
  });

  return (
    <>
      {devices.length > 0 && (
        <ul className={styles.component}>
          {devices.map((device, index) => (
            <li key={index}>
              <DeviceCard deleteDevice={undefined} {...{ device }} index={index} customer={customer} />
            </li>
          ))}
        </ul>
      )}
      {devices.length == 0 && (
        <ul className={styles.component}>
          <li>
            <div
              style={{
                border: '2px solid #dedede',
                padding: 10,
                borderRadius: 5,
              }}
            >
              {t('devices_nodevices')}
            </div>
          </li>
        </ul>
      )}
    </>
  );
}
