import React from 'react';
import { Subscription } from '../../interfaces';

import { ExtraSubscriptionCard } from '../ExtraSubscriptionCard/ExtraSubscriptionCard';

import styles from './ExtraSubscriptionOverview.module.scss';

type ExtraSubscriptionGridProps = {
  subscriptions: Subscription[];
  showActionButtons: boolean;
  showPrice?: boolean;
  allowChangingBase?: boolean;
};

export function ExtraSubscriptionOverview({ subscriptions, showActionButtons, showPrice, allowChangingBase = true }: ExtraSubscriptionGridProps) {
  return (
    <ul className={styles.component}>
      {subscriptions.map((subscription, i) => (
        <li key={i}>
          <ExtraSubscriptionCard
            {...{
              subscription,
              allowChangingBase,
              showPrice,
              showActionButtons,
              type: 'EXTRA',
            }}
          />
        </li>
      ))}
    </ul>
  );
}
