import { useContext, useState } from 'react';
import { getOrderCurrency } from '../../Utilities/OrderUtilities';
import { useTranslation } from 'react-i18next';
import { OrderContext, AuthContext } from '../../Contexts';
import { getCurrencyCreditPrice, getFinalCreditsRenew, getFinalPriceRenew, getTotalPlanPrice, getTranslatedName } from '../../Utilities/ProductUtilities';
import { Image } from '../Image/Image';
import { formatPriceWithCurrency, getOrderTaxTotal, getOrderTotal, getOrderTotalNoTax, getTaxRateName } from '../../Utilities/PriceUtilities';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import styles from './PortalCartSubscriptionsBase.module.scss';
import { Order } from "../../interfaces";
import { faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { Modal } from 'antd';
import { ProductCard } from '../ProductCardBase/ProductCardBase';
import { PlanPricePortal } from '../PlanPrice/PlanPricePortal';
import { Product, Plan, Customer, SubscriptionRules } from '../../interfaces';

type PortalCartOverviewProps = {
  allowActions?: boolean;
  isCompleted?: boolean;
  existingOrder?: Order;
};

export function PortalCartSubscriptions({ allowActions = true, isCompleted = false }: PortalCartOverviewProps) {
  let { order, taxRates } = useContext(OrderContext);
  const { customer } = useContext(AuthContext);
  const { t } = useTranslation();
  const currency = getOrderCurrency(order);
  const isCartEmpty = !order.products || order.products.length === 0;

  return (
    <div style={{ marginTop: 20 }}>
      {isCartEmpty ? (
        <span>{t('cart_overview_empty_cart')}</span>
      ) : (
        <div>
          {order.products.map(({ product, plan }, index) => (
            <ProductRow
              key={product._id}
              {...{
                product,
                plan,
                allowActions: false,
              }}
            />
          ))}

          <div
            style={{
              border: '1px solid #dedede',
              backgroundColor: '#fff',
              borderRadius: 5,
              marginBottom: 5,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  width: '85%',
                }}
              >
                <b>{t('cart_overview_subtotal')}:</b>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  width: '15%',
                }}
              >
                <b>{formatPriceWithCurrency(getOrderTotalNoTax(order), currency)}</b>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  width: '85%',
                }}
              >
                <b>
                  {t('cart_overview_tax') + ' '}
                  {getTaxRateName(order, taxRates)}
                  {'%:'}
                </b>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  width: '15%',
                }}
              >
                <b>{formatPriceWithCurrency(getOrderTaxTotal(order, taxRates), currency)}</b>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  width: '85%',
                }}
              >
                <b>{t('cart_overview_total')}:</b>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  width: '15%',
                }}
              >
                <b>{formatPriceWithCurrency(getOrderTotal(order, taxRates), currency)}</b>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  width: '85%',
                }}
              >
                <b>{t('cart_overview_credit')}:</b>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  color: 'green',
                  width: '15%',
                }}
              >
                <b>{formatPriceWithCurrency(getCurrencyCreditPrice(customer), customer.billing_currency)}</b>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  width: '85%',
                }}
              >
                <b>{t('cart_overview_final_total')}:</b>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  width: '15%',
                }}
              >
                <b>{formatPriceWithCurrency(getFinalPriceRenew(customer, order, customer.billing_taxrate), customer.billing_currency)}</b>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  width: '85%',
                }}
              >
                <b>{t('cart_overview_credits_left')}:</b>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 20,
                  width: '15%',
                  color: 'green',
                }}
              >
                <b>{formatPriceWithCurrency(getFinalCreditsRenew(customer, order, customer.billing_taxrate), customer.billing_currency)}</b>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

type ProductRowProps = {
  product: Product;
  plan: Plan;
  onProductChange?: (product) => void;
  allowActions: boolean;
  isCredit?: boolean;
};

function ProductRow({ product, plan, onProductChange, allowActions = true, isCredit = false }: ProductRowProps) {
  const {t} = useTranslation();
  const { i18n } = useTranslation();
  const name = getTranslatedName(product, i18n.language);
  const { order, subscriptionRules, removeProduct } = useContext(OrderContext);
  const { customer } = useContext(AuthContext);
  const currency = getOrderCurrency(order);
  const [modalOpenBase, setModalOpenBase] = useState(false);
  const [modalOpenExtra, setModalOpenExtra] = useState(false);
  const [productsExtra, setProductsExtra] = useState([] as any);
  const [productsBase, setProductsBase] = useState([] as any);

  const { baseProducts } = useContext(OrderContext);
  const openBaseProductChangeModal = () => {
    setProductsBase(baseProducts);
    setModalOpenBase(true);
  };

  const { extraProducts } = useContext(OrderContext);
  const openExtraProductChangeModal = () => {
    setProductsExtra(extraProducts);
    setModalOpenExtra(true);
  };
  return (
    <div
      className="highlight"
      key={product._id}
      style={{
        border: '1px solid #dedede',
        backgroundColor: '#fff',
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 5,
      }}
    >
      <DisplayChangeBaseProductsModal
        open={modalOpenBase}
        onOk={() => {
          setModalOpenBase(false);
        }}
        onCancel={() => setModalOpenBase(false)}
        productType={'BASE'}
        products={productsBase}
        customer={customer}
        order={order}
        subscriptionRules={subscriptionRules}
        t={t}
        styles={styles}
      />
      <DisplayChangeExtraProductsModal
        open={modalOpenExtra}
        onOk={() => {
          setModalOpenExtra(false);
        }}
        onCancel={() => setModalOpenExtra(false)}
        productType={'EXTRA'}
        products={productsExtra}
        customer={customer}
        order={order}
        subscriptionRules={subscriptionRules}
        t={t}
        styles={styles}
      />
      {!isMobile && (
        <div
          style={{
            width: '20%',
            display: 'flex',
            alignItems: 'center',
            padding: 5,
            paddingLeft: 20,
          }}
        >
          <div className={styles.imageContainer}>
            <Image src={product.external.images[0]?.url} alt={name} layoutClass={styles.image} />
          </div>
        </div>
      )}

      <div
        className={styles.productDetails}
        style={{
          margin: 20,
          width: '35%',
        }}
      >
        <span>
          <b>
            {name} ({product.type})
          </b>
        </span>
        {plan && <PlanPricePortal {...{ plan }} />}

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {product.type == 'EXTRA' && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ cursor: 'pointer' }} onClick={() => removeProduct(product, plan)}>
                <FontAwesomeIcon icon={faTrash} style={{ marginRight: 5, marginTop: 15, fontSize: 16 }} />
                <span style={{ fontSize: 16 }}>{t('cart_buttons_remove')}</span>
              </div>
            </div>
          )}
          {onProductChange != undefined && product.type == 'BASE' && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ cursor: 'pointer' }} onClick={() => openBaseProductChangeModal()}>
                <FontAwesomeIcon icon={faArrowUpArrowDown} style={{ marginRight: 5, marginTop: 15, fontSize: 16 }} />
                <span style={{ fontSize: 16 }}>{t('cart_buttons_change')}</span>
              </div>
            </div>
          )}
          {onProductChange != undefined && product.type == 'EXTRA' && (
            <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row' }}>
              <div style={{ cursor: 'pointer' }} onClick={() => openExtraProductChangeModal()}>
                <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 5, marginTop: 15, fontSize: 16 }} />
                <span style={{ fontSize: 16 }}>{t('cart_buttons_more')}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          verticalAlign: 'top',
          width: '25%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            marginTop: 20,
            marginBottom: 20,
          }}
        ></div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          verticalAlign: 'top',
          width: '20%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            margin: 20,
            color: isCredit ? 'green' : '#000',
          }}
        >
          {plan && formatPriceWithCurrency(getTotalPlanPrice(plan, currency), currency)}
        </div>
      </div>
    </div>
  );
}

interface DisplayProductModalProps {
  open: boolean;
  onOk: Function;
  onCancel: Function;
  products: Product[];
  customer: Customer;
  order: any;
  subscriptionRules: SubscriptionRules;
  productType: 'BASE' | 'EXTRA';
  t: any;
  styles: any;
}

const DisplayChangeBaseProductsModal = ({ open, onOk, onCancel, products, customer, order, subscriptionRules, t, styles }: DisplayProductModalProps) => {
  return (
    <Modal {...{ open }} footer={null} width={1200} onOk={() => onOk()} onCancel={() => onCancel()}>
      {products && (
        <div className={styles.component}>
          {products.map((product, index) => {
            let showProduct = false;
            if (product.plans !== undefined) {
              product.plans.plans.forEach((plan) => {
                customer.subscriptions.base.product.plans.plans.forEach((plan_) => {
                  if (plan_.duration === plan.duration && plan_.duration_period_in === plan.duration_period_in) {
                    //if (plan._id != plan_._id) {
                    const currency = getOrderCurrency({
                      ...order,
                      customer,
                    });
                    const productprice = plan.prices.find((p) => p.currency == currency).price;
                    const currentprice = customer.subscriptions.base.plan.prices.find((p) => p.currency == currency).price;
                    if (subscriptionRules.allow_downgrades && productprice <= currentprice) {
                      showProduct = true;
                    } else {
                      if (productprice >= currentprice) {
                        showProduct = true;
                      }
                    }
                    // }
                  }
                });
              });

              if (showProduct && product.type === 'BASE') {
                return (
                  <ProductCard
                    key={`product-grid-${index}`}
                    {...{
                      product,
                      showStartOptions: false,
                      allowStartImmediate: false,
                      handleNext: null,
                    }}
                  />
                );
              } else {
                return null;
              }
            }
          })}
        </div>
      )}
    </Modal>
  );
};

const DisplayChangeExtraProductsModal = ({ open, onOk, onCancel, products, customer, order, subscriptionRules, t, styles }: DisplayProductModalProps) => {
  return (
    <Modal {...{ open }} footer={null} width={1200} onOk={() => onOk()} onCancel={() => onCancel()}>
      {products && (
        <div className={styles.component}>
          {products.map((product, index) => {
            let showProduct = false;
            if (product.plans !== undefined) {
              product.plans.plans.forEach((plan) => {
                customer.subscriptions.extras.forEach((extra) => {
                  extra.product.plans.plans.forEach((plan_) => {
                    if (plan_.duration === plan.duration && plan_.duration_period_in === plan.duration_period_in) {
                      if (plan._id != plan_._id) {
                        const currency = getOrderCurrency({
                          ...order,
                          customer,
                        });
                        const productprice = plan.prices.find((p) => p.currency == currency).price;
                        const currentprice = customer.subscriptions.base.plan.prices.find((p) => p.currency == currency).price;
                        if (subscriptionRules.allow_downgrades && productprice <= currentprice) {
                          showProduct = true;
                        } else {
                          if (productprice >= currentprice) {
                            showProduct = true;
                          }
                        }
                      }
                    }
                  });
                });
              });

              if (showProduct && product.type === 'EXTRA') {
                return (
                  <ProductCard
                    key={`product-grid-${index}`}
                    {...{
                      product,
                      showStartOptions: false,
                      allowStartImmediate: false,
                      handleNext: null,
                    }}
                  />
                );
              } else {
                return null;
              }
            }
          })}
        </div>
      )}
    </Modal>
  );
};
