import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Components/Container/Container';
import { Title } from '../Components/Title/Title';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { Form, Select } from '../Components/Form/Form';
import { Input, SubmitButton } from '../Components/Form/Form';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { PORTAL_VIEWS, useSwitchView } from '../views';
import { AuthContext, HashContext } from '../Contexts';

export const ForgotPassword = () => {
  const { t } = useTranslation();

  const { hash } = useContext(HashContext);
  const { switchView } = useSwitchView();
  const { client, deployment, service } = React.useContext(HashContext);
  const { isLoggedIn } = React.useContext(AuthContext);
  if (isLoggedIn) {
    switchView(PORTAL_VIEWS.dashboard);
  }

  const { handleSubmit, register, resetField } = useForm<{ email?: string, username?: string, mobile?: string }>();

  const [fieldSelection, setFieldSelection] = useState('username');
  const [sendingRequest, setSendingRequest] = useState(false);

  return (
    <Container>
      <VerticalSpacer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          <div style={{ width: '50%', marginTop: 25 }}>
            <Title level={1} title={t('forgot_password_form_title')} />
            <div style={{ marginTop: 20 }}>{t('forgot_password_form_info')}</div>
            <Select defaultValue={'username'} onChange={(e) => {
              setFieldSelection(e.target.value);
              resetField('email');
              resetField('mobile');
              resetField('username');
            }} label={t('customer_stuff')}>
              <option key='username' value='username'>
                Username
              </option>
              <option key='email' value='email'>
                Email
              </option>
              <option key='mobile' value='mobile'>
                Mobile
              </option>
            </Select>
            <Form
              onSubmit={handleSubmit(async (data) => {
                setSendingRequest(true);

                try {
                  const url = new URL(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/customers/customers/recovery`);
                  url.searchParams.set('instance', client);
                  url.searchParams.set('cms', deployment);
                  url.searchParams.set('crm', service);
                  await axios.post(url.toString(), data);
                }
                finally {
                  switchView(PORTAL_VIEWS.home);
                  setSendingRequest(false);
                }
              })}
            >
              <div
                style={{
                  marginTop: 50,
                  justifyContent: 'center',
                }}
              >
                <div style={{ marginTop: 25 }}>
                  {fieldSelection === 'email' && (
                    <Input {...register('email', { required: true })} label={t('forgot_password_form_email')} />
                  )}
                  {fieldSelection === 'username' && (
                    <Input {...register('username', { required: true })} label={t('forgot_password_form_username')} />
                  )}
                  {fieldSelection === 'mobile' && (
                    <Input {...register('mobile', { required: true })} label={t('forgot_password_form_mobile')} />
                  )}
                </div>
                <div style={{ marginTop: 40 }}>
                  <SubmitButton disabled={sendingRequest}>{t('forgot_password_form_submit')}</SubmitButton>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </VerticalSpacer>
    </Container>
  );
};
