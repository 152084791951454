import classNames from 'classnames';

import styles from './Container.module.scss';
import { ReactNode } from 'react';

type BaseContainerProps = {
  children?: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg';
};

export function Container({ children, size }: BaseContainerProps) {
  return (
    <div
      className={classNames(styles.component, {
        [`${styles.xs}`]: size === 'xs',
        [`${styles.sm}`]: size === 'sm',
        [`${styles.lg}`]: size === 'lg',
      })}
    >
      <div className={styles.containerInner} style={{ margin: 50 }}>
        {children}
      </div>
    </div>
  );
}
