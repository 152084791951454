import { useTranslation } from 'react-i18next';

import { Container } from '../Components/Container/Container';
import { Paragraph } from '../Components/Paragraph/Paragraph';
import { Title } from '../Components/Title/Title';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';

/* This page is used for purchases other than shopping cart flow */
/* For shopping cart flow, we redirect back to the form on purchase fail, create a new order using the old one,
 and notify the customer about the issue with the payment */
export function PageOrderCancelled() {
  const { t } = useTranslation();

  return (
    <Container size="sm">
      <VerticalSpacer size="xxl">
        <VerticalSpacer size="sm">
          <Title level={1} title={t('order_cancelled_title')} />
          <Paragraph text={t('order_cancelled_info')} />
        </VerticalSpacer>
      </VerticalSpacer>
    </Container>
  );
}
