import { useContext } from 'react';
import { DesignContext } from '../../Contexts';
import { Image } from 'antd';
import { ICountry } from './SiteHeaderPortalContainer';
import { colorShade } from '../../Utilities/ColorShade';
import styles from './SiteHeaderPortal.module.scss';

type CountryButtonProps = {
  country: ICountry;
  setBillingCountry: (country: ICountry) => void;
};

const CountryButton = ({ country, setBillingCountry }: CountryButtonProps) => {
  const { globals, navbar } = useContext(DesignContext);

  return (
    <button
      onClick={() => setBillingCountry(country)}
      style={{
        fontFamily: globals['font-family'],
        color: navbar['text-color'],
        backgroundColor: colorShade(navbar['background-color'], 20),
        padding: 10,
      }}
      className={styles.languageCurrencyButton}
    >
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div style={{ display: 'flex', width: '20%' }}>
          <Image preview={false} style={{ width: 20 }} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/country-iso2/' + country?.iso2.toLowerCase() + '.png'}></Image>
        </div>
        <div style={{ display: 'flex', width: '50%', fontSize: 16 }}>{`${country.name}`}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '30%',
            fontSize: 16,
          }}
        >{`${country.currency}`}</div>
      </div>
    </button>
  );
};

export default CountryButton;
