import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBoxOpen, faUser, faUserEdit, faUserCheck, faGlobe, faLocationArrow, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer } from 'antd';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { PORTAL_VIEWS, useSwitchView } from '../../views';
import { ICountry, ILanguage } from './SiteHeaderPortalContainer';
import styles from './SiteHeaderPortal.module.scss';
import CountryCurrencySelectorDropDown from './CountrySelectorDropdown';
import LanguageSelectorDropdown from './LanguageSelectorDropdown';
import { AuthContext, DesignContext } from '../../Contexts';

interface SiteHeaderPortalDrawerProps {
  countries: ICountry[];
  languages: ILanguage[];
  updateShopCountry: (country: ICountry) => void;
  billingCountry: ICountry;
}

const SiteHeaderPortalDrawer = ({ countries, updateShopCountry, billingCountry, languages }: SiteHeaderPortalDrawerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { switchViewUrl } = useSwitchView();
  const { isLoggedIn, logout } = useContext(AuthContext);
  const { globals, navbar } = useContext(DesignContext);

  return (
    <>
      <FontAwesomeIcon size="2xl" icon={faBars as IconProp} onClick={() => setOpen(true)} style={{ padding: '10px' }} />
      <Drawer title="Menu" placement={'left'} closable={true} onClose={() => setOpen(false)} open={open} bodyStyle={{ backgroundColor: navbar['background-color'] }}>
        <header
          style={{
            fontFamily: globals['font-family'],
            color: navbar['text-color'],
            justifyContent: 'flex-start',
            padding: 5,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {!isLoggedIn ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 15,
                      marginRight: 20,
                      fontSize: '1.5rem',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FontAwesomeIcon size={'xs'} width={'2rem'} icon={faLocationArrow as IconProp} />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 10,
                          marginRight: 20,
                        }}
                      >
                        <CountryCurrencySelectorDropDown countries={countries} billingCountry={billingCountry} setBillingCountry={updateShopCountry} layoutClass={styles.menuItem} />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FontAwesomeIcon size={'xs'} width={'2rem'} icon={faGlobe as IconProp} />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 10,
                          marginRight: 20,
                        }}
                      >
                        <LanguageSelectorDropdown layoutClass={styles.menuItem} languages={languages} />
                      </div>
                    </div>
                    <Link
                      style={{
                        color: navbar['text-color'],
                        textAlign: 'center',
                      }}
                      to={switchViewUrl(PORTAL_VIEWS.shop)}
                      onClick={() => setOpen(false)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon size={'xs'} width={'2rem'} icon={faBoxOpen as IconProp} />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 10,
                            marginRight: 20,
                            fontSize: 16,
                          }}
                        >
                          {t('navigation_products')}
                        </div>
                      </div>
                    </Link>

                    <Link
                      style={{
                        color: navbar['text-color'],
                        textAlign: 'center',
                      }}
                      to={switchViewUrl(PORTAL_VIEWS.home)}
                      onClick={() => setOpen(false)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon size={'xs'} width={'2rem'} icon={faUser as IconProp} />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 10,
                            fontSize: 16,
                          }}
                        >
                          {t('navigation_login')}
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 15,
                      marginRight: 20,
                      fontSize: '1.5rem',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FontAwesomeIcon size={'xs'} width={'2rem'} icon={faGlobe as IconProp} />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 10,
                          marginRight: 20,
                        }}
                      >
                        <LanguageSelectorDropdown layoutClass={styles.menuItem} languages={languages} />
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginRight: 20,
                      }}
                    >
                      <div
                        style={{
                          color: navbar['text-color'],
                          textAlign: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          switchViewUrl(PORTAL_VIEWS.account);
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: 30,
                            alignItems: 'center',
                          }}
                        >
                          <FontAwesomeIcon size={'xs'} width={'2rem'} icon={faUserEdit as IconProp} />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              height: 30,
                              marginLeft: 10,
                              fontSize: 16,
                            }}
                          >
                            {t('navigation_my_account')}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div
                        style={{
                          color: navbar['text-color'],
                          textAlign: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (isLoggedIn) logout();
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: 30,
                            alignItems: 'center',
                          }}
                        >
                          <FontAwesomeIcon size={'xs'} width={'2rem'} icon={faUserCheck as IconProp} />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              height: 30,
                              marginLeft: 10,
                              fontSize: 16,
                            }}
                          >
                            {t('navigation_logout')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </header>
      </Drawer>
    </>
  );
};

export default SiteHeaderPortalDrawer;
