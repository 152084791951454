const handleApplePayClick = async () => {
  try {
    const canMakePayments = await ApplePaySession.canMakePayments();

    if (canMakePayments) {
      const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
          countryCode: 'US',
          currencyCode: 'USD',
          merchantCapabilities: ['supports3DS'],
          supportedNetworks: ['visa', 'masterCard'],
          total: {
              label: 'Total',
              amount: '10.00',
          },
      };

      const session = new ApplePaySession(1, paymentRequest);

      session.onvalidatemerchant = (event) => {
        // Realizează validarea comerciantului
      };

      session.onpaymentauthorized = (event) => {
        // Realizează procesarea plății autorizate
      };

      session.begin();
    } else {
      console.log('Apple Pay nu este suportat pe acest dispozitiv.');
    }
  } catch (error) {
    console.error('Eroare:', error);
  }
};

export function ApplePayButton() {
    return (
        <button onClick={handleApplePayClick}>Pay witch Apple Pay</button>
    );
}


