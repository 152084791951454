import { useContext, useState } from 'react';
import styles from './ShoppingCartFlow.module.scss';
import { OrderContext, CustomerPortalContext, ShoppingCartContext, DesignContext } from '../../Contexts';
import { SecondaryButton } from '../Button/Button';
import { FinishNoPay, Pay, PaymentMethod } from '../../Pages';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import { Container } from '../Container/Container';
import { CartOverview } from '../CartOverview/CartOverview';
import { useTranslation } from 'react-i18next';
import { Steps } from 'antd';
import { ShoppingProductCard } from '../ShoppingProductCard/ShoppingProductCard';
import { CustomerCreateForm } from '../CustomerCreateForm/CustomerCreateForm';
import { CustomerProductStatus } from '@mwaretv/database/build/backend/enums/customers/customerProductStatus';
import { useEffectOnce } from 'react-use';
import { OrderTypes, Product } from '../../interfaces';

type ShoppingCartFlowProps = {
  baseProducts: Product[];
  extraProducts: Product[];
  setOnStartOrder: any;
  initialStep: ShoppingCartWizard;
};

export enum ShoppingCartWizard {
  baseProduct,
  extraProducts,
  newCustomerDetails,
  cartOverview,
  paymentMethod,
  payNow,
}

export function ShoppingCartFlow({ baseProducts, extraProducts, setOnStartOrder, initialStep }: ShoppingCartFlowProps) {
  const { t } = useTranslation();
  const { globals } = useContext(DesignContext);
  const { order, setCustomerLead, setOrderType } = useContext(OrderContext);
  const { currencies, countries } = useContext(CustomerPortalContext);
  const [currentStep, setCurrentStep] = useState(initialStep ?? ShoppingCartWizard.baseProduct);
  const { payment_gateways: paymentGateways } = useContext(ShoppingCartContext);

  useEffectOnce(() => {
    setOrderType(OrderTypes.NewCustomer);
  });

  const cartSteps = [{ title: t('cart_base') }, { title: t('cart_extra') }, { title: t('cart_yours') }, { title: t('cart_order') }, { title: t('cart_payment') }];

  const noExtrasInShop = extraProducts.length === 0;

  if (noExtrasInShop) {
    cartSteps.splice(1, 1); // Remove "Extra" tab
  }

  const handlePrevious = () => {
    if (currentStep > ShoppingCartWizard.baseProduct) {
      if (currentStep < ShoppingCartWizard.newCustomerDetails) {
        setOnStartOrder(false);
      }

      let newStep = currentStep - 1;

      if (noExtrasInShop && newStep === ShoppingCartWizard.extraProducts) {
        newStep -= 1;
      }

      setCurrentStep(newStep);
    }
  };

  const handleNext = () => {
    if (currentStep < ShoppingCartWizard.payNow) {
      if (currentStep > ShoppingCartWizard.extraProducts) {
        setOnStartOrder(true);
      }

      let newStep = currentStep + 1;

      if (noExtrasInShop && newStep === ShoppingCartWizard.extraProducts) {
        newStep += 1;
      }

      setCurrentStep(newStep);
    }
  };

  const canAdvance = () => {
    if (currentStep === ShoppingCartWizard.baseProduct && order.products.find((x) => x.product.type === 'BASE') === undefined) {
      return false;
    }

    return true;
  };

  const isPriceHigherThenZero = () => {
    var test = order.products.find((x) => x.product.type === 'BASE');

    if (test !== undefined) {
      var paymentMethodHandlesTrial = paymentGateways.find((p) => p.payment_provider_id == 'stripe') != undefined ? true : false;
      var price = test.plan.prices.find((p) => p.currency === order.billingCurrency).price;
      if (test.product.settings.trial_days > 0 && !paymentMethodHandlesTrial) {
        return false;
      } else {
        return price > 0;
      }
    } else {
      return false;
    }
  };
  const getCorrectProducts = (products) => {
    var products_ = [] as any;
    products.forEach((product, index) => {
      var test = order.products.find((p) => p.product.type === 'BASE');
      if (test !== undefined) {
        if (test.product.settings.trial_days === 0) {
          products_.push(products[index]);
        } else {
          if (test.product.settings.trial_days === product.settings.trial_days) {
            products_.push(products[index]);
          }
        }
      }
    });
    return products_;
  };

  const ProductGridBase = ({ products }: { products: Product[] }) => (
 
    <div className={styles.component}>
      {products.map((product, index) => (
        <ShoppingProductCard
          key={`product-grid-${index}`}
          {...{
            product,
          }}
          productIndex={index}
        />
      ))}
    </div>

  );
  const ProductGridExtra = ({ products }: { products: Product[] }) => (
    <div className={styles.component}>
      {getCorrectProducts(products).map((product, index) => (
        <ShoppingProductCard
          key={`product-grid-${index}`}
          {...{
            product,
          }}
          productIndex={index}
        />
      ))}
      {getCorrectProducts(products).length == 0 && <div>{t('no_extra_products_available')}</div>}
    </div>
  );

  const PaymentMethodSelection = () => (
    <Container>
      <VerticalSpacer size="xxl">
        <PaymentMethod onDone={() => {}} onPrevious={() => {}} showActionBar={false} />
      </VerticalSpacer>
    </Container>
  );

  const stepToDisplay = noExtrasInShop && currentStep >= ShoppingCartWizard.extraProducts ? currentStep - 1 : currentStep;

  return (
    <>
      <Steps style={{ fontFamily: globals['font-family'] ,marginTop:20 }} current={stepToDisplay} items={cartSteps} />

      <div className={styles.modalFooter} style={{ marginTop: 20 }}>
        {currentStep !== ShoppingCartWizard.paymentMethod && currentStep !== ShoppingCartWizard.payNow && currentStep !== ShoppingCartWizard.newCustomerDetails && (
          <SecondaryButton
            disabled={currentStep === ShoppingCartWizard.baseProduct}
            onClick={() => {
              handlePrevious();
            }}
          >
            {t('checkout_previous_step')}
          </SecondaryButton>
        )}

        {currentStep === ShoppingCartWizard.paymentMethod && isPriceHigherThenZero() ? (
          <>
            <SecondaryButton
              onClick={() => {
                handlePrevious();
              }}
            >
              {t('checkout_previous_step')}
            </SecondaryButton>

            <SecondaryButton
              onClick={() => {
                handleNext();
              }}
              disabled={order.payment === null}
            >
              {t('checkout_next_step')}
            </SecondaryButton>
          </>
        ) : (
          currentStep !== ShoppingCartWizard.paymentMethod &&
          currentStep !== ShoppingCartWizard.newCustomerDetails &&
          currentStep !== ShoppingCartWizard.payNow && (
            <SecondaryButton
              disabled={!canAdvance()}
              onClick={() => {
                handleNext();
              }}
            >
              {t('checkout_next_step')}
            </SecondaryButton>
          )
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {currentStep === ShoppingCartWizard.baseProduct && <ProductGridBase products={baseProducts} />}
        {currentStep === ShoppingCartWizard.extraProducts && <ProductGridExtra products={extraProducts} />}

        {currentStep === ShoppingCartWizard.newCustomerDetails && (
          <CustomerCreateForm
            onPrevious={handlePrevious}
            onCustomerCreated={(customer) => {
              customer.product_status = order.products !== undefined && order.products[0].product.settings.trial_days > 0 ? CustomerProductStatus.TRIAL : CustomerProductStatus.REGULAR;
              setCustomerLead(customer, handleNext);
            }}
            countries={countries}
            currencies={currencies}
          />
        )}
        {currentStep === ShoppingCartWizard.cartOverview && <CartOverview />}
        {currentStep === ShoppingCartWizard.paymentMethod && isPriceHigherThenZero() && <PaymentMethodSelection />}
        {currentStep === ShoppingCartWizard.paymentMethod && !isPriceHigherThenZero() && <FinishNoPay />}
        {currentStep === ShoppingCartWizard.payNow && <Pay />}
      </div>

      <div className={styles.modalFooter} style={{ marginTop: 20 }}>
        {currentStep !== ShoppingCartWizard.paymentMethod && currentStep !== ShoppingCartWizard.payNow && currentStep !== ShoppingCartWizard.newCustomerDetails && (
          <SecondaryButton
            disabled={currentStep === ShoppingCartWizard.baseProduct}
            onClick={() => {
              handlePrevious();
            }}
          >
            {t('checkout_previous_step')}
          </SecondaryButton>
        )}

        {currentStep === ShoppingCartWizard.paymentMethod && isPriceHigherThenZero() ? (
          <>
            <SecondaryButton
              onClick={() => {
                handlePrevious();
              }}
            >
              {t('checkout_previous_step')}
            </SecondaryButton>

            <SecondaryButton
              onClick={() => {
                handleNext();
              }}
              disabled={order.payment === null}
            >
              {t('checkout_next_step')}
            </SecondaryButton>
          </>
        ) : (
          currentStep !== ShoppingCartWizard.paymentMethod &&
          currentStep !== ShoppingCartWizard.newCustomerDetails &&
          currentStep !== ShoppingCartWizard.payNow && (
            <SecondaryButton
              disabled={!canAdvance()}
              onClick={() => {
                handleNext();
              }}
            >
              {t('checkout_next_step')}
            </SecondaryButton>
          )
        )}
      </div>
    </>
  );
}
