import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Components/Container/Container';
import { Title } from '../Components/Title/Title';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { ErrorMessage, Form, PasswordField } from '../Components/Form/Form';
import { Input, SubmitButton } from '../Components/Form/Form';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { PORTAL_VIEWS, useSwitchView } from '../views';
import { AuthContext, HashContext } from '../Contexts';
import { useLocation } from 'react-router-dom';

export const ResetPassword = () => {
  const { t } = useTranslation();

  const { hash } = useContext(HashContext);
  const { search } = useLocation();
const searchParams = new URLSearchParams(search);
  const { switchView } = useSwitchView();
  const { client, deployment, service } = useContext(HashContext);
  const { isLoggedIn } = useContext(AuthContext);
  if (isLoggedIn) {
    switchView(PORTAL_VIEWS.dashboard);
  }
  const { handleSubmit, register, formState: { errors } } = useForm<{
    password: string;
  }>();

  return (
    <Container>
      <VerticalSpacer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          <div style={{ width: '50%', marginTop: 25 }}>
            <Title level={1} title={t('reset_password_form_title')} />
            <div style={{ marginTop: 20 }}>{t('reset_password_form_info')}</div>
            <Form
              onSubmit={handleSubmit(async (data) => {
                const url = new URL(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/customers/customers/password-reset`);
                url.searchParams.set('instance', client);
                url.searchParams.set('cms', deployment);
                url.searchParams.set('crm', service);
                await axios.post(url.toString(), {
                  ...(data as {}),
                  token: searchParams.get('t'),
                  cust: searchParams.get('cust'),
                });
                switchView(PORTAL_VIEWS.home);
              })}
            >
              <div
                style={{
                  marginTop: 50,
                  justifyContent: 'center',
                }}
              >
                <div style={{ marginTop: 25 }}>
                  <PasswordField {...register('password', { required: true })} label={t('reset_password_form_password')} />
                  <ErrorMessage error={errors.password?.message} />
                </div>
                <div style={{ marginTop: 40 }}>
                  <SubmitButton>{t('reset_password_form_submit')}</SubmitButton>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </VerticalSpacer>
    </Container>
  );
};
