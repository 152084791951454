import React from 'react';
import { Buffer } from 'buffer';

type HashContextType = {
  hash: string;
  client: string;
  deployment: string;
  service: string;
  portalId: string;
  code: string;
};

export const HashContext = React.createContext<HashContextType>({
  hash: '',
  client: '',
  deployment: '',
  service: '',
  portalId: '',
  code: ''
});

type HashContextProviderProps = {
  hash: string | undefined;
  children: React.ReactNode;
  code: string;
  onInvalidHash: () => void;
};

// const getQueryStringParent = () => {
//   var queryStringKeyValue = window.parent.location.search.replace('?', '').split('&');
//   var qsJsonObject = {};
//   if (queryStringKeyValue) {
//       for (var i = 0; i < queryStringKeyValue.length; i++) {
//           qsJsonObject[queryStringKeyValue[i].split('=')[0]] = queryStringKeyValue[i].split('=')[1];
//       }
//   }
//   return qsJsonObject;
// }

export function HashContextProvider({ children, hash, code, onInvalidHash }: HashContextProviderProps) {
  if (!hash) {
    onInvalidHash();
    return null;
  }

  const { client, deployment, service, portalId } = JSON.parse(Buffer.from(hash || '', 'base64').toString());

  if (!client || !deployment || !service || !portalId) {
    onInvalidHash();
    return null;
  }

  return <HashContext.Provider value={{ hash, client, deployment, service, portalId, code }}>{children}</HashContext.Provider>;
}
