import React, { useContext, useEffect } from 'react';
import { Customer } from '../interfaces';
import { useMe, useLoginMutation, useLogoutMutation } from '../Services/Customer';
import { HashContext } from './HashContext';

type AuthContextType = {
  login: (credentials: { username: string; password: string }) => void;
  logout: () => void;
  isLoading: boolean;
  isLoggedIn: boolean;
  customer: Customer | null;
};

export const AuthContext = React.createContext<AuthContextType>({
  isLoggedIn: false,
  customer: null,
  logout: () => {},
  login: () => {},
  isLoading: true,
});

type AuthContextProvider = {
  children: React.ReactNode;
};

export function AuthContextProvider({ children }: AuthContextProvider) {
  const { client, deployment, service } = React.useContext(HashContext);
  const [authState, setAuthState] = React.useState<Omit<AuthContextType, 'login' | 'logout' | 'isLoading'>>(null);

  const { isLoading: customerFetchIsLoading, refetch } = useMe(
    { instance: client, cms: deployment, crm: service },
    {
      onSuccess: (data: any) => {
        setAuthState({
          isLoggedIn: true,
          customer: data.data,
        });
      },
      onError: () => {
        setAuthState({
          isLoggedIn: false,
          customer: null,
        });
      },
      retry: 1,
    }
  );

  const { isLoading: loginIsLoading, mutate: login } = useLoginMutation(
    { instance: client, cms: deployment, crm: service },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const { mutate: logout, isLoading: logoutIsLoading } = useLogoutMutation(
    { instance: client, cms: deployment, crm: service },
    {
      onSuccess: () => {
        setAuthState({ isLoggedIn: false, customer: null });
      },
    }
  );

  const isLoading = authState === null || loginIsLoading || logoutIsLoading || customerFetchIsLoading;

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        isLoading,
        login: doLogin,
        logout: doLogout,
      }}
    >
      <>{children}</>
    </AuthContext.Provider>
  );

  function doLogin(credentials: { username: string; password: string }) {
    setAuthState(null);
    login(credentials);
  }

  function doLogout() {
    setAuthState(null);
    logout({});
  }
}
