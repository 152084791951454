export enum Periods {
  DAY = 'days',
  WEEK = 'weeks',
  MONTH = 'months',
  YEAR = 'years',
}

export interface Plan {
  _id?: string;
  name: string;
  duration: number;
  duration_period_in: Periods;
  isSelected?: boolean;
  prices: {
    currency: string;
    price: number;
  }[];
}
