import { useTranslation } from 'react-i18next';
import { Title } from '../Title/Title';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import { useContext, useEffect, useState } from 'react';
import { HashContext} from '../../Contexts';
import { useChannels } from '../../Services/Channels';
import { Image } from 'antd';

type ProductItemDetailsProps = {
  bundle: string;
  index: number;
};

export function ChannelGrid({ bundle, index }: ProductItemDetailsProps) {
  const { client, deployment, service } = useContext(HashContext);
  const { t } = useTranslation();
  const { isLoading, isError, data } = useChannels(client, deployment, service, bundle);
  const [channels, setChannels] = useState([] as any);

  useEffect(() => {
    var channelsOut = [] as any;
    if (data?.data != undefined) {
      var categories = data?.data;

      
      categories.categories.forEach((categorie) => {
        categorie.channels.forEach((channel: any) => {
          if (channel != undefined) {
            var test = channelsOut.find((c) => c._id == channel._id);
            if (test == undefined) {
              channelsOut.push(channel);
            }
          }
        });
      });
      setChannels(channelsOut);
    }
  }, [data]);
  return (
    <VerticalSpacer>
      <Title level={3} title={t('product_content_details_channels_bundle') + ' ' + (index + 1)} />
      {!isLoading && (
        <div
          style={{
            width: '100%',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
          }}
        >
          {channels.map((channel) => {
            return (
              <div
                style={{
                  border: '2px solid #dedede',
                  backgroundColor: '#f2f2f2',
                  padding: 5,
                  borderRadius: 5,
                  // width: 115,
                  // height: 130,
                  margin: 3,
                  float: 'left',
                }}
              >
                <Image src={'https://cloudtv.akamaized.net/' + channel.images.square} alt={channel.name} style={{ width: 97.5, height: 97.5 }} />
                <div style={{ fontSize: 10, fontWeight: 'bold', width: 95, textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap' }}>{channel.name}</div>
              </div>
            );
          })}
        </div>
      )}
    </VerticalSpacer>
  );
}
