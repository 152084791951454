import { useContext, useState } from 'react';
//import 'antd/dist/antd.css';
import { HashContext } from '../Contexts';
import { PrimaryButton } from '../Components/Button/Button';
import axios from 'axios';
import { Card, Col, notification, Row } from 'antd';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { Title } from '../Components/Title/Title';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const MomoApiPaymentVerify = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { portalId, client, deployment, service } = useContext(HashContext);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const orderId = new URLSearchParams(search).get('mws');

  const [notifier, contextHolder] = notification.useNotification();

  const verifyBaseUrl = `${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/shopping-carts/${portalId}/orders/sessions/${orderId}/verify-transaction?instance=${client}&cms=${deployment}&crm=${service}&orderId=${searchParams.get('orderId')}`;

  const callbackBaseUrl = `${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/shopping-carts/${portalId}/orders/sessions/${orderId}/complete-transaction?instance=${client}&cms=${deployment}&crm=${service}&orderId=${searchParams.get('orderId')}`;
  const successUrl = `${callbackBaseUrl}&success=true`;
  const cancelUrl = `${callbackBaseUrl}&success=false`;

  const displayError = (paymentStatus: string) => {
    notifier.open({
      message: `${t('momoapi_payment_verify_error_title')}`,
      description: `${t('momoapi_payment_verify_error_description')} "${paymentStatus}"`,
      duration: 5,
    });
  };

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const session = await axios.get(verifyBaseUrl);
      console.log(session.data);

      if (session.data.paymentSuccessful) {
        window.location.replace(successUrl);
      } else {
        displayError(session.data.paymentStatus);
      }
    } catch {
      displayError('unknown error');
    }

    setTimeout(() => setIsLoading(false), 3000);
  };

  return (
    <>
      {contextHolder}
      <Row justify="center">
        <Col>
          <Card>
            <VerticalSpacer>
              <Row justify={'center'}>
                <Title level={3} title={t('momoapi_payment_verify_title')} />
              </Row>
              <div style={{ maxWidth: '500px', textAlign: 'center' }}>{t('momoapi_payment_verify_description')}</div>
              <Row justify={'center'}>
                <PrimaryButton disabled={isLoading} onClick={onSubmit}>
                  {t('momoapi_submit')}
                </PrimaryButton>
              </Row>
            </VerticalSpacer>
          </Card>
        </Col>
      </Row>
    </>
  );
};
