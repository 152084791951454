import { createContext } from 'react';
import { PortalDesign } from '../interfaces';

type DesignContextType = {
  design: PortalDesign;
};

const defaultFont = 'Arial';
const defaultFontSize = 16;
const fontColor = '#505972';
const primaryColor = '#1ac884';
const secondaryColor = '#485Fe0';
const backgroundColor = '#F0F2F5';

export const getDefaultDesign = () => {
  return {
    globals: {
      'font-family': defaultFont,
      'font-size': defaultFontSize,
      'primary-color': primaryColor,
      'secondary-color': secondaryColor,
      'background-color': backgroundColor,
      'text-color': fontColor,
    },
    navbar: {
      'background-color': secondaryColor,
      'text-color': '#ffffff',
    },
    headings: {
      h1: {
        'font-size': 32,
      },
      h2: {
        'font-size': 24,
      },
      h3: {
        'font-size': 19,
      },
      h4: {
        'font-size': 16,
      },
      h5: {
        'font-size': 13,
      },
      h6: {
        'font-size': 11,
      },
    },
    buttons: {
      primary: {
        'font-size': defaultFontSize,
        'font-color': '#ffffff',
        'border-width': 1,
        'border-radius': 20,
        'background-color': primaryColor,
        'border-color': primaryColor,
      },
      secondary: {
        'font-size': defaultFontSize,
        'font-color': '#ffffff',
        'border-width': 1,
        'border-radius': 20,
        'background-color': secondaryColor,
        'border-color': secondaryColor,
      },
    },
    forms: {
      labels: {
        'font-size': defaultFontSize,
        'font-color': fontColor,
      },
      inputs: {
        'font-size': defaultFontSize,
        'font-color': fontColor,
        'border-width': 1,
        'border-radius': 20,
        'background-color': secondaryColor,
        'border-color': secondaryColor,
      },
    },
  };
};

export const DesignContext = createContext<PortalDesign>(getDefaultDesign());

export const DesignContextProvider = DesignContext.Provider;
