import { useContext, useState } from 'react';
import styles from './PortalCartFlowRenew.module.scss';
import { OrderContext, AuthContext, DesignContext } from '../../Contexts';
import { Alert, Steps } from 'antd';
import { PrimaryButton, SecondaryButton } from '../Button/Button';
import { Pay, PaymentMethod } from '../../Pages';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import { Container } from '../Container/Container';
import { PortalCartSubscriptions } from '../PortalCartSubscriptions/PortalCartSubscriptions';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { Product, OrderTypes } from '../../interfaces';

type ProductGridProps = {
  items: Product[];
  unavailableProducts?: string[];
  showStartOptions?: boolean;
  allowStartImmediate?: boolean;
};

enum BasePlanWizard {
  planDetails = 0,
  paymentMethod = 1,
  payNow = 2,
}

export function PortalCartFlowRenew({ items, unavailableProducts = [], showStartOptions = false, allowStartImmediate = false }: ProductGridProps) {
  const { order, shopSelectProductsForRenewalOrConvert, setOrderType } = useContext(OrderContext);
  const { customer } = useContext(AuthContext);
  const { t } = useTranslation();
  const { globals } = useContext(DesignContext);
  const [currentStep, setCurrentStep] = useState(0);
  const isInTrialMode = customer.productStatus.toLocaleUpperCase() === 'TRIAL';

  useEffectOnce(() => {
    setOrderType(customer.productStatus.toUpperCase() == 'TRIAL' ? OrderTypes.ConvertFromTrail : OrderTypes.SubscriptionRenewal);
    shopSelectProductsForRenewalOrConvert(customer);
  });

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNext = () => {
    if (currentStep <= 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const PaymentMethodSelection = () => {
    return (
      <Container>
        <VerticalSpacer size="xxl">
          <PaymentMethod onDone={() => {}} onPrevious={() => {}} showActionBar={false} />
        </VerticalSpacer>
      </Container>
    );
  };

  return (
    <>
      <Steps style={{ fontFamily: globals['font-family'], marginTop:20 }} current={currentStep} items={[{ title: t('cart_order') }, { title: t('cart_payment') }]} />
      {isInTrialMode && <Alert className="mt-4 pt-3" style={{marginTop:20}} message="Trial Convert" showIcon description={t('trial_warning_preconvert')} type="warning" />}
      <div>
        {currentStep === BasePlanWizard.planDetails && <PortalCartSubscriptions />}
        {currentStep === BasePlanWizard.paymentMethod && <PaymentMethodSelection />}
        {currentStep === BasePlanWizard.payNow && <Pay />}
      </div>
      <div className={styles.modalFooter}>
        {currentStep !== BasePlanWizard.payNow && (
          <SecondaryButton
            key="submit"
            onClick={() => {
              handlePrevious();
            }}
          >
            {t('checkout_previous_step')}
          </SecondaryButton>
        )}
        {currentStep === BasePlanWizard.paymentMethod ? (
          <PrimaryButton
            onClick={() => {
              handleNext();
            }}
            disabled={order.payment === null}
          >
            {t('checkout_tabs_next_button')}
          </PrimaryButton>
        ) : (
          currentStep !== BasePlanWizard.payNow && (
            <SecondaryButton
              onClick={() => {
                handleNext();
              }}
            >
              {t('checkout_next_step')}
            </SecondaryButton>
          )
        )}
      </div>
    </>
  );
}
