import { useContext } from 'react';
import { OrderContext, AuthContext } from '../../Contexts';
import { PlanPricePortal } from '../PlanPrice/PlanPricePortal';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import styles from './ChangeBaseSubscription.module.scss';
import cx from 'classnames';
import { Radio } from 'antd';
import { getPlanPrice } from '../../Utilities/ProductUtilities';
import { getOrderCurrency } from '../../Utilities/OrderUtilities';
import { Product } from '../../interfaces';
import product from '@mwaretv/database/build/backend/models/billing/product';

type ChangeBaseSubscriptionProps = {
  product: Product;
  layoutClass?: string;
  disabled?: boolean;
  showStartOptions?: boolean;
  allowStartImmediate?: boolean;
  handleNext?: Function;
};

export function ChangeBaseSubscription({ product, layoutClass }: ChangeBaseSubscriptionProps) {
  const { order } = useContext(OrderContext);
  const { subscriptionRules, onPlanItemChange } = useContext(OrderContext);
  const { customer } = useContext(AuthContext);
  const existingSubscriptionPlan = customer && customer.subscriptions.base.plan;

  const isPlanSameAsBasePlan = (plan: any) => {
    const currency = getOrderCurrency({ ...order, customer });
    const price = getPlanPrice(plan, currency);
    const productprice = existingSubscriptionPlan.prices.find((p) => p.currency == currency).price;

    if (existingSubscriptionPlan.duration_period_in == plan.duration_period_in && existingSubscriptionPlan.duration == plan.duration) {
      if (subscriptionRules.allow_downgrades && price < productprice) {
        return true;
      } else {
        if (price >= productprice) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  return (
    <div
      className={cx(styles.component, {
        [`${layoutClass}`]: layoutClass,
      })}
    >
      {product.plans?.plans.map((plan, index) => {
        return (
          <div
            key={index}
            className={cx({
              [`${styles.selectedPlan}`]: plan.isSelected,
            })}
          >
            {isPlanSameAsBasePlan(plan) && (
              <VerticalSpacer size="xs">
                <Radio.Button
                  style={{
                    width: '100%',
                    height: 45,
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5,
                    borderWidth: 2,
                    marginBottom: 3,
                  }}
                  name={`${product._id}_plan`}
                  checked={plan.isSelected}
                  onChange={() => onPlanItemChange(product, plan, order)}
                >
                  <PlanPricePortal {...{ plan }} />
                </Radio.Button>
              </VerticalSpacer>
            )}
          </div>
        );
      })}
    </div>
  );
}
