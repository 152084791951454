import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { ShoppingCart } from '../interfaces/shoppingCart';

type Params = { instance: string; cms: string; crm: string };

const getCart = (id: string, params: Params) =>
  axios.get<ShoppingCart>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/shopping-carts/${id}`, {
    params,
  });

export const useCart = (id: string, params: Params) =>
  useQuery(['shopping-cart', 'get', params, id], async () => {
    return getCart(id, params)
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  });
