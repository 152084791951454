import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DesignContext } from '../../Contexts';
import classNames from 'classnames';
import { Image } from 'antd';
import { ICountry } from './SiteHeaderPortalContainer';
import CountryButton from './CountryButton';
import styles from './SiteHeaderPortal.module.scss';

type CountryCurrencySelectorDropdownProps = {
  layoutClass?: string;
  countries: ICountry[];
  billingCountry?: ICountry;
  setBillingCountry: (country: ICountry) => void;
};

const CountryCurrencySelectorDropDown = ({ layoutClass, countries, billingCountry, setBillingCountry }: CountryCurrencySelectorDropdownProps) => {
  const { globals, navbar } = useContext(DesignContext);
  const { t } = useTranslation();
  return (
    <div
      className={classNames(styles.languageDropdown, {
        [`${layoutClass}`]: layoutClass,
      })}
    >
      <button
        className={classNames(styles.languageDropdownButton)}
        style={{
          fontFamily: globals['font-family'],
          color: navbar['text-color'],
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div style={{ width: '100%', marginRight: 5, fontSize: 16 }}>{t('navigation_location')}:</div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25 }}>
            <Image preview={false} style={{ width: 20 }} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/country-iso2/' + billingCountry?.iso2.toLowerCase() + '.png'}></Image>
          </div>
        </div>
      </button>
      <ul
        className={styles.dropdownItems}
        style={{
          paddingTop: 6,
        }}
      >
        {countries.map((country) => (
          <CountryButton key={country?.iso2} country={country} setBillingCountry={setBillingCountry} />
        ))}
      </ul>
    </div>
  );
};

export default CountryCurrencySelectorDropDown;
