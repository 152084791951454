import React from 'react'

type ProductDescriptionProps = {
  description : string
}

export function ProductDescription({ description } : ProductDescriptionProps) {
  return (
    <div>{ description }</div>
  )
}
