import { ReactComponent as Icon } from '../../Assets/Icons/loader.svg';
import styles from './Loader.module.scss';

type LoaderProps = {
  text?: string;
};

export function Loader({ text }: LoaderProps) {
  return (
    <div
      className={styles.component}
      style={{
        color: '#999',
      }}
    >
      <Icon />
      {text && <span>{text}</span>}
    </div>
  );
}
