import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React handles escaping
    },
    debug: false, // in production, this field must be false
  });

export default i18n;
