import { getOrderCountry, getOrderCurrency } from './OrderUtilities';
import moment from 'moment';
import { Order, TaxRate, Customer, Plan, Product } from '../interfaces';

export const formatPrice = (price: number) => {
  return price.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

export const formatPriceWithCurrency = (price: number, currency: string) => {
  return `${formatPrice(price)} ${currency}`;
};

export const getOrderTotal = (order: Order, taxRates: TaxRate) => {
  return order.products.reduce((sum, { product, plan }) => {
    const priceWithTax = getProductPlanPriceWithTax(product, plan, getOrderCurrency(order), getOrderCountry(order), taxRates);
    return sum + priceWithTax;
  }, 0);
};
export const getOrderDiscount = (order: Order, taxRates: TaxRate, discount: number) => {
  return order.products.reduce((sum, { product, plan }) => {
    const priceWithTax =((getProductPlanPriceWithTax(product, plan, getOrderCurrency(order), getOrderCountry(order), taxRates) * discount) / 100) ;
    return sum + priceWithTax;
  }, 0);
};
export const getOrderTotalDiscount = (order: Order, taxRates: TaxRate, discount: number) => {
  return order.products.reduce((sum, { product, plan }) => {
    const priceWithTax = (getProductPlanPriceWithTax(product, plan, getOrderCurrency(order), getOrderCountry(order), taxRates) - (getProductPlanPriceWithTax(product, plan, getOrderCurrency(order), getOrderCountry(order), taxRates) * discount) / 100);
    return sum + priceWithTax;
  }, 0);
};

export const getOrderTotalRenew = (order: Order, taxRate: number, customer: Customer) => {
  return order.products.reduce((sum, { product, plan }) => {
    const priceWithTax = getProductPlanPriceWithTaxRenew(product, plan, customer.billing_currency, customer.billing_country, taxRate);
    return sum + priceWithTax;
  }, 0);
};
export const getOrderTotalPartial = (order: Order, taxRates: TaxRate, customerPlan: Plan, customer: Customer) => {
  return order.products.reduce((sum, { product, plan }) => {
    const priceDivider = Math.round(moment().add(plan.duration, plan.duration_period_in).diff(moment(), 'days', true));
    const priceMultiplier = Math.max(Math.round(moment.unix(customer.subscriptions.base.expiration_date).diff(moment(), 'days', true)), 0);

    const priceWithTax = getProductPlanPriceWithTax(product, plan, getOrderCurrency(order), getOrderCountry(order), taxRates);
    const price_old = getCurrentProductPlanPriceWithTax(customerPlan, getOrderCurrency(order), getOrderCountry(order), taxRates);
    return ((sum + (priceWithTax - price_old)) / priceDivider) * priceMultiplier;
  }, 0);
};

export const getOrderTotalPartialExtra = (order: Order, taxRates: TaxRate, customerPlan: Plan, customer: Customer) => {
  return order.products.reduce((sum, { product, plan }) => {
    const priceDivider = Math.round(moment().add(plan.duration, plan.duration_period_in).diff(moment(), 'days', true));
    const priceMultiplier = Math.max(Math.round(moment.unix(customer.subscriptions.base.expiration_date).diff(moment(), 'days', true)), 0);

    const priceWithTax = getProductPlanPriceWithTax(product, plan, getOrderCurrency(order), getOrderCountry(order), taxRates);

    return ((sum + priceWithTax) / priceDivider) * priceMultiplier;
  }, 0);
};

export const getOrderTaxTotal = (order: Order, taxRates: TaxRate) => {
  return order.products.reduce((sum, { product, plan }) => {
    const priceWithTax = getProductPlanTaxWithoutPrice(product, plan, getOrderCurrency(order), getOrderCountry(order), taxRates); //sander fixed
    return sum + priceWithTax;
  }, 0);
};

export const getOrderTaxTotalPartial = (order: Order, taxRates: TaxRate, customerPlan: Plan, customer: Customer) => {
  return order.products.reduce((sum, { product, plan }) => {
    const priceDivider = Math.round(moment().add(plan.duration, plan.duration_period_in).diff(moment(), 'days', true));
    const priceMultiplier = Math.max(Math.round(moment.unix(customer.subscriptions.base.expiration_date).diff(moment(), 'days', true)), 0);

    const priceWithTax = getProductPlanTaxWithoutPrice(product, plan, getOrderCurrency(order), getOrderCountry(order), taxRates);
    const price_old = getCurrentProductPlanTaxWithoutPrice(customerPlan, getOrderCurrency(order), getOrderCountry(order), taxRates);
    return sum + ((priceWithTax - price_old) / priceDivider) * priceMultiplier;
  }, 0);
};
export const getOrderTaxTotalPartialExtra = (order: Order, taxRates: TaxRate, customerPlan: Plan, customer: Customer) => {
  return order.products.reduce((sum, { product, plan }) => {
    const priceDivider = Math.round(moment().add(plan.duration, plan.duration_period_in).diff(moment(), 'days', true));
    const priceMultiplier = Math.max(Math.round(moment.unix(customer.subscriptions.base.expiration_date).diff(moment(), 'days', true)), 0);

    const priceWithTax = getProductPlanTaxWithoutPrice(product, plan, getOrderCurrency(order), getOrderCountry(order), taxRates);

    return sum + (priceWithTax / priceDivider) * priceMultiplier;
  }, 0);
};
export const getOrderTotalNoTax = (order: Order) => {
  return order.products.reduce((sum, { product, plan }) => {
    const price = getProductPlanPrice(product, plan, getOrderCurrency(order)); //sander fixed
    return sum + price;
  }, 0);
};

export const getOrderTotalPartialNoTax = (order: Order, customerPlan: Plan, customer: Customer) => {
  return order.products.reduce((sum, { product, plan }) => {
    const priceDivider = Math.round(moment().add(plan.duration, plan.duration_period_in).diff(moment(), 'days', true));
    const priceMultiplier = Math.max(Math.round(moment.unix(customer.subscriptions.base.expiration_date).diff(moment(), 'days', true)), 0);

    const price = getProductPlanPrice(product, plan, getOrderCurrency(order));
    const price_old = getCurrentProductPlanPrice(customerPlan, getOrderCurrency(order));
    return sum + ((price - price_old) / priceDivider) * priceMultiplier;
  }, 0);
};
export const getOrderTotalPartialNoTaxExtra = (order: Order, customerPlan: Plan, customer: Customer) => {
  return order.products.reduce((sum, { product, plan }) => {
    const priceDivider = Math.round(moment().add(plan.duration, plan.duration_period_in).diff(moment(), 'days', true));
    const priceMultiplier = Math.max(Math.round(moment.unix(customer.subscriptions.base.expiration_date).diff(moment(), 'days', true)), 0);

    const price = getProductPlanPrice(product, plan, getOrderCurrency(order));

    return sum + (price / priceDivider) * priceMultiplier;
  }, 0);
};

export const getCartTaxRate = (country: string, taxRates: TaxRate) => {
  if (taxRates.rates) {
    var test = taxRates.rates.find((r) => r.country == country);
    if (test == undefined) {
      return {
        name: '0%',
        rate: {
          country: country,
          percentage: 0,
        },
      };
    } else {
      return {
        name: test.rate_name,
        rate: {
          country: test.country,
          percentage: test.percentage,
        },
      };
    }
  } else {
    return {
      name: '0%',
      rate: {
        country: country,
        percentage: 0,
      },
    };
  }
};

// export const getProductPlan = (product: Product, plan: any) => {
//   const plan = product.plans.plans.find(({ _id }) => _id.toString() === plan);
//   return plan;
// };

export const getProductPlanTax = (product: Product, plan: any, currency: string, country: string, taxRates: TaxRate) => {
  const price = getProductPlanPrice(product, plan, currency);
  const taxRate = getCartTaxRate(country, taxRates) as any;
  return (price * taxRate.rate.percentage) / 100;
};

export const getProductPlanPrice = (product: Product, plan: any, currency: string) => {
  //const plan = getProductPlan(product, planId);
  const price = plan.prices.find(({ currency: c }) => c === currency).price;
  return price;
};
export const getCurrentProductPlanPrice = (plan: Plan, currency: string) => {
  const price = plan.prices.find(({ currency: c }) => c === currency).price;
  return price;
};

const getProductPlanPriceWithTaxRenew = (product: Product, plan: any, currency: string, country: string, taxRate: number) => {
  const price = getProductPlanPrice(product, plan, currency);
  const priceWithTax = (price * (100 + taxRate)) / 100;
  return priceWithTax;
};
const getProductPlanPriceWithTax = (product: Product, plan: any, currency: string, country: string, taxRates: TaxRate) => {
  const price = getProductPlanPrice(product, plan, currency);
  const taxRate = getCartTaxRate(country, taxRates) as any;
  const priceWithTax = (price * (100 + taxRate.rate.percentage)) / 100;
  return priceWithTax;
};

const getCurrentProductPlanPriceWithTax = (plan: Plan, currency: string, country: string, taxRates: TaxRate) => {
  const price = getCurrentProductPlanPrice(plan, currency);
  const taxRate = getCartTaxRate(country, taxRates) as any;
  const priceWithTax = (price * (100 + taxRate.rate.percentage)) / 100;
  return priceWithTax;
};

const getProductPlanTaxWithoutPrice = (product: Product, plan: any, currency: string, country: string, taxRates: TaxRate) => {
  const price = getProductPlanPrice(product, plan, currency);
  const taxRate = getCartTaxRate(country, taxRates) as any;
  const priceWithTax = (price * (100 + taxRate.rate.percentage)) / 100 - price;
  return priceWithTax;
};

const getCurrentProductPlanTaxWithoutPrice = (plan: Plan, currency: string, country: string, taxRates: TaxRate) => {
  const price = getCurrentProductPlanPrice(plan, currency);
  const taxRate = getCartTaxRate(country, taxRates) as any;
  const priceWithTax = (price * (100 + taxRate.rate.percentage)) / 100 - price;
  return priceWithTax;
};

export const getTaxRateName = (order: any, taxRates: TaxRate) => {
  const country = getOrderCountry(order);
  const taxRate = getCartTaxRate(country, taxRates) as any;
  return taxRate.rate.percentage;
};
export const getDiscountName = (discount: number) => {
  return discount;
};
