import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { ShoppingCartContext } from './ShoppingCartContext';
import _ from 'lodash';
import { DesignContextProvider, getDefaultDesign } from './DesignContext';

type DesignContextProviderProps = {
  children: React.ReactNode;
};

export function DesignContextProviderWrapper({ children }: DesignContextProviderProps) {
  const { design: baseDesign } = useContext(ShoppingCartContext);

  const design = _.merge(getDefaultDesign(), baseDesign);

  const {
    globals,
    buttons: { primary, secondary },
    headings,
    navbar,
    forms: { labels, inputs },
  } = design;

  const fontString = globals['font-family'];

  const size = isMobile ? '16px' : '18px';

  return (
    <DesignContextProvider value={design}>
      <Helmet
        style={[
          {
            cssText: `
            body {
              font-family: ${globals['font-family']};
              background-color: ${globals['background-color']};
              font-size: ${size};
              color: ${globals['text-color']}
            }
        `,
          },
        ]}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link href={`https://fonts.googleapis.com/css2?family=${fontString}&display=swap`} rel="stylesheet" />
      </Helmet>
      {children}
    </DesignContextProvider>
  );
}
