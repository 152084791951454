import { BaseSubscriptionCard } from '../BaseSubscriptionCard/BaseSubscriptionCard';
import { Subscription } from '../../interfaces/customerSubscriptions';

import styles from './BaseSubscriptionOverview.module.scss';
import { useContext } from 'react';
import { AuthContext } from '../../Contexts';
import { PendingBaseSubscriptionCard } from '../PendingBaseSubscriptionCard/PendingBaseSubscriptionCard';

type BaseSubscriptionGridProps = {
  subscriptions: Subscription[];
  showActionButtons: boolean;
  showPrice?: boolean;
  allowChangingBase?: boolean;
  allowRenewBase?: boolean;
};

export function BaseSubscriptionOverview({ subscriptions, showActionButtons, showPrice, allowChangingBase = true, allowRenewBase = true }: BaseSubscriptionGridProps) {
  const { customer } = useContext(AuthContext);
  return (
    <ul className={styles.component}>
      {subscriptions.map((subscription, i) => (
        <li key={i}>
          <BaseSubscriptionCard
            {...{
              subscription,
              allowChangingBase,
              showPrice,
              showActionButtons,
              allowRenewBase,
              type: 'BASE',
            }}
          />
        </li>
      ))}
      {customer.subscriptions.pending_base !== undefined && <PendingBaseSubscriptionCard subscription={customer.subscriptions.pending_base} />}
    </ul>
  );
}
