import { createContext, useContext, useState, useEffect } from 'react';
import { HashContext } from './HashContext';
import axios from 'axios';

import { Loader } from '../Components/Loader/Loader';
import { Country } from '../Types/Country';
import { ShoppingCart } from '../interfaces';
import { useCart } from '../Services/Cart';
import { listCountries } from '../Services/Countries';

export const CustomerPortalContext = createContext<ShoppingCart & { currencies: []; countries: Country[] }>({} as any);

type CustomerPortalContextProviderProps = {
  children: React.ReactNode;
};

export function CustomerPortalContextProvider({ children }: CustomerPortalContextProviderProps) {
  const { client, deployment, service, portalId } = useContext(HashContext); 
  const [currencies, setCurrencies] = useState(null);
  const [countries, setCountries] = useState([]);

  const { isLoading, isError, data } = useCart(portalId, {
    instance: client,
    cms: deployment,
    crm: service,
  });

  // axios.get(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/shopping-carts/${id}`, {
  useEffect(() => {
    async function loadDetails() {
      const { data: deploymentCurrencies } = await axios.get(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/deployments/find`, { params: { client, page: 1, limit: 9999, s: JSON.stringify({ name: deployment })}});
      setCurrencies(deploymentCurrencies);

      const { data } = await listCountries();
      setCountries(data.data.countries);
    }
    loadDetails();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {isError && <span>Something went wrong</span>}
      {!isLoading && !isError && <CustomerPortalContext.Provider value={{ ...data.data, ...currencies, countries }}>{children}</CustomerPortalContext.Provider>}
    </>
  );
}
