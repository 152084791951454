import React from 'react'
import { PrimaryButton } from '../Button/Button'

type TextWithButtonProps = {
  text : string
  buttonText : string
  onButtonClick : () => void
}

export function TextWithButton({ text, buttonText, onButtonClick } : TextWithButtonProps) {
  return (
    <div>
      <p>{text}</p>
      <PrimaryButton onClick={onButtonClick}>{buttonText}</PrimaryButton>
    </div>
  )
}
