import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptop } from '@fortawesome/pro-light-svg-icons';
import { faMobile, faTablet, faTelevision } from '@fortawesome/pro-regular-svg-icons';

export function DeviceCard({ deleteDevice, device, index, customer }) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        border: '2px solid #dedede',
        padding: 20,
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#fff',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        {device.formfactor == 'Desktop' && <FontAwesomeIcon icon={faLaptop}></FontAwesomeIcon>}
        {device.formfactor == 'Phone' && <FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>}
        {device.formfactor == 'Tablet' && <FontAwesomeIcon icon={faTablet}></FontAwesomeIcon>}
        {device.formfactor == 'Television' && <FontAwesomeIcon icon={faTelevision}></FontAwesomeIcon>}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div style={{ fontSize: 16 }}>
          <b>{device.model}</b>
        </div>
        <div style={{ fontSize: 16 }}>{device.uuid}</div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignSelf: 'center',
          flexDirection: 'row',
        }}
      >
        {deleteDevice && (
          <div style={{ width: 200 }}>
            <PrimaryButton onClick={() => deleteDevice(customer, index)}>{t('devices_delete')}</PrimaryButton>
          </div>
        )}
      </div>
    </div>
  );
}
