import { useContext, useState } from 'react';
//import 'antd/dist/antd.css';
import { OrderContext, HashContext } from '../Contexts';
import { Form, Input, Select } from '../Components/Form/Form';
import { v4 } from 'uuid';
import axios from 'axios';
import { SubmitButton } from '../Components/Form/Form';
import { Title } from '../Components/Title/Title';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { useTranslation } from 'react-i18next';
import { notification, Row, Col, Card } from 'antd';
import { useForm } from 'react-hook-form';

export const MomoApiPayment = () => {
  const { order } = useContext(OrderContext);
  const { portalId, client, deployment, service } = useContext(HashContext);
  const [sesssionId] = useState(v4());
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<{ partyIdType: string; partyId: string }>({
    defaultValues: {
      partyIdType: 'MSISDN',
      partyId: '',
    },
  });

  const [notifier, contextHolder] = notification.useNotification();

  const displayError = () => {
    notifier.open({
      message: `${t('momoapi_payment_error_title')}`,
      description: `${t('momoapi_payment_error_description')}`,
      duration: 5,
    });
  };

  const baseUrl = process.env.REACT_APP_TVMS_API_BASE;
  const formSubmitUrl = `${baseUrl}/api/v1/billing/shopping-carts/${portalId}/orders/sessions/${sesssionId}/start-transaction`;

  const submitStartTransaction = async (data: any) => {
    setIsLoading(true);
    try {
      const { partyIdType, partyId } = data;

      const response = await axios.post(
        formSubmitUrl,
        { orderData: order, momoapi: { partyIdType, partyId } },
        {
          params: { instance: client, cms: deployment, crm: service },
        }
      );

      
      window.location.replace(response.data.redirectUrl);
    } catch {
      displayError();
    }

    setTimeout(() => setIsLoading(false), 3000);
  };

  return (
    <>
      {contextHolder}
      <Row justify="center">
        <Col>
          <Card>
            <VerticalSpacer>
              <Row justify={'center'}>
                <Title level={3} title={t('momoapi_payment_title')} />
              </Row>
              <div>{t('momoapi_payment_description')}</div>
              <Form onSubmit={handleSubmit(submitStartTransaction)}>
                <Select {...register("partyIdType", { required: true })} label={t('momoapi_party_id_type')}>
                  <option value="MSISDN" key="MSISDN">
                    {t('momoapi_party_id_type_msisdn')}
                  </option>
                  <option value="EMAIL" key="EMAIL">
                    {t('momoapi_party_id_type_email')}
                  </option>
                  <option value="PARTY_CODE" key="PARTY_CODE">
                    {t('momoapi_party_id_type_party_code')}
                  </option>
                </Select>
                <Input {...register("partyId", { required: true })} type="text" label={t('momoapi_party_id')} />
                <Row justify={'center'}>
                  <SubmitButton disabled={isLoading}>{t('momoapi_submit')}</SubmitButton>
                </Row>
              </Form>
            </VerticalSpacer>
          </Card>
        </Col>
      </Row>
    </>
  );
};
