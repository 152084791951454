import valid from 'card-validator';

export function validateCardNumber(cardNumber: string): any {
  return valid.number(cardNumber);
}

export function validateExpirationDate(expirationDate: string): boolean {
  return valid.expirationDate(expirationDate).isValid;
}

export function validateCVV(cvv: string): boolean {
  return valid.cvv(cvv).isValid;
}
