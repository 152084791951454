import { createContext, useContext, useEffect, useState } from 'react';
import { OrderStatus } from '@mwaretv/database/build/backend/enums/billing/orderStatus';
import { PaymentStatus } from '@mwaretv/database/build/backend/enums/billing/orderPaymentStatus';
import { getCartProducts } from '../Services/CartProducts';
import { getOrderSession } from '../Services/Order';
import { ShoppingCartContext } from './ShoppingCartContext';
import { OrderTypes, CustomerLead, PaymentMethod, SubscriptionRules, TaxRate, Customer, Plan, Order, Product } from '../interfaces';
import { getPaymentProviderSchemas } from '../Services/PaymentMethodSettings';
import { AuthContext } from './AuthContext';
import { HashContext } from './HashContext';
import { useLocation } from 'react-router-dom';
import { PORTAL_VIEWS, useSwitchView } from '../views';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { getAffiliate, getCoupon } from '../Services/Coupons';

type OrderContextType = {
  order?: Order;
  lastOrder?: Order;
  isLoading: boolean;
  addProduct: (product: Product) => void;
  removeProduct: (product: Product, selectedPlan) => void;
  setOrderType: (type: OrderTypes) => void;
  setShopBillingData: (currency: string, country: string) => void;
  hasBaseProduct: () => boolean;
  hasProductInOrder: (product: Product) => boolean;
  setCustomerLead: (customer: CustomerLead, onSuccess?: () => void, onError?: () => void) => void;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  setLastOrder?: (order: Order) => void;
  shopSelectBaseProduct?: (product, selectedPlan) => void;
  shopSelectExtraProduct?: (product, selectedPlan) => void;
  shopSelectExtraProductRemove?: (product, customer, selectedPlan) => void;
  shopSelectProductsForRenewalOrConvert?: (customer) => void;
  onPlanItemChange?: (product, selectedPlan, order) => void;
  subscriptionRules?: SubscriptionRules;
  taxRates?: TaxRate;
  discount?: number;
  baseProducts?: any[];
  extraProducts?: any[];
  paymentProviders: any;
};

const initialOrder: OrderContextType = {
  addProduct: () => {},
  removeProduct: () => {},
  setOrderType: () => {},
  setShopBillingData: () => {},
  hasBaseProduct: () => false,
  hasProductInOrder: () => false,
  setCustomerLead: () => {},
  setPaymentMethod: () => {},
  setLastOrder: () => {},
  isLoading: true,
  order: {
    _id: '',
    session: '',
    status: OrderStatus.IN_PROGRESS,
    products: [],
    cart: '',
    customerLead: undefined,
    payment_provider_payload: {},
    payment_provider_ref_id: '',
    payment: null,
    discount: 0,
  },
  subscriptionRules: {} as SubscriptionRules,
  taxRates: {} as TaxRate,
  baseProducts: [],
  extraProducts: [],
  paymentProviders: [],
};

export const OrderContext = createContext<OrderContextType>(initialOrder);

type OrderContextProviderProps = {
  children: React.ReactNode;
  // lastOrder: Order;
  // setLastOrder: (order: Order) => void;
};

const initialOrderState = {
  _id: '',
  session: '',
  status: OrderStatus.IN_PROGRESS,
  products: [],
  cart: '',
  customer: null,
  customerLead: undefined,
  payment_provider_payload: {},
  payment_provider_ref_id: '',
  billingCurrency: null,
  billingCountry: null,
  billingTaxrate: 0,
  payment: null,
  discount: 0,
  type: OrderTypes.NewCustomer,
};

export function OrderContextProvider({ children }: OrderContextProviderProps) {
  const { client, deployment, service, portalId } = useContext(HashContext);
  const [baseProducts, setBaseProducts] = useState([]);
  const [extraProducts, setExtraProducts] = useState([]);
  const { customer, isLoggedIn } = useContext(AuthContext);
  const { subscription_rules } = useContext(ShoppingCartContext);
  const { tax_rates } = useContext(ShoppingCartContext);
  const [paymentProvidersLoading, setpaymentProvidersLoading] = useState(true);
  const [subscriptionRules, setSubscriptionRules] = useState({} as SubscriptionRules);
  const [taxRates, setTaxRates] = useState({} as TaxRate);
  const [paymentProviders, setPaymentProviders] = useState([]);
  const { search } = useLocation();
  const [orderReady, setOrderReady] = useState(false);
  const { switchView } = useSwitchView();
  const [notifier, context] = notification.useNotification();
  const { t } = useTranslation();

  const params = new URLSearchParams(search);

  useEffect(() => {
    const fetchPaymentProviders = async () => {
      const { data } = await getPaymentProviderSchemas();
      setPaymentProviders(data);
      setpaymentProvidersLoading(false);
    };
    fetchPaymentProviders();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setOrderReady(false);
      setOrder((order) => initialOrderState);

      const productResults = await getCartProducts(client, deployment, service, portalId, {
        type: { $eq: 'BASE' },
      });
      const products  = productResults?.data?.filter((p) => p.type === 'BASE');
      const extras = productResults?.data?.filter((p) => p.type === 'EXTRA');

      // const { data: extras } = await getCartProducts(client, deployment, service, portalId, {
      //   type: { $eq: 'EXTRA' },
      // });

      if (subscription_rules != undefined) {
        setSubscriptionRules(subscription_rules[0] as SubscriptionRules);
      }
      if (tax_rates != undefined) {
        setTaxRates(tax_rates[0] as TaxRate);
      }

      if (!customer) {
        setBaseProducts(products.filter((i) => i.plans !== undefined));
        setExtraProducts(extras.filter((i) => i.plans !== undefined));
        setOrder((order) => ({ ...order, customer: undefined }));

        if (params.get('mwsf') != null) {
          try {
            const { data: receivedOrder } = await getOrderSession(client, deployment, service, portalId, params.get('mwsf'));

            const { _id, session, ...cleanOrder } = receivedOrder;

            if (receivedOrder.payment.status === PaymentStatus.AWAITING_PAYMENT || receivedOrder.payment.status === PaymentStatus.PAYMENT_FAILED) {
              //console.log('Reinitialized session using previous transaction', params.get('mwsf'));
              setOrder((order) => ({ ...order, ...cleanOrder }));
              switchView(PORTAL_VIEWS.shop, { mwsf: undefined });
              notifier.info({ message: t('order_cancelled_info'), duration: 15 });
            }
          } catch (e) {}
        }
        setOrderReady(true);
      } else {
        const newProducts = products
          .filter((i) => i.plans !== undefined)
          .map(({ plans: { plans: existingPlans, ...rest }, ...product }) => {
            return {
              ...product,
              ...{
                plans: {
                  ...rest,
                  plans: existingPlans.map((e: any) => ({
                    ...e,
                    isSelected: e._id === customer.subscriptions.base.plan,
                  })),
                },
              },
            };
          });

        const excludeNonUserCurrencies = ({ prices }) => prices.find(({ currency }) => currency === customer.billing_currency);
        const hideProductsWithEmptyPlans = ({ plans: { plans } }) => plans.length;

        const filteredProducts = newProducts
          .map(({ plans: { plans: existingPlans, ...rest }, ...product }) => ({
            ...product,
            ...{
              plans: {
                ...rest,
                plans: existingPlans.filter(excludeNonUserCurrencies),
              },
            },
          }))
          .filter(hideProductsWithEmptyPlans);

        setBaseProducts(filteredProducts);
        setOrder((order) => ({ ...order, customer }));
      }
      setOrderReady(true);
    };

    loadData();
  }, [customer]);

  const [order, setOrder] = useState(initialOrderState);

  // console.log({
  //   order,
  // });

  const shopSelectProductsForRenewalOrConvert = (customer: Customer) => {
    var products = [] as any;
    if (customer.subscriptions.pending_base == undefined) {
      products.push({
        product: customer.subscriptions.base.product,
        plan: customer.subscriptions.base.plan,
      });
    } else {
      products.push({
        product: customer.subscriptions.pending_base.product,
        plan: customer.subscriptions.pending_base.plan,
      });
    }

    const extras = customer.subscriptions.extras;
    extras.forEach((extra) => {
      products.push({
        product: extra.product,
        plan: extra.plan,
      });
    });

    setOrder((order) => ({
      ...order,
      type: customer.productStatus.toUpperCase() == 'TRIAL' ? OrderTypes.ConvertFromTrail : OrderTypes.SubscriptionRenewal,
      products: products,
    }));
  };

  const shopSelectBaseProduct = (product: Product, selectedPlan: Plan) => {
    const newBaseProducts = baseProducts.map((i) => {
      const existingPlans = i.plans.plans;
      return {
        ...i,
        ...{
          plans: {
            plans: existingPlans.map((plan) => ({
              ...plan,
              isSelected: i._id === product._id && plan._id === selectedPlan._id,
            })),
          },
        },
      };
    });
    const newExtraProducts = extraProducts.map((i) => {
      const existingPlans = i.plans.plans;
      return {
        ...i,
        ...{
          plans: {
            plans: existingPlans.map((plan) => ({
              ...plan,
              isSelected: false,
            })),
          },
        },
      };
    });
    setBaseProducts(newBaseProducts);
    setExtraProducts(newExtraProducts);
    setOrder((order) => ({
      ...order,
      products: [
        {
          product: product,
          plan: selectedPlan,
        },
      ],
    }));
  };

  const shopSelectExtraProduct = (product: Product, selectedPlan: Plan | null) => {
    const newExtraProducts = extraProducts.map((i) => {
      const existingPlans = i.plans.plans;
      return {
        ...i,
        ...{
          plans: {
            plans: existingPlans.map((plan) => ({
              ...plan,
              isSelected: i._id !== product._id ? plan.isSelected : selectedPlan !== null && plan._id === selectedPlan._id,
            })),
          },
        },
      };
    });

    const products = order.products.filter((x) => x.product._id !== product._id);
    setExtraProducts(newExtraProducts);

    if (selectedPlan === null) {
      setOrder((order) => ({
        ...order,
        products: products,
      }));
    } else {
      setOrder((order) => ({
        ...order,
        products: [
          ...products,
          {
            product,
            plan: selectedPlan,
          },
        ],
      }));
    }
  };

  const shopSelectExtraProductRemove = (product: Product, customer: any, selectedPlan: Plan | null) => {
    setOrder((order) => ({
      ...order,
      products: [
        {
          product,
          plan: selectedPlan,
        },
      ],
      type: OrderTypes.ExtraSubscriptionRemove,
    }));
  };

  const onPlanItemChange = (product, selectedPlan, order) => {
    const newProducts = baseProducts.map(({ plans: { plans: existingPlans, ...rest }, ...product }) => {
      return {
        ...product,
        ...{
          plans: {
            ...rest,
            plans: existingPlans.map(({ _id, ...rest }) => ({
              ...rest,
              _id,
              isSelected: _id === selectedPlan._id,
            })),
          },
        },
      };
    });

    setBaseProducts(newProducts);
    var products = order.products.filter((p) => p.product.type != 'BASE');
    products.push({
      product,
      plan: selectedPlan,
    });
    setOrder((order) => ({
      ...order,
      products: products,
    }));
  };

  return (
    <OrderContext.Provider
      value={{
        addProduct,
        removeProduct,
        setOrderType,
        setShopBillingData,
        hasBaseProduct,
        hasProductInOrder,
        setCustomerLead,
        setPaymentMethod,
        shopSelectBaseProduct,
        shopSelectExtraProduct,
        shopSelectExtraProductRemove,
        shopSelectProductsForRenewalOrConvert,
        onPlanItemChange,
        isLoading: paymentProvidersLoading,
        baseProducts: baseProducts as any,
        extraProducts: extraProducts as any,
        order,
        subscriptionRules,
        taxRates,
        paymentProviders,
      }}
    >
      {context}
      {order && orderReady && children}
    </OrderContext.Provider>
  );
  //DSWW99 // DSWW98
  async function checkCouponVanityCode(customer: CustomerLead) {
    // console.log(customer.coupon);
    if (customer.coupon != undefined && customer.coupon != '') {
      var affiliate = (await getAffiliate(client, deployment, service, customer.coupon.toUpperCase())) as any;
      var coupon = (await getCoupon(client, deployment, service, customer.coupon.toUpperCase())) as any;
      //console.log(affiliate.data);
      if (affiliate.data.data.vanity != undefined) {
        return affiliate.data.data.discount.amount;
      } else if (coupon.data.data.vanity != undefined) {
        return coupon.data.data.discount.amount;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  function addProduct(product: Product) {
    let { products = [] } = order || {};

    if (product.type === 'BASE') {
      //products = products.filter((x) => x.product.type !== 'BASE');
      products = [];
    }

    if (product.type === 'EXTRA') {
      products = products.filter((x) => x.product._id !== product._id);
    }

    const selectedPlan = product.plans.plans.find((plan) => plan.isSelected);

    setOrder((order) => ({
      ...order,
      products: [
        ...products,
        {
          product,
          plan: selectedPlan,
          //plan_id: selectedPlan._id,
        },
      ],
    }));
  }

  function removeProduct(product: Product, selectedPlan: Plan) {
    let { products = [] } = order || {};

    const newBaseProducts = baseProducts.map((i) => {
      const existingPlans = i.plans.plans;
      return {
        ...i,
        ...{
          plans: {
            plans: existingPlans.map((plan) => ({
              ...plan,
              isSelected: i._id === product._id && plan._id === selectedPlan._id,
            })),
          },
        },
      };
    });
    const newExtraProducts = extraProducts.map((i) => {
      const existingPlans = i.plans.plans;
      return {
        ...i,
        ...{
          plans: {
            plans: existingPlans.map((plan) => ({
              ...plan,
              isSelected: false,
            })),
          },
        },
      };
    });
    setBaseProducts(newBaseProducts);
    setExtraProducts(newExtraProducts);

    products = products.filter((x) => x.product._id !== product._id);
    setOrder((order) => ({ ...order, products }));
  }

  function setOrderType(type: OrderTypes) {
    setOrder((order) => ({
      ...order,
      type: type,
    }));
  }

  function setShopBillingData(currency: string, country: string) {
    if (isLoggedIn) {
      if (customer) {
        setOrder((order) => ({
          ...order,
          billingCountry: customer.billing_country ?? 'USA',
          billingCurrency: customer.billing_currency ?? 'USD',
          billingTaxrate: customer.billing_taxrate ?? 0,
        }));
      }
    } else {
      setOrder((order) => ({
        ...order,
        billingCurrency: currency,
        billingCountry: country,
        billingTaxrate: tax_rates != undefined && tax_rates[0] != undefined && tax_rates[0].rates != undefined && tax_rates[0].rates.find((r) => r.country == country) != undefined ? tax_rates[0].rates.find((r) => r.country == country).percentage : 0,
      }));
    }
  }

  function hasBaseProduct() {
    let { products = [] } = order || {};

    return products.find((x) => x.product.type === 'BASE') !== undefined;
  }

  function hasProductInOrder(product: Product) {
    let { products = [] } = order || {};

    return products.find((x) => x.product._id === product._id) !== undefined;
  }

  async function setCustomerLead(customer: CustomerLead, onSuccess: () => void, onError: () => void) {
    var discount = await checkCouponVanityCode(customer);
    // console.log(discount);
    setOrder((order) => ({ ...order, customerLead: customer, discount: discount }));
    if (onSuccess) {
      onSuccess();
    }
  }

  async function setPaymentMethod(paymentMethod: PaymentMethod) {
    setOrder((order) => ({
      ...order,
      payment: {
        gateway: paymentMethod,
        status: PaymentStatus.AWAITING_PAYMENT,
      },
    }));
  }

  // async function initializeSession() {
  //   const {
  //     data: { session: serverSession, cart, _id },
  //   } = (await getOrderSession(client, deployment, service, portalId, session)) as any;

  //   setOrder({
  //     ...order,
  //     ...{ cart, _id, session: serverSession },
  //   });

  //   const result = await updateOrderSession(client, deployment, service, portalId, session, {
  //     ...order,
  //     ...{ cart, _id, session: serverSession, customer },
  //   } as any);

  //   return { serverSession };
  // }
}
