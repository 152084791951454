import React from 'react';
import { AuthContext } from '../../Contexts';
import { useTranslation } from 'react-i18next';

import { Title } from '../Title/Title';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import { Paragraph } from '../Paragraph/Paragraph';
import { Container } from '../Container/Container';
import { PORTAL_VIEWS, useSwitchView } from '../../views';

type RestrictedRouteProps = {
  children: React.ReactNode;
};

export function RestrictedRoute({ children }: RestrictedRouteProps) {
  const { isLoggedIn } = React.useContext(AuthContext);
  const { switchView } = useSwitchView();
  const { t } = useTranslation();

  if (!isLoggedIn) {
    switchView(PORTAL_VIEWS.home);
  }

  return (
    <>
      {!isLoggedIn && (
        <Container>
          <VerticalSpacer>
            <Title level={1} title={t('restricted_route_title')} />
            <Paragraph text={t('restricted_route_info_text')} />
          </VerticalSpacer>
        </Container>
      )}
      {isLoggedIn && <>{children}</>}
    </>
  );
}
