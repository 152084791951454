import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerTitle } from '@mwaretv/database/build/backend/enums/customers/customerTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { capitalizeFirstLetter } from '../../Utilities/StringUtilities';
import { Input, Form, Select, HorizontalRow, CustomPhoneInput, PasswordField, Label, ErrorMessage } from '../Form/Form';
import { Title } from '../Title/Title';
import { OrderContext, ShoppingCartContext, HashContext, DesignContext } from '../../Contexts';
import { Country } from '../../Types/Country';
import { SecondaryButton } from '../Button/Button';
import { RegionDropdown } from 'react-country-region-selector';
import { AddressProfile } from '@mwaretv/database/build/backend/enums/billing/addressProfile';
import { CustomerLead } from '../../interfaces';
import { checkCustomer } from '../../Services/Customer';

type CustomerCreateFormProps = {
  onCustomerCreated?: (customer: CustomerLead) => void;
  currencies?: [];
  countries?: Country[];
  onPrevious?: any;
};

export function CustomerCreateForm({ onCustomerCreated, countries, onPrevious }: CustomerCreateFormProps) {
  const { order } = useContext(OrderContext);
  const {
    customer: { mandatory_email, mandatory_phone, address },
    enable_coupons,
  } = useContext(ShoppingCartContext);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomerLead>({
    defaultValues: order.customerLead,
  });
  const { t } = useTranslation();
  const country = countries.find((c) => c.iso3 === order.billingCountry);
  const { client, deployment, service } = useContext(HashContext);
  const [region, setRegion] = useState('');

  const onSubmit: SubmitHandler<CustomerLead> = (data) => {
    if (data.password !== data.password_check) {
      alert(t('error_passwordsdontmatch'));
    } else {
      checkCustomer(client, deployment, service, { userName: data.username, email: data.email }).then((result: any) => {
        if (!result.data.isValid) {
          if (result.data.message === 'username') {
            alert(t('error_usernameissue'));
          } else if (result.data.message === 'email') {
            alert(t('error_emailissue'));
          }
        } else {
          // TODO Rewrite this
          if (data.mobile === '') {
            data.mobile = undefined;
          }
          if (data.phone === '') {
            data.phone = undefined;
          }
          if (data.email === '') {
            data.email = undefined;
          }
          onCustomerCreated({
            ...data,
            ...{
              country: country.name,
              state: region,
            },
            ...{
              billing_country: country.iso3,
              billing_currency: country.currency,
              billing_taxrate: order.billingTaxrate,
            },
          });
        }
      });
    }
  };

  const {
    globals,
    forms: { inputs },
  } = useContext(DesignContext);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SecondaryButton
          onClick={() => {
            onPrevious();
          }}
          type="button"
        >
          {t('checkout_previous_step')}
        </SecondaryButton>
        <div style={{ margin: 5 }}></div>

        <SecondaryButton key="submits" disabled={false} type="submit">
          {t('checkout_next_step')}
        </SecondaryButton>
      </div>
      <div
        style={{
          border: '1px solid #dedede',
          padding: 20,
          backgroundColor: '#fff',
          borderRadius: 5,
        }}
      >
        <div style={{ width: 100 }}>
          <Select {...register('title', { required: true })} label={t('customer_form_labels_title')}>
            {Object.values(CustomerTitle).map((x) => (
              <option key={x} value={x}>
                {capitalizeFirstLetter(x)}
              </option>
            ))}
          </Select>
        </div>

        <HorizontalRow>
          <div>
            <Input {...register('first_name', { required: t('field_validation_required') })} label={t('customer_form_labels_first_name')} />
            <ErrorMessage error={errors.first_name?.message} />
          </div>
          <div>
            <Input {...register('last_name', { required: t('field_validation_required') })} label={t('customer_form_labels_last_name')} />
            <ErrorMessage error={errors.last_name?.message} />
          </div>
        </HorizontalRow>
        <div>
          <Input {...register('email', { required: mandatory_email ? t('field_validation_required') : false, pattern: { value: /^\S+@\S+\.\S+$/, message: t('field_validation_email') } })} label={t('customer_form_labels_email')} />
          <ErrorMessage error={errors.email?.message} />
        </div>
        <HorizontalRow>
          <div>
            <CustomPhoneInput required={false} control={control} name="phone" defaultValue={country.iso2} label={t('customer_form_labels_phone')} />
            <ErrorMessage error={errors.phone?.message} />
          </div>
          <div>
            <CustomPhoneInput required={mandatory_phone} control={control} name="mobile" defaultValue={country.iso2} label={t('customer_form_labels_mobile')} />
            <ErrorMessage error={errors.mobile?.message} />
          </div>
        </HorizontalRow>
      </div>

      {address !== AddressProfile.NONE && (
        <div
          style={{
            border: '1px solid #dedede',
            padding: 20,
            backgroundColor: '#fff',
            borderRadius: 5,
          }}
        >
          <Title level={3} title={t('customer_form_address')} />
          <HorizontalRow>
            <Input value={country.name} disabled label={t('customer_form_labels_country')} />
            <Label>
              <span>{t('customer_form_labels_state')}</span>
              <RegionDropdown
                country={country.name}
                value={region}
                onChange={(val) => setRegion(val)}
                style={{
                  fontFamily: globals['font-family'],
                  fontSize: 16,
                  color: inputs['font-color'],
                  borderWidth: inputs['border-width'],
                  borderRadius: inputs['border-radius'],
                  borderColor: inputs['border-color'],
                  backgroundColor: inputs['background-color'],
                  borderStyle: 'solid',
                  padding: 6,
                }}
              />
            </Label>
          </HorizontalRow>

          {address === AddressProfile.FULL && (
            <>
              <HorizontalRow>
                <div>
                  <Input {...register('city', { required: t('field_validation_required') })} label={t('customer_form_labels_city')} />
                  <ErrorMessage error={errors.city?.message} />
                </div>
                <div>
                  <Input {...register('zipcode', { required: t('field_validation_required') })} label={t('customer_form_labels_zipcode')} />
                  <ErrorMessage error={errors.zipcode?.message} />
                </div>
              </HorizontalRow>
              <div>
                <Input {...register('street', { required: t('field_validation_required') })} label={t('customer_form_labels_street')} />
                <ErrorMessage error={errors.street?.message} />
              </div>
            </>
          )}
        </div>
      )}
      <div
        style={{
          border: '1px solid #dedede',
          padding: 20,
          backgroundColor: '#fff',
          borderRadius: 5,
        }}
      >
        <Title level={3} title={t('customer_form_account')} />
        <div>
          <Input {...register('username', { required: t('field_validation_required') })} label={t('customer_form_labels_username')} />
          <ErrorMessage error={errors.username?.message} />
        </div>

        <div>
          <PasswordField {...register('password', { required: t('field_validation_required') })} label={t('customer_form_labels_password')} />
          <ErrorMessage error={errors.password?.message} />
        </div>
        <div>
          <PasswordField {...register('password_check', { required: t('field_validation_required') })} label={t('customer_form_labels_retypepassword')} />
          <ErrorMessage error={errors.password_check?.message} />
        </div>
      </div>
      {enable_coupons == true && (
        <div
          style={{
            border: '1px solid #dedede',
            padding: 20,
            backgroundColor: '#fff',
            borderRadius: 5,
          }}
        >
          <Title level={3} title={t('customer_form_coupon')} />
          {t('customer_form_coupon_info')}
          <div style={{ marginTop: 20 }}>
            <Input {...register('coupon')} label={t('customer_form_labels_coupon')} />
            <ErrorMessage error={errors.username?.message} />
          </div>
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SecondaryButton
          onClick={() => {
            onPrevious();
          }}
          type="button"
        >
          {t('checkout_previous_step')}
        </SecondaryButton>
        <div style={{ margin: 5 }}></div>

        <SecondaryButton key="submits" disabled={false} type="submit">
          {t('checkout_next_step')}
        </SecondaryButton>
      </div>
    </Form>
  );
}
// pattern: { value: /(?=.*[A-Z])(?=.*[0-9]).*/, message: t('field_validation_password') }
