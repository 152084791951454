import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Components/Container/Container';
import { Title } from '../Components/Title/Title';
import { Paragraph } from '../Components/Paragraph/Paragraph';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { Form, Radio, SubmitButton } from '../Components/Form/Form';
import { useForm } from 'react-hook-form';
import { sprintf } from 'sprintf-js';
import { CustomerPortalContext, AuthContext, HashContext } from '../Contexts';
import { Loader } from '../Components/Loader/Loader';
import { PrimaryButton } from '../Components/Button/Button';
import { CartOverview } from '../Components/CartOverview/CartOverview';
import { PORTAL_VIEWS, useSwitchView } from '../views';

export function PageRenewNow() {
  const [ready, setReady] = React.useState<boolean>(false);

  return (
    <>
      {!ready && (
        <RenewNowForm
          onSubmit={(values) => {
            setReady(true);
          }}
        />
      )}
      {ready && <Pay />}
    </>
  );
}

type RenewNowFormProps = {
  onSubmit: (values: any) => void;
};

function RenewNowForm({ onSubmit }: RenewNowFormProps) {
  const shopping_cart_data = useContext(CustomerPortalContext);
  const { customer } = useContext(AuthContext);
  const { t } = useTranslation();
  const { handleSubmit, register } = useForm();
  const { switchView } = useSwitchView();

  if (!customer || typeof customer.subscriptions.base.product !== 'object') {
    return <span>{t('renew_now_no_customer')}</span>;
  }

  const baseSubscription = [
    {
      ...customer.subscriptions.base,
      type: 'BASE',
    },
  ];
  const extraSubscriptions = customer.subscriptions.extras.map((x) => ({
    ...x,
    type: 'EXTRA',
  }));

  // //fill cart
  // let product = customer.subscriptions.base.product as Product;
  // addProduct(product, customer.subscriptions.base.plan);

  // customer.subscriptions.extras.forEach((sub) => {
  //   let product = sub.product as Product;
  //   addProduct(product, sub.plan);
  // });

  return (
    <Container>
      <VerticalSpacer>
        <VerticalSpacer size="xs">
          <Title level={1} title={t('renew_now_title')} />
          <Paragraph text={sprintf(t('renew_now_info_text'))} />

          <div style={{ marginTop: 50 }}></div>
          {/* 
          {baseSubscription && (
            <SubscriptionGrid
              subscriptions={baseSubscription}
              showActionButtons_
              showPrice
            />
          )}
          {extraSubscriptions && (
            <SubscriptionGrid
              subscriptions={extraSubscriptions}
              showActionButtons_
              showPrice
            />
          )} */}

          <div style={{ marginTop: 50 }}>
            <CartOverview></CartOverview>
          </div>

          <div style={{ marginTop: 50 }}>
            <div style={{ marginBottom: 25 }}>
              <Title level={3} title={t('dashboard_extra_packages')} />
              <Paragraph text={t('dashboard_addextra_text_renewal')} />
            </div>
            <div style={{ width: 300, marginTop: 25 }}>
              <PrimaryButton
                onClick={() => {
                  switchView(PORTAL_VIEWS.addpackages);
                }}
              >
                <div style={{ justifyContent: 'center' }}>
                  <div>{t('account_navigation_buy_packages')}</div>
                </div>
              </PrimaryButton>
            </div>
          </div>

          <div style={{ marginTop: 50 }}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Title level={2} title={t('renew_now_select_payment_provider')} />
              {shopping_cart_data?.payment_gateways?.map((x) => (
                <Radio label={x.name} {...register('payment_method', { required: true })} />
              ))}
              <div style={{ marginTop: 50, marginBottom: 100, width: 300 }}>
                <SubmitButton>{t('renew_now_submit')}</SubmitButton>
              </div>
            </Form>
          </div>
        </VerticalSpacer>
      </VerticalSpacer>
    </Container>
  );
}

function Pay() {
  const { client, deployment, service, portalId } = React.useContext(HashContext);
  const { t } = useTranslation();

  // const { isLoading } = useStartTransaction(
  //   client,
  //   deployment,
  //   service,
  //   portalId,
  //   v4(),
  //   {
  //     onSuccess: (data) => {
  //       window.location.replace(data.redirectUrl);
  //     },
  //   }
  // );

  return (
    <Container>
      <VerticalSpacer size="xs">
        <Title level={1} title={t('renew_now_redirecting')} />
        <Paragraph text={t('renew_now_redirecting_info')} />
        <Loader />
      </VerticalSpacer>
    </Container>
  );
}
