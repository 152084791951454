import { useContext, useState } from 'react';
import { ProductCard } from '../ProductCardExtra/ProductCardExtra';
import styles from './PortalCartFlowExtra.module.scss';
import { OrderContext, DesignContext, AuthContext } from '../../Contexts';
import { Steps } from 'antd';
import { PrimaryButton, SecondaryButton } from '../Button/Button';
import { FinishNoPay, Pay, PaymentMethod } from '../../Pages';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import { Container } from '../Container/Container';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { PortalCartOverview } from '../PortalCartOverviewExtra/PortalCartOverviewExtra';
import { getCurrencyCreditPriceNoTax } from '../../Utilities/ProductUtilities';
import { Product, OrderTypes } from '../../interfaces';

type ProductGridProps = {
  items: Product[];
  unavailableProducts?: any;
  showStartOptions?: boolean;
  allowStartImmediate?: boolean;
};

enum BasePlanWizard {
  changePlan = 0,
  planDetails = 1,
  paymentMethod = 2,
  payNow = 3,
}

export function PortalCartFlowExtra({ items, showStartOptions = false, allowStartImmediate = false }: ProductGridProps) {
  const { order, setOrderType, subscriptionRules } = useContext(OrderContext);
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const { customer } = useContext(AuthContext);
  const { globals } = useContext(DesignContext);

  useEffectOnce(() => {
    setOrderType(OrderTypes.ExtraSubscriptionAdd);
  });

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const handleNext = () => {
    if (currentStep <= 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const canAdvance = () => {
    if (currentStep === BasePlanWizard.changePlan && order.products.find((x) => x.product.type === 'EXTRA') === undefined) {
      return false;
    }

    return true;
  };

  const isPriceHigherThenZero = () => {
    const product = order.products.find((x) => x.product.type === 'EXTRA');
    if (product !== undefined) {
      const price = product.plan.prices.find((p) => p.currency === order.billingCurrency).price;
      return price > 0;
    } else {
      return false;
    }
  };

  const PaymentMethodSelection = () => {
    return (
      <Container>
        <VerticalSpacer size="xxl">
          <PaymentMethod onDone={() => {}} onPrevious={() => {}} showActionBar={false} />
        </VerticalSpacer>
      </Container>
    );
  };

  const steps = [{ title: t('cart_extra') }, { title: t('cart_order') }, { title: t('cart_payment') }];

  return (
    <>
      <Steps style={{ fontFamily: globals['font-family'],marginTop:20  }} current={currentStep} items={steps} />

      <div>
        {currentStep === BasePlanWizard.changePlan && (
          <div className={styles.component}>
            {items.map((product, index) => {
              if (product.plans !== undefined) {
                if (customer.subscriptions.extras.find((e) => e.product._id === product._id) === undefined) {
                  return (
                    <ProductCard
                      key={`product-grid-${index}`}
                      {...{
                        product,
                        showStartOptions,
                        allowStartImmediate,
                        handleNext,
                      }}
                    />
                  );
                } else {
                  return null;
                }
              } else {
                return null;
              }
            })}
          </div>
        )}
        {currentStep === BasePlanWizard.planDetails && <PortalCartOverview />}
        {currentStep === BasePlanWizard.paymentMethod && isPriceHigherThenZero() && subscriptionRules.allow_partial_upgrades && <PaymentMethodSelection />}
        {currentStep === BasePlanWizard.paymentMethod && (!isPriceHigherThenZero() || !subscriptionRules.allow_partial_upgrades) && <FinishNoPay />}
        {currentStep === BasePlanWizard.payNow && <Pay />}
      </div>
      <div className={styles.modalFooter}>
        {currentStep !== BasePlanWizard.payNow && (
          <SecondaryButton
            key="submit"
            disabled={currentStep === BasePlanWizard.changePlan}
            onClick={() => {
              handlePrevious();
            }}
          >
            {t('checkout_previous_step')}
          </SecondaryButton>
        )}
        {currentStep === BasePlanWizard.paymentMethod ? (
          <PrimaryButton
            onClick={() => {
              handleNext();
            }}
            disabled={order.payment === null}
          >
            {t('checkout_tabs_next_button')}
          </PrimaryButton>
        ) : (
          <SecondaryButton
            disabled={!canAdvance()}
            onClick={() => {
              handleNext();
            }}
          >
            {t('checkout_next_step')}
          </SecondaryButton>
        )}
      </div>
    </>
  );
}
