import React from 'react';
import { Image } from 'antd';

import styles from './ImageGallery.module.scss';

type ImageGalleryProps = {
  images: { name: string; url: string }[];
};

export function ImageGallery({ images }: ImageGalleryProps) {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

  const selectedImage = images.at(selectedIndex);

  return (
    <div className={styles.component}>
      <div className={styles.highlightedImage}>
        {selectedImage && (
          <Image
            src={selectedImage.url}
            alt={selectedImage.name}
            style={{ borderRadius: 5 }}
            //layoutClass={styles.image}
          />
        )}
      </div>
      <div className={styles.smallImages} style={{ marginTop: 10 }}>
        {images.map((image, index) => (
          <button
            key={`ImageGallery-button-${index}`}
            className={styles.button}
            onClick={() => setSelectedIndex(index)}
            style={{
              border: '1px solid #dedede',
              padding: 5,
              borderRadius: 3,
              margin: 5,
            }}
          >
            <Image
              preview={false}
              src={image.url}
              alt={image.name}
              style={{ borderRadius: 3 }}
              // layoutClass={styles.buttonImage}
            />
          </button>
        ))}
      </div>
    </div>
  );
}
