import { Product } from './product';
import { OrderStatus } from '@mwaretv/database/build/backend/enums/billing/orderStatus';
import { PaymentStatus } from '@mwaretv/database/build/backend/enums/billing/orderPaymentStatus';
import { PaymentMethod } from './paymentMethod';
import { Customer, CustomerLead } from './customer';
import { Plan } from './plan';

export enum OrderTypes {
  NewCustomer = 'New Customer',
  ExpirationDateChange = 'Expiration Date Change',
  BaseSubscriptionChange = 'Base Subscription Change',
  ExtraSubscriptionAdd = 'Extra Subscription Add',
  ExtraSubscriptionRemove = 'Extra Subscription Remove',
  SubscriptionRenewal = 'Subscription Renewal',
  ConvertFromTrail = 'Convert From Trial',
}

export interface Order {
  _id?: string;
  session?: string;
  status?: OrderStatus;
  type?: OrderTypes;
  products: {
    product: Product;
    plan: Plan;
  }[];
  cart?: string;
  payment?: {
    gateway: PaymentMethod;
    status: PaymentStatus;
  };
  payment_provider_payload?: Object;
  payment_provider_ref_id?: string;
  customer?: Customer;
  customerLead?: CustomerLead;
  billingCurrency?: string;
  billingCountry?: string;
  billingTaxrate?: number;
  discount?: number;
}
