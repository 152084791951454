//import 'antd/dist/antd.css';
import { Col, Collapse, Row, CollapseProps, Image } from 'antd';
import { PaymentForm } from '../Components/CreditCard/CreditCardForm';
import { ApplePayButton } from '../Components/ApplePay/ApplePay';

export const CyberSourcePayment = () => {
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Image width={75} preview={false} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/payment-methods/visa.png'}></Image>
          <Image width={75} preview={false} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/payment-methods/discover.png'}></Image>
          <Image width={75} preview={false} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/payment-methods/mastercard.png'}></Image>
          <Image width={75} preview={false} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/payment-methods/americanexpress.png'}></Image>
          <Image width={75} preview={false} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/payment-methods/jcb.png'}></Image>
        </div>
      ),
      children: <PaymentForm />,
    },
    {
      key: '2',
      label: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Image width={75} preview={false} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/payment-methods/applepay.png'}></Image>
        </div>
      ),
      children: <ApplePayButton />,
    },
    {
      key: '3',
      label: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Image width={75} preview={false} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/payment-methods/googlepay.png'}></Image>
        </div>
      ),
      children: <ApplePayButton />,
    },
    {
      key: '4',
      label: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Image width={75} preview={false} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/payment-methods/samsungpay.png'}></Image>
        </div>
      ),
      children: <ApplePayButton />,
    },
    {
      key: '5',
      label: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Image width={75} preview={false} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/payment-methods/visacheckout.png'}></Image>
        </div>
      ),
      children: <ApplePayButton />,
    },
  ];

  return (
    <Row justify="center">
      <Col>
        <Collapse items={items} defaultActiveKey={['1']} />
      </Col>
    </Row>
  );
};
