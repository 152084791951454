import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const getChannels = (client : string, deployment: string, service : string, id : string) => axios.get<any>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/product/channels/${id}`, {
  params: { instance: client, cms: deployment, crm: service }
})

export const useChannels = (client : string, deployment: string, service : string, id : string) => useQuery(['packages', client, deployment, service, id], async () => {
  return getChannels(client, deployment, service, id)
    .then(r => Promise.resolve(r.data))
    .catch(e => Promise.reject(e))
})
