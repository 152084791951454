import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../Components/Container/Container';
import { Title } from '../Components/Title/Title';
import { Paragraph } from '../Components/Paragraph/Paragraph';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';

export function PageAppSettings() {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <VerticalSpacer>
          <Title level={1} title={t('app_settings_title')} />
          <Paragraph text={t('app_settings_info_text')} />
        </VerticalSpacer>
      </Container>
    </>
  );
}
