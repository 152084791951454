import { isMobile } from 'react-device-detect';
import QRCode from 'react-qr-code';

export const AppStore = ({ text, url, logo }: { text?: string; url?: string; logo: string }) => {
    return (
      <>
        {url !== undefined && url !== '' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: '#fff',
              borderRadius: 5,
              width: '100%',
              border: '2px solid #dedede',
            }}
          >
            <div style={{ display: 'flex', flexDirection: `${isMobile ? 'column' : 'row'}`, alignItems: 'center', width: '100%' }}>
              <a target="_parent" href={url}>
                <img width={140} src={logo} alt="" />
              </a>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 16,
                  padding: '1rem',
                  flex: 1,
                }}
              >
                {text}
              </div>
              {!isMobile && (
                <div style={{ marginRight: 20 }}>
                  <QRCode value={url} size={96} />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  };