import React from 'react';
import { Container } from '../Components/Container/Container';

// import styles from './AccountLayout.module.scss';

export const AccountLayout = ({ children }: {children: React.ReactNode[] }) => {
  return (
    <Container>
      <div style={{ marginTop: 50 }}>
        {children}
      </div>
    </Container>
  );
}
