import axios, { AxiosResponse } from 'axios';
import { Customer } from '../interfaces/customer';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';

type Params = { instance: string; cms: string; crm: string };

const create = (client: string, deployment: string, service: string, data: Customer) =>
  axios.post<Customer>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/portals/customers`, data, {
    params: { instance: client, cms: deployment, crm: service },
  });

const check = (
  client: string,
  deployment: string,
  service: string,
  data: {
    userName: string;
    email: string;
  }
) =>
  axios.post<Customer>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/customers/customers/check`, {
    params: { instance: client, cms: deployment, crm: service, data: { userName: data.userName, email: data.email } },
  });
export const checkCustomer = check;

const patch = (params: Params, id: string, data: Partial<Customer>) =>
  axios.patch<Customer>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/portals/customers/${id}`, data, {
    params,
    withCredentials: true,
  });

export const updateCustomer = patch;

const login = (params: Params, data: { username: string; password: string }) => {
  return axios.post<Customer>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/portals/auth/login`, data, {
    withCredentials: true,
    params,
  });
};

const logout = (params: Params) => {
  return axios.post<Customer>(
    `${process.env.REACT_APP_TVMS_API_BASE}/api/v1/portals/auth/logout`,
    {},
    {
      withCredentials: true,
      params,
    }
  );
};

const me = (params: Params) => {
  return axios.get<Customer>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/portals/customers/me`, {
    withCredentials: true,
    params,
  });
};

export const useMe = (params: Params, options?: (Omit<UseQueryOptions<AxiosResponse<Customer>, unknown, unknown, (string | Params)[]>, 'initialData' | 'queryFn' | 'queryKey'> & { initialData?: (() => undefined) | undefined }) | undefined) =>
  useQuery(
    ['portals', 'customers', params],
    async () => {
      return me(params)
        .then((r) => Promise.resolve(r))
        .catch((e) => Promise.reject(e));
    },
    options
  );
///removed ,any to fix build error not sure why it was there
export const useLoginMutation = (params: Params, options?: Omit<UseMutationOptions<AxiosResponse<Customer>, unknown, { username: string; password: string }, unknown>, 'mutationFn'>) =>
  useMutation(async (values: { username: string; password: string }) => {
    return login(params, values)
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.resolve(e));
  }, options);

export const useLogoutMutation = (params: Params, options?: Omit<UseMutationOptions<AxiosResponse<Customer>, unknown, unknown, unknown>, 'mutationFn'>) =>
  useMutation(async () => {
    return logout(params)
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.resolve(e));
  }, options);

export const useCustomerCreateMutation = (client: string, deployment: string, service: string, options?: Omit<UseMutationOptions<AxiosResponse<Customer>, unknown, Customer, unknown>, 'mutationFn'>) =>
  useMutation(async (values: Customer) => {
    return create(client, deployment, service, values)
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  }, options);

export const useCustomerPatchMutation = (params: Params, options?: Omit<UseMutationOptions<AxiosResponse<Customer>, unknown, { id: string; values: Partial<Customer> }, unknown>, 'mutationFn'>) =>
  useMutation(async ({ id, values }: { id: string; values: Partial<Customer> }) => {
    return patch(params, id, values)
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  }, options);

export const useCheckMutation = (
  client: string,
  deployment: string,
  service: string,
  data: {
    userName: string;
    email: string;
  },
  options?: Omit<UseMutationOptions<AxiosResponse<Customer>, unknown, unknown>, 'mutationFn'>
) =>
  useMutation(async () => {
    return check(client, deployment, service, data)
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.resolve(e));
  }, options);
