import { Container } from '../Components/Container/Container'
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer'
import { LoginPage } from './Login'

export function PortalHome() {
  return (
    <Container>
      <VerticalSpacer size='sm'>
        <LoginPage />
      </VerticalSpacer>
    </Container>
  )
}
