import { useHistory, useLocation } from 'react-router-dom';

export enum PORTAL_VIEWS {
  cart = 'cart',
  checkout = 'checkout',
  orderComplete = 'order-complete',
  orderCancelled = 'order-cancelled',
  productDetails = 'product',
  cybersourcePayment = 'cybersource-payment',
  mercadopagoPayment = 'mercadopago-payment',
  authorizenetPayment = 'authorizenet-payment',
  payfastPayment = 'payfast-payment',
  momoApiPayment = 'momoapi-payment',
  momoApiPaymentVerify = 'momoapi-paymentverify',
  shop = 'shop',
  account = 'account',
  subscriptions = 'subscriptions',
  appsettings = 'app-settings',
  dashboard = 'dashboard',
  renew = 'renew',
  addpackages = 'addpackages',
  cancelsubscription = 'cancel-subscription',
  passwordchange = 'password-change',
  forgotpassword = 'forgot-password',
  passwordreset = 'password-reset',
  home = 'home',
  recoverurl = 'r',
  transactions = 'transactions'
}

export const useSwitchView = () => {
  const history = useHistory();
  const { pathname, hash, search } = useLocation();

  const switchViewUrl = (view: PORTAL_VIEWS, query?: Record<string, string>) => {
    const params = new URLSearchParams(search);
    params.set('o', view);

    if (query !== undefined) {
      for (const [key, value] of Object.entries(query)) {
        if (value != null) {
          params.set(key, value);
        }
        else {
          params.delete(key);
        }
      }
    }

    return {
      pathname,
      search: params.toString(),
      hash,
    };
  };

  return {
    switchView: (view: PORTAL_VIEWS, query?: Record<string, string>) => history.push(switchViewUrl(view, query)),
    switchViewUrl,
  };
};
