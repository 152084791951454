import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DesignContext } from '../../Contexts';
import styles from './SiteHeaderPortal.module.scss';
import moment from 'moment';
import { ILanguage } from './SiteHeaderPortalContainer';

type LanguageButtonProps = {
  children?: React.ReactNode;
  language: ILanguage;
};

const LanguageButton = ({ children, language }: LanguageButtonProps) => {
  const { i18n } = useTranslation();
  const { globals, navbar } = useContext(DesignContext);

  const changLanguage = (language) => {
    i18n.changeLanguage(language.code);
    moment().locale(language.code);
  };
  return (
    <button
      onClick={() => changLanguage(language)}
      style={{
        fontFamily: globals['font-family'],
        color: navbar['text-color'],
      }}
      className={styles.languageButton}
    >
      {children}
    </button>
  );
};

export default LanguageButton;
