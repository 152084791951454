"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentStatus = void 0;
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["NONE"] = "none";
    PaymentStatus["AWAITING_PAYMENT"] = "awaiting_payment";
    PaymentStatus["PAYMENT_DONE"] = "done";
    PaymentStatus["PAYMENT_FAILED"] = "failed";
})(PaymentStatus = exports.PaymentStatus || (exports.PaymentStatus = {}));
