import React from 'react';
import { ShoppingCart } from '../interfaces';
import { useCart } from '../Services/Cart';
import { HashContext } from './HashContext';

type ShoppingCartContextType = ShoppingCart;

export const ShoppingCartContext = React.createContext<ShoppingCartContextType>({} as any);

type ShoppingCartContextProviderProps = {
  children: React.ReactNode;
};

export function ShoppingCartContextProvider({ children }: ShoppingCartContextProviderProps) {
  const { client, deployment, service, portalId } = React.useContext(HashContext);

  const { isLoading, isError, data } = useCart(portalId, {
    instance: client,
    cms: deployment,
    crm: service,
  });

  return <>{!isLoading && !isError && <ShoppingCartContext.Provider value={data.data}>{children}</ShoppingCartContext.Provider>}</>;
}
