import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { Product } from '../interfaces/product'

const getProduct = (client : string, deployment: string, service : string, id : string) =>
  axios.get<Product>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/products/${id}`, {
    params: { instance: client, cms: deployment, crm: service }
  })

export const useProduct = (client : string, deployment: string, service : string, id : string) => useQuery(['products', client, deployment, service, id], async () => {
  return getProduct(client,  deployment, service, id)
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
})
