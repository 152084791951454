import { useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Product } from '../../interfaces/product';
import { Subscription } from '../../interfaces/customerSubscriptions';
import { PrimaryButton, PrimaryButtonMaxWidthVariableHeight, SecondaryButton, TertiereButton } from '../Button/Button';
import styles from './BaseSubscriptionCard.module.scss';
import { PlanPricePortal } from '../PlanPrice/PlanPricePortal';
import { Modal } from 'antd';
import { PortalCartFlowBase } from '../PortalCartFlowBase/PortalCartFlowBase';
import { PortalCartFlowRenew } from '../PortalCartFlowRenew/PortalCartFlowRenew';
import { OrderContext, AuthContext, HashContext } from '../../Contexts';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import { Title } from '../Title/Title';
import { Paragraph } from '../Paragraph/Paragraph';
import { useCustomerPatchMutation } from '../../Services/Customer';
import React from 'react';
import { CustomerStatus } from '@mwaretv/database/build/backend/enums/customers/customerStatus';
import { CustomerSubscriptionStatus } from '@mwaretv/database/build/backend/enums/customers/customerSubscriptionStatus';
import { useCancelSubscriptionMutation } from '../../Services/Subscriptions';

type BaseSubscriptionCardProps = {
  subscription: Subscription;
  showActionButtons?: boolean;
  showPrice?: boolean;
  allowChangingBase?: boolean;
  allowRenewBase?: boolean;
  type: string;
};

export function BaseSubscriptionCard({ subscription, type, showActionButtons, showPrice, allowChangingBase = true, allowRenewBase = true }: BaseSubscriptionCardProps) {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [modalOpenBase, setModalOpenBase] = useState(false);
  const [modalOpenRenew, setModalOpenRenew] = useState(false);
  const [modalOpenCancelTrial, setModalOpenCancelTrial] = useState(false);
  const [modalProductType, setModalProductType] = useState<'BASE' | 'EXTRA'>('BASE');
  const { baseProducts } = useContext(OrderContext);

  const openBaseProductChangeModal = () => {
    setModalOpenBase(true);
    setModalProductType('BASE');
  };
  const convertRenewModal = () => {
    setModalOpenRenew(true);
  };
  const cancelTrial = () => {
    setModalOpenCancelTrial(true);
  };
  const { start_date: startDate, activation_date: activationDate, expiration_date: expirationDate, end_date: endDate } = subscription;
  const product = subscription.product as Product;
  const [image] = product.external.images;
  const plan = product.plans.plans.find((p) => p._id === subscription.plan._id);
  const { customer } = useContext(AuthContext);
  const now = dayjs();
  const expiration = dayjs.unix(customer.subscriptions.base.expiration_date ?? now.unix());
  const isActuallyExpired = customer.subscriptions.base?.expiration_date != null && now.isAfter(expiration);
  const trueSubscriptionStatus = isActuallyExpired ? 'EXPIRED' : customer.subscriptionStatus.toLocaleUpperCase();
  const { client, deployment, service } = React.useContext(HashContext);

  const { isLoading, mutate } = useCancelSubscriptionMutation({ instance: client, cms: deployment, crm: service });

  return (
    <>
      <DisplayChangeBaseProductsModal
        open={modalOpenBase}
        onOk={() => {
          setModalOpenBase(false);
        }}
        onCancel={() => setModalOpenBase(false)}
        productType={modalProductType}
        products={baseProducts}
      />
      <DisplayRenewModal
        open={modalOpenRenew}
        onOk={() => {
          setModalOpenRenew(false);
        }}
        onCancel={() => setModalOpenRenew(false)}
        productType={modalProductType}
        products={baseProducts}
      />
      <DisplayCancelTrialModal
        open={modalOpenCancelTrial}
        onOk={async () => {
          await mutate({ id: customer._id });
          logout();
        }}
        onCancel={() => setModalOpenCancelTrial(false)}
        productType={modalProductType}
        products={baseProducts}
      />
      <div
        style={{
          border: '2px solid #dedede',
          padding: 20,
          borderRadius: 5,
          backgroundColor: '#fff',
          flexDirection: `${isMobile ? 'column' : 'row'}`,
        }}
        className={classNames(styles.component)}
      >
        <div className={styles.imageContainer}>{image && <img className={styles.image} src={image.url} alt={product.name} />}</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 2,
          }}
        >
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <span style={{ fontSize: 22 }}>
              <b>{product.name}</b>
            </span>

            {customer.productStatus.toUpperCase() == 'TRIAL' && (
              <div style={{ marginLeft: 10, color: '#fff', paddingLeft: 10, paddingRight: 10, borderRadius: 100, backgroundColor: '#28b463', fontSize: 14, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                {product.settings.trial_days} {t('plan_price_day_plural')} {t('trial')}
              </div>
            )}
          </div>
          {!(activationDate || expirationDate) && (
            <div style={{ fontSize: 16 }}>
              {t('subscription_card_subscription_starts_on', {
                start_date: moment.unix(startDate).format('lll'),
              })}
            </div>
          )}
          <div style={{ display: 'flex' }}>
            {(activationDate || expirationDate) && (
              <div>
                {t('subscriptions_expires_at')}: {`${expirationDate ? moment.unix(expirationDate).format('lll') : ''}`}
              </div>
            )}
          </div>

          <div
            className={styles.actionContainer}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'row',
            }}
          >
            {t('transactions_plan_name')}: <PlanPricePortal plan={plan} />
          </div>
          {customer.subscriptions.pending_base != undefined && expirationDate > moment().unix() && (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div style={{ textAlign: 'center', marginTop: 10, color: '#fff', paddingLeft: 10, paddingRight: 10, borderRadius: 5, backgroundColor: 'coral', fontSize: 14, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                {t('subscriptions_end_on') + ': '} {moment.unix(customer.subscriptions.base.expiration_date).format('lll')}
              </div>
            </div>
          )}
          {customer.subscriptions.pending_base != undefined && expirationDate < moment().unix() && (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div style={{ textAlign: 'center', marginTop: 10, color: '#fff', paddingLeft: 10, paddingRight: 10, borderRadius: 5, backgroundColor: 'crimson', fontSize: 14, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                {t('subscriptions_expired_at') + ': '} {moment.unix(customer.subscriptions.base.expiration_date).format('lll')}
              </div>
            </div>
          )}
        </div>
        {showActionButtons === true && customer.subscriptions.pending_base === undefined && trueSubscriptionStatus !== 'PENDING' && (
          <div>
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'flex-end',
              }}
              className={styles.actionContainer}
            >
              {type === 'BASE' && allowChangingBase && trueSubscriptionStatus !== 'EXPIRED' && <PrimaryButton onClick={openBaseProductChangeModal}>{t('subscription_card_change')}</PrimaryButton>}
              {type === 'BASE' && allowRenewBase && trueSubscriptionStatus !== 'EXPIRED' && customer.productStatus.toUpperCase() === 'TRIAL' && <TertiereButton onClick={cancelTrial}>{t('cancel_trial')}</TertiereButton>}
              {type === 'BASE' && allowRenewBase && trueSubscriptionStatus !== 'EXPIRED' && !customer.payment_auto_recurring && <PrimaryButton onClick={convertRenewModal}>{t('subscription_card_renew_to_paid')}</PrimaryButton>}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

interface DisplayProductModalProps {
  open: boolean;
  onOk: Function;
  onCancel: Function;
  products: Product[];
  productType: 'BASE' | 'EXTRA';
}

const DisplayRenewModal = ({ open, onOk, onCancel, products }: DisplayProductModalProps) => {
  return (
    <Modal {...{ open }} footer={null} width={1200} onOk={() => onOk()} onCancel={() => onCancel()}>
      {products && <PortalCartFlowRenew showStartOptions items={products} />}
    </Modal>
  );
};

const DisplayChangeBaseProductsModal = ({ open, onOk, onCancel, products }: DisplayProductModalProps) => {
  return (
    <Modal {...{ open }} footer={null} width={1200} onOk={() => onOk()} onCancel={() => onCancel()}>
      {products && <PortalCartFlowBase showStartOptions items={products} />}
    </Modal>
  );
};

const DisplayCancelTrialModal = ({ open, onOk, onCancel, products }: DisplayProductModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal {...{ open }} footer={null} width={500} onOk={() => onOk()} onCancel={() => onCancel()}>
      <div style={{ marginTop: 20 }}>
        <div>
          <Title level={2} title={t('cancel_trial')} />
          <Paragraph text={t('cancel_trial_information')} />
        </div>

        <div className="row text-right" style={{ marginTop: 20, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
          <div className="col" style={{ marginRight: 10 }}>
            <PrimaryButton
              onClick={() => {
                onCancel();
              }}
            >
              {t('close')}
            </PrimaryButton>
          </div>
          <div className="col">
            <SecondaryButton
              key="submit"
              onClick={() => {
                onOk();
              }}
            >
              {t('submit')}
            </SecondaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
