import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Product } from '../interfaces';

export const getCartProducts = (client: string, deployment: string, service: string, id: string, filter: Object = {}) =>
  axios.get<Product[]>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/shopping-carts/${id}/products`, {
    params: { instance: client, cms: deployment, crm: service, filter },
  });

export const getCartProduct = (client: string, deployment: string, service: string, id: string, productId: string) =>
  axios.get<Product>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/shopping-carts/${id}/products/${productId}}`, {
    params: { instance: client, cms: deployment, crm: service },
  });

export const useCartProducts = (client: string, deployment: string, service: string, id: string, filter: Object = {}) =>
  useQuery(['cart', 'products', client, deployment, service, id, filter], async () => {
    return getCartProducts(client, deployment, service, id, filter)
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  });

export const useCartProduct = (client: string, deployment: string, service: string, id: string, productId: string) =>
  useQuery(['products', client, deployment, service, id], async () => {
    return getCartProduct(client, deployment, service, id, productId)
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  });
