import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { DesignContext } from '../../Contexts';
import { InputHTMLAttributes, LegacyRef, ReactNode, SelectHTMLAttributes, forwardRef, useContext, useState } from 'react';
import { PrimaryButton } from '../Button/Button';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import styles from './Form.module.scss';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
//import 'react-phone-input-2/lib/style.css';
import { Controller } from 'react-hook-form';

export const ErrorMessage = (props: { error?: string }) => {
  const {
    globals,
    forms: { inputs },
  } = useContext(DesignContext);

  return !props.error ? undefined : (
    <div
      className={styles.input}
      style={{
        fontFamily: globals['font-family'],
        fontSize: 16,
        color: inputs['font-color'],
        borderWidth: 0,
      }}
    >
      {props.error}
    </div>
  );
};

type FormProps = {
  children?: ReactNode;
  onSubmit: () => void;
};

export function Form({ children, onSubmit }: FormProps) {
  return (
    <form
      className={styles.form}
      onSubmit={(e) => {
        e.preventDefault();

        onSubmit();
      }}
    >
      <VerticalSpacer>{children}</VerticalSpacer>
    </form>
  );
}

export const CustomPhoneInput = ({ required, control, name, defaultValue, label }) => {
  const {
    globals,
    forms: { inputs },
  } = useContext(DesignContext);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{ pattern: /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/ }}
      render={({ field: { ref, ...field } }) => (
        <Label>
    
          <PhoneInput
            {...field}
            inputProps={{
              ref,
              required,
              autoFocus: true,
            }}
            country={defaultValue.toLowerCase()}
            inputClass="ant-input form-control"
            inputStyle={{ width: '100%', height: '40px', fontSize: '16px', border: '1px solid #e9edf4', fontFamily: globals['font-family'] }}
            buttonStyle={{ border: 'none', backgroundColor: 'transparent' }}
            containerStyle={{
              fontFamily: globals['font-family'],
              fontSize: 16,
              color: inputs['font-color'],
              borderWidth: inputs['border-width'],
              borderRadius: inputs['border-radius'],
              borderColor: inputs['border-color'],
              backgroundColor: inputs['background-color'],
            }}
          />
        </Label>
      )}
    />
  );
};

type InputProps = InputHTMLAttributes<HTMLInputElement> & { label: string };

export const Input = forwardRef(({ label, ...props }: InputProps, ref: LegacyRef<HTMLInputElement>) => {
  const {
    globals,
    forms: { inputs },
  } = useContext(DesignContext);

  return (
    <Label>
      <span>{label}</span>
      <input
        ref={ref}
        className={styles.input}
        style={{
          fontFamily: globals['font-family'],
          fontSize: 16,
          color: inputs['font-color'],
          borderWidth: inputs['border-width'],
          borderRadius: inputs['border-radius'],
          borderColor: inputs['border-color'],
          backgroundColor: props.disabled ? 'lightgray' : inputs['background-color'],
        }}
        {...props}
      />
    </Label>
  );
});
export const PasswordInput = forwardRef(({ label, ...props }: InputProps, ref: LegacyRef<HTMLInputElement>) => {
  const {
    globals,
    forms: { inputs },
  } = useContext(DesignContext);

  return (
    <Label>
      <span>{label}</span>
      <input
        {...props}
        ref={ref}
        className={styles.input}
        style={{
          fontFamily: globals['font-family'],
          fontSize: 16,
          color: inputs['font-color'],
          borderWidth: inputs['border-width'],
          borderRadius: inputs['border-radius'],
          borderColor: inputs['border-color'],
          backgroundColor: props.disabled ? 'lightgray' : inputs['background-color'],
        }}
      />
    </Label>
  );
});

type SubmitButtonProps = {
  children?: ReactNode;
  disabled?: boolean;
};

export function SubmitButton({ children, disabled }: SubmitButtonProps) {
  return (
    <PrimaryButton disabled={disabled} type="submit">
      {children}
    </PrimaryButton>
  );
}

type LabelProps = {
  children?: ReactNode;
  horizontal?: boolean;
};

export function Label({ children, horizontal = false }: LabelProps) {
  const {
    globals,
    forms: { labels },
  } = useContext(DesignContext);

  return (
    <label
      style={{
        fontFamily: globals['font-family'],
        fontSize: 16,
        color: labels['font-color'],
      }}
      className={classNames(styles.label, {
        [styles.labelHorizontal]: horizontal,
      })}
    >
      {children}
    </label>
  );
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & { label: string };

export const Select = forwardRef(({ label, children, ...props }: SelectProps, ref: LegacyRef<HTMLSelectElement>) => {
  const {
    globals,
    forms: { inputs },
  } = useContext(DesignContext);

  return (
    <Label>
      <span>{label}</span>
      <select
        {...props}
        ref={ref}
        className={styles.select}
        style={{
          fontFamily: globals['font-family'],
          fontSize: 16,
          color: inputs['font-color'],
          borderWidth: inputs['border-width'],
          borderRadius: inputs['border-radius'],
          borderColor: inputs['border-color'],
          backgroundColor: inputs['background-color'],
        }}
      >
        {children}
      </select>
    </Label>
  );
});

type HorizontalRowProps = {
  children?: ReactNode;
};

export function HorizontalRow({ children }: HorizontalRowProps) {
  return (
    <div
      className={styles.horizontalRow}
      style={{
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      }}
    >
      {children}
    </div>
  );
}

type RadioProps = InputHTMLAttributes<HTMLInputElement> & { label: string };

export const Radio = forwardRef(({ label, children, ...props }: RadioProps, ref: LegacyRef<HTMLInputElement>) => {
  return (
    <Label horizontal>
      <input type="radio" {...props} ref={ref} />
      <span>{label}</span>
    </Label>
  );
});

type PasswordFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

export const PasswordField = forwardRef(({ label, disabled, ...props }: PasswordFieldProps, ref: LegacyRef<HTMLInputElement>) => {
  const {
    globals,
    forms: { inputs },
  } = useContext(DesignContext);

  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const checkPasswords = () => {
    if (props.name === 'password_check') {
      const password = document.getElementById('password');
      const confirmPassword = document.getElementById('password_check');
      // @ts-expect-error
      if (password.value !== confirmPassword.value) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    }
  };

  return (
    <Label>
      {label && <span>{label}</span>}
      <div
        style={{
          display: 'flex',
          fontFamily: globals['font-family'],
          fontSize: 16,
          color: inputs['font-color'],
          borderWidth: inputs['border-width'],
          borderRadius: inputs['border-radius'],
          borderColor: showError ? 'red' : inputs['border-color'],
          backgroundColor: disabled ? 'gray' : inputs['background-color'],
        }}
      >
        <input
          {...props}
          disabled={disabled}
          ref={ref}
          type={showPassword ? 'text' : 'password'}
          className={styles.input}
          id={props.name}
          onChange={() => checkPasswords()}
          style={{
            flexGrow: 1,
            fontFamily: globals['font-family'],
            fontSize: 16,
            color: inputs['font-color'],
            borderWidth: inputs['border-width'],
            borderColor: showError ? 'red' : inputs['border-color'],
            borderTopLeftRadius: inputs['border-radius'],
            borderBottomLeftRadius: inputs['border-radius'],
            backgroundColor: disabled ? 'lightgray' : inputs['background-color'],
          }}
        />
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setShowPassword(!showPassword);
          }}
          style={{
            borderTopRightRadius: inputs['border-radius'],
            borderBottomRightRadius: inputs['border-radius'],
            borderColor: inputs['border-color'],
            backgroundColor: '#d6d6d6',
            cursor: 'pointer',
          }}
        >
          <FontAwesomeIcon
            size="lg"
            icon={faEye}
            style={{
              marginLeft: 10,
              marginRight: 10,
              verticalAlign: 'middle',
              color: '#333333',
            }}
          />
        </button>
      </div>
    </Label>
  );
});
