import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';
import { Container } from '../Components/Container/Container';
import { Paragraph } from '../Components/Paragraph/Paragraph';
import { Title } from '../Components/Title/Title';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { OrderContext, ShoppingCartContext, AuthContext, HashContext } from '../Contexts';
import { PrimaryButton } from '../Components/Button/Button';

import amazon from '../Assets/Images/amazon.png';
import apple from '../Assets/Images/apple.png';
import google from '../Assets/Images/google.png';
import roku from '../Assets/Images/roku.png';
import lg from '../Assets/Images/lg.png';
import samsung from '../Assets/Images/samsung.png';
import vidaa from '../Assets/Images/vidaa.png';
import apk from '../Assets/Images/apk.png';

import { getOrderSession } from '../Services/Order';
import { PasswordField } from '../Components/Form/Form';
import { PORTAL_VIEWS, useSwitchView } from '../views';
import { AppStore } from '../Components/AppStore/AppStore';
import { useLocation } from 'react-router-dom';

const NewCustomerConfirmationPage = ({ order, lastOrder, t, website_portal, app_stores }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  let signupData = null;
  try {
    signupData = JSON.parse(atob(searchParams.get('reg')));
    console.log(signupData);
  } catch (e) {
   
  }

  return (
    <Container size="sm">
      <VerticalSpacer size="xxl">
        <VerticalSpacer size="sm">
          <Title level={1} title={`${t('order_complete_thank_you')} ${order?.customer?.first_name || order?.customerLead?.first_name || ''}`} />
          <Paragraph text={t('order_complete_now_what')} />
          <div style={{ width: 350 }}>
            <PrimaryButton
              onClick={() => {
                window.open(website_portal);
              }}
            >
              {t('order_complete_personal_portal')}
            </PrimaryButton>
          </div>
        </VerticalSpacer>
        {signupData != undefined && signupData?.password != '' && (
          <VerticalSpacer size="sm">
            <Title level={1} title={t('order_complete_login_overview')} />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 125 }}>Username:</div>
              <div>
                <b>{signupData?.username}</b>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 125, margin: 'auto 0 auto 0' }}>Password:</div>
              <div>
                <PasswordField value={signupData?.password ?? ''} label={''} />
              </div>
            </div>
          </VerticalSpacer>
        )}
        <VerticalSpacer size="sm">
          <Title level={1} title={sprintf(t('order_complete_install_apps'))} />
          <Paragraph text={t('order_complete_install_apps_info')} />

          <AppStore text={app_stores?.google_text} url={app_stores?.google_url} logo={google} />
          <AppStore text={app_stores?.apple_text} url={app_stores?.apple_url} logo={apple} />
          <AppStore text={app_stores?.amazon_text} url={app_stores?.amazon_url} logo={amazon} />
          <AppStore text={app_stores?.vidaa_text} url={app_stores?.vidaa_url} logo={vidaa} />
          <AppStore text={app_stores?.lg_text} url={app_stores?.lg_url} logo={lg} />
          <AppStore text={app_stores?.samsung_text} url={app_stores?.samsung_url} logo={samsung} />
          <AppStore text={app_stores?.roku_text} url={app_stores?.roku_url} logo={roku} />
          <AppStore text={app_stores?.apk_text} url={app_stores?.apk_url} logo={apk} />
        </VerticalSpacer>
      </VerticalSpacer>
    </Container>
  );
};

const ExistingCustomerConfirmationPage = ({ customer, order, t, website_portal }) => {
  return (
    <Container size="sm">
      <VerticalSpacer size="xxl">
        <VerticalSpacer size="sm">
          <Title level={1} title={sprintf(t('order_complete_thank_you'), customer.first_name)} />
          <Paragraph text={t('order_complete_now_what')} />
        </VerticalSpacer>
        <VerticalSpacer size="sm">
          <div style={{ width: 300 }}>
            <PrimaryButton
              onClick={() => {
                window.open(website_portal, '_blank');
              }}
            >
              {t('order_complete_personal_portal')}
            </PrimaryButton>
          </div>
        </VerticalSpacer>
      </VerticalSpacer>
    </Container>
  );
};

export function PageOrderComplete() {
  const { t } = useTranslation();
  const { search } = window.location;
  const { switchView } = useSwitchView();

  const orderId = new URLSearchParams(search).get('mws');

  const { lastOrder } = useContext(OrderContext);
  const { client, deployment, service, portalId } = useContext(HashContext);
  const { app_stores, website_portal } = useContext(ShoppingCartContext);
  const { customer, isLoading } = useContext(AuthContext);

  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const fetchOrder = async () => {
      const { data } = await getOrderSession(client, deployment, service, portalId, orderId);

      setOrder(data);
    };

    if (customer) {
      switchView(PORTAL_VIEWS.home, {
        oc: undefined,
      });
    } else {
      fetchOrder();
    }
  }, [client, deployment, orderId, portalId, service, customer]);

  return customer || isLoading || !order ? <></> : <NewCustomerConfirmationPage {...{ lastOrder, app_stores, website_portal, order, t }} />;
}
