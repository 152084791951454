import { OrderContext, AuthContext } from '../../Contexts';

import { getPlanPrice, getPlanPriceTax, getProductTaxRate } from '../../Utilities/ProductUtilities';
import { formatPriceWithCurrency } from '../../Utilities/PriceUtilities';
import { getOrderCountryTaxRate, getOrderCurrency } from '../../Utilities/OrderUtilities';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Plan, Product } from '../../interfaces';

type PlanPriceProps = {
  plan: Plan;
  product: Product;
};

export function PlanPriceCart({ plan, product }: PlanPriceProps) {
  const { t } = useTranslation();
  const { order } = useContext(OrderContext);
  const { customer } = useContext(AuthContext);
  const currency = getOrderCurrency({ ...order, customer });
  const price = getPlanPrice(plan, currency);
  const duration = plan.duration;
  const taxrate = getOrderCountryTaxRate(order);
  const billing_taxrate = customer != undefined ? customer.billing_taxrate : taxrate;

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {billing_taxrate != 0 && (
          <>
            <div>
              {`
        ${duration}
        ${t(`plan_price_${plan.duration_period_in}_${duration === 1 ? 'single' : 'plural'}`)} 
      `}
            </div>
            {/* <div>
              {`
        ${formatPriceWithCurrency(price ?? NaN, currency)}
        - ${formatPriceWithCurrency(getPlanPriceTax(plan, currency, billing_taxrate) ?? NaN, currency)} (${billing_taxrate}%) 
      `}
            </div> */}
          </>
        )}
        {billing_taxrate == 0 && (
          <div>
            {`
        ${duration}
        ${t(`plan_price_${plan.duration_period_in}_${duration === 1 ? 'single' : 'plural'}`)} 

       -  ${formatPriceWithCurrency(price ?? NaN, currency)}
      `}
          </div>
        )}
      </div>
    </div>
  );
}
