import moment from 'moment';
import { Customer, Plan, Product, TaxRate } from '../interfaces';
import { getOrderTotal, getOrderTotalPartial, getOrderTotalPartialExtra, getOrderTotalRenew } from './PriceUtilities';

export const getTranslatedName = (product: Product, language: string): string => product.external.names.find((x) => x.language.toLocaleLowerCase() === language.toLocaleLowerCase())?.name || product.name;

export const getTranslatedDescription = (product: Product, language: string) => product.external.descriptions.find((x) => x.language.toLocaleLowerCase() === language.toLocaleLowerCase())?.description;

export const getPlanPrice = (plan: Plan, currency: string) => {
  return plan?.prices.find((price) => price.currency === currency)?.price;
};
export const getPlanPriceTax = (plan: Plan, currency: string, tax_rate: number) => {
  var price = plan?.prices.find((price) => price.currency === currency)?.price;
  return (price * (100 + tax_rate)) / 100;
};

export const getTotalPlanPrice = (plan: Plan, currency: string) => {
  const price = getPlanPrice(plan, currency);
  return price;
};

export const getCurrencyCreditPrice = (customer: Customer) => {
  const price = customer.credit_amount;
  return price;
};
export const getCurrencyCreditPriceNoTax = (customer: Customer) => {
  const price = customer.credit_amount / (100 + customer.billing_taxrate);
  return price * 100;
};

export const getTotalPlanPricePartial = (plan: Plan, currency: string, customer: Customer) => {
  const priceDivider = Math.round(moment().add(plan.duration, plan.duration_period_in).diff(moment(), 'days', true));
  const priceMultiplier = Math.max(Math.round(moment.unix(customer.subscriptions.base.expiration_date).diff(moment(), 'days', true)), 0);

  const price = (getPlanPrice(plan, currency) / priceDivider) * priceMultiplier;
  //const duration = plan.duration;
  return price // * duration;
};

export const getProductTaxRate = (country: string, taxRates: TaxRate) => {
  if (taxRates.rates) {
    var test = taxRates.rates.find((r) => r.country == country);
    if (test == undefined) {
      return {
        name: '0%',
        rate: {
          country: country,
          percentage: 0,
        },
      };
    } else {
      return {
        name: test.rate_name,
        rate: {
          country: test.country,
          percentage: test.percentage,
        },
      };
    }
  } else {
    return {
      name: '0%',
      rate: {
        country: country,
        percentage: 0,
      },
    };
  }
};

export const getFinalPricePartial = (customer, order, taxRates) => {
  if (getCurrencyCreditPrice(customer) > getOrderTotalPartial(order, taxRates, customer.subscriptions.base.plan, customer)) {
    return 0;
  } else {
    return Math.abs(getCurrencyCreditPrice(customer) - getOrderTotalPartial(order, taxRates, customer.subscriptions.base.plan, customer));
  }
};
export const getFinalPrice = (customer, order, taxRates) => {
  if (getCurrencyCreditPrice(customer) > getOrderTotal(order, taxRates)) {
    return 0;
  } else {
    return Math.abs(getCurrencyCreditPrice(customer) - getOrderTotal(order, taxRates));
  }
};
export const getFinalPriceExtra = (customer, order, taxRates) => {
  if (getCurrencyCreditPrice(customer) > getOrderTotalPartialExtra(order, taxRates, customer.subscriptions.base.plan, customer)) {
    return 0;
  } else {
    return Math.abs(getCurrencyCreditPrice(customer) - getOrderTotalPartialExtra(order, taxRates, customer.subscriptions.base.plan, customer));
  }
};
export const getFinalPriceRenew = (customer, order, taxRates) => {
  if (getCurrencyCreditPrice(customer) > getOrderTotalRenew(order, taxRates, customer)) {
    return 0;
  } else {
    return Math.abs(getOrderTotalRenew(order, taxRates, customer) - getCurrencyCreditPrice(customer));
  }
};

export const getFinalCreditsRenew = (customer, order, taxRates) => {
  if (getCurrencyCreditPrice(customer) > getOrderTotalRenew(order, taxRates, customer)) {
    return Math.abs(getCurrencyCreditPrice(customer) - getOrderTotalRenew(order, taxRates, customer));
  } else {
    return 0;
  }
};

export const getFinalCreditsPartial = (customer, order, taxRates) => {
  if (getCurrencyCreditPrice(customer) > getOrderTotalPartial(order, taxRates, customer.subscriptions.base.plan, customer)) {
    return Math.abs(getCurrencyCreditPrice(customer) - getOrderTotalPartial(order, taxRates, customer.subscriptions.base.plan, customer));
  } else {
    return 0;
  }
};

export const getFinalCredits = (customer, order, taxRates) => {
  if (getCurrencyCreditPrice(customer) > getOrderTotal(order, taxRates)) {
    return Math.abs(getCurrencyCreditPrice(customer) - getOrderTotal(order, taxRates));
  } else {
    return 0;
  }
};

export const getFinalCreditsExtra = (customer, order, taxRates) => {
  if (getCurrencyCreditPrice(customer) > getOrderTotalPartialExtra(order, taxRates, customer.subscriptions.base.plan, customer)) {
    return Math.abs(getCurrencyCreditPrice(customer) - getOrderTotalPartialExtra(order, taxRates, customer.subscriptions.base.plan, customer));
  } else {
    return 0;
  }
};

export const getFinalCreditExtra = (customer, order, taxRates) => {
  if (getCurrencyCreditPrice(customer) > getOrderTotalPartialExtra(order, taxRates, customer.subscriptions.base.plan, customer)) {
    return Math.abs(getCurrencyCreditPrice(customer) - getOrderTotalPartialExtra(order, taxRates, customer.subscriptions.base.plan, customer));
  } else {
    return 0;
  }
};
