import { DesignContext } from '../Contexts';
import { ReactComponent as Loader } from '../Assets/Icons/loader.svg';
import styles from './Loading.module.scss';
import { useContext } from 'react';

export function PageLoading() {
  const { globals } = useContext(DesignContext);

  return (
    <div className={styles.page}>
      <span
        className={styles.loader}
        style={{
          color: globals['primary-color'],
        }}
      >
        <Loader />
      </span>
    </div>
  );
}
