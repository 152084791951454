import React, { useEffect, useState } from 'react';
import { AuthContext } from '../../Contexts';
import { Form, Input, PasswordField, SubmitButton } from '../Form/Form';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { PORTAL_VIEWS, useSwitchView } from '../../views';
import {  notification } from 'antd';

export function LoginForm() {
  const { login, isLoading } = React.useContext(AuthContext);
  const { handleSubmit, register } = useForm();
  const { t } = useTranslation();
  const { switchViewUrl } = useSwitchView();
  const [notifier, context] = notification.useNotification();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  useEffect(() => {
    if (isLoggingIn && !isLoading) {
      notifier.error({ message: t('login_form_failed') });
    }
  }, [isLoggingIn, isLoading]);

  return (
    <Form
      onSubmit={handleSubmit(async (data: any) => {
        setIsLoggingIn(true);
        login(data);
      })}
    >
      {context}
      <div
        style={{
          marginTop: 50,
          justifyContent: 'center',
        }}
      >
        <div style={{ marginTop: 25 }}>
          <Input disabled={isLoading} {...register('username', { required: true })} label={t('login_form_username')} />
        </div>
        <div style={{ marginTop: 25 }}>
          <PasswordField disabled={isLoading} {...register('password', { required: true })} label={t('login_form_password')} />
        </div>
        <div style={{ marginTop: 40 }}>
          <SubmitButton disabled={isLoading}>{t('login_form_login')}</SubmitButton>
        </div>
        <div style={{ float: 'right', marginTop: 25 }}>
          <Link className="text-secondary float-right" to={switchViewUrl(PORTAL_VIEWS.forgotpassword)}>
            {t('login_form_forgot_password')}
          </Link>
        </div>
      </div>
    </Form>
  );
}
