import { OrderContext, AuthContext, ShoppingCartContext, DesignContext } from '../../Contexts';

import { getPlanPrice, getPlanPriceTax, getProductTaxRate } from '../../Utilities/ProductUtilities';
import { formatPriceWithCurrency } from '../../Utilities/PriceUtilities';
import { getOrderCountryTaxRate, getOrderCurrency } from '../../Utilities/OrderUtilities';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Plan, Product } from '../../interfaces';
import styles from './PlanPriceCard.module.scss';

type PlanPriceProps = {
  plan: Plan;
  product: Product;
};
//
export function PlanPriceCard({ plan, product }: PlanPriceProps) {
  const { t } = useTranslation();
  const { order } = useContext(OrderContext);
  const { customer } = useContext(AuthContext);
  const currency = getOrderCurrency({ ...order, customer });
  const taxrate = getOrderCountryTaxRate(order);
  const price = getPlanPrice(plan, currency);
  const duration = plan.duration;
  const billing_taxrate = customer != undefined ? customer.billing_taxrate : taxrate;
  const { including_tax } = useContext(ShoppingCartContext);
  const {
    globals,
  } = useContext(DesignContext);


  return (
    <div className={styles.component}>
    <div style={{ display: 'flex', flexDirection: 'row' , marginLeft:10, fontSize:12,                    fontFamily: globals['font-family'], }}>
 
      <div style={{ display: 'flex', flexDirection: 'row' , float:'right', width:'100%', fontSize:12 }}>
        {billing_taxrate != 0 && (
          <>
            <div className="row" style={{ marginRight: 5, display: 'flex', flexDirection: 'row', fontSize:12 }}>
              <div style={{ marginRight: 5,fontSize:12  }}>{duration}</div>
              {t(`plan_price_${plan.duration_period_in}_${duration === 1 ? 'single' : 'plural'}`)}
            </div>
            {including_tax && <div style={{ marginRight: 5,fontSize:12  }}>{formatPriceWithCurrency(getPlanPriceTax(plan, currency, billing_taxrate) ?? NaN, currency)}</div>}
            {!including_tax && <div style={{ paddingRight: 5 ,fontSize:12 }}>{formatPriceWithCurrency(price ?? NaN, currency)}</div>}
          </>
        )}
        {billing_taxrate == 0 && (
          <div>
            {`
        ${duration}
      ${t(`plan_price_${plan.duration_period_in}_${duration === 1 ? 'single' : 'plural'}`)} 

       -  ${formatPriceWithCurrency(price ?? NaN, currency)}
      `}
          </div>
        )}
      </div>

    </div>
    </div>
  );
}
