import { useContext } from 'react';
import { getOrderCurrency } from '../../Utilities/OrderUtilities';
import { useTranslation } from 'react-i18next';
import { OrderContext, AuthContext } from '../../Contexts';
import { getCurrencyCreditPrice, getFinalCredits, getFinalCreditsPartial, getFinalPrice, getFinalPricePartial, getTotalPlanPrice, getTotalPlanPricePartial, getTranslatedName } from '../../Utilities/ProductUtilities';
import { Image } from '../Image/Image';
import { PlanPricePortal } from '../PlanPrice/PlanPricePortal';
import { formatPriceWithCurrency, getOrderTaxTotal, getOrderTaxTotalPartial, getOrderTotal, getOrderTotalNoTax, getOrderTotalPartial, getOrderTotalPartialNoTax, getTaxRateName } from '../../Utilities/PriceUtilities';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import styles from './PortalCartOverview.module.scss';
import { Order } from "../../interfaces";
import moment from 'moment';
import { Product, Plan } from '../../interfaces';

type PortalCartOverviewProps = {
  allowActions?: boolean;
  isCompleted?: boolean;
  existingOrder?: Order;
};

export function PortalCartOverview({ allowActions = true, isCompleted = false }: PortalCartOverviewProps) {
  let { order, lastOrder, removeProduct, subscriptionRules, taxRates } = useContext(OrderContext);
  const { customer } = useContext(AuthContext);
  order = isCompleted ? lastOrder : order;
  const { t } = useTranslation();
  const currency = getOrderCurrency(order);

  if (!order) {
    return null;
  }

  const isCartEmpty = !order.products || order.products.length === 0;

  return (
    <div style={{ marginTop: 20 }}>
      {isCartEmpty ? (
        <span>{t('cart_overview_empty_cart')}</span>
      ) : (
        <div>
          {order.products.map(({ product, plan }, index) => (
            <ProductRow
              key={product._id}
              {...{
                product,
                plan,
                allowActions,
              }}
              onProductDelete={() => {
                removeProduct(order.products[index].product, plan);
              }}
            />
          ))}
          {subscriptionRules.allow_partial_upgrades && <ProductRow key={customer.subscriptions.base.product._id} product={customer.subscriptions.base.product} plan={customer.subscriptions.base.plan} allowActions={false} onProductDelete={() => {}} isCredit={true} />}
          {subscriptionRules.allow_partial_upgrades && getOrderTotalPartial(order, taxRates, customer.subscriptions.base.plan, customer) > 0 && (
            <div
              style={{
                border: '1px solid #dedede',
                backgroundColor: '#fff',
                borderRadius: 5,
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '85%',
                  }}
                >
                  <b>{t('cart_overview_subtotal')}:</b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '15%',
                  }}
                >
                  <b>{formatPriceWithCurrency(getOrderTotalPartialNoTax(order, customer.subscriptions.base.plan, customer), currency)}</b>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '85%',
                  }}
                >
                  <b>
                    {t('cart_overview_tax') + ' '}
                    {getTaxRateName(order, taxRates)}
                    {'%:'}
                  </b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '15%',
                  }}
                >
                  <b>{formatPriceWithCurrency(getOrderTaxTotalPartial(order, taxRates, customer.subscriptions.base.plan, customer), currency)}</b>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '85%',
                  }}
                >
                  <b>{t('cart_overview_total')}:</b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '15%',
                  }}
                >
                  <b>{formatPriceWithCurrency(getOrderTotalPartial(order, taxRates, customer.subscriptions.base.plan, customer), currency)}</b>
                </div>
              </div>
              {getCurrencyCreditPrice(customer) > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      width: '85%',
                    }}
                  >
                    <b>{t('cart_overview_credit')}:</b>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      color: 'green',
                      width: '15%',
                    }}
                  >
                    <b>{formatPriceWithCurrency(getCurrencyCreditPrice(customer), customer.billing_currency)}</b>
                  </div>
                </div>
              )}
              {getCurrencyCreditPrice(customer) > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      width: '85%',
                    }}
                  >
                    <b>{t('cart_overview_final_total')}:</b>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      width: '15%',
                    }}
                  >
                    <b>{formatPriceWithCurrency(getFinalPricePartial(customer, order, customer.billing_taxrate), customer.billing_currency)}</b>
                  </div>
                </div>
              )}
              {getCurrencyCreditPrice(customer) > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      width: '85%',
                    }}
                  >
                    <b>{t('cart_overview_credits_left')}:</b>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      width: '15%',
                      color: 'green',
                    }}
                  >
                    <b>{formatPriceWithCurrency(getFinalCreditsPartial(customer, order, customer.billing_taxrate), currency)}</b>
                  </div>
                </div>
              )}
            </div>
          )}
          {subscriptionRules.allow_partial_upgrades && getOrderTotalPartial(order, taxRates, customer.subscriptions.base.plan, customer) <= 0 && (
            <div
              style={{
                border: '1px solid #dedede',
                backgroundColor: '#fff',
                borderRadius: 5,
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '85%',
                  }}
                >
                  <b>{t('cart_overview_subtotal')}:</b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    color: 'green',
                    width: '15%',
                  }}
                >
                  <b>{formatPriceWithCurrency(getOrderTotalPartialNoTax(order, customer.subscriptions.base.plan, customer) * -1, currency)}</b>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '85%',
                  }}
                >
                  <b>
                    {t('cart_overview_tax') + ' '}
                    {getTaxRateName(order, taxRates)}
                    {'%:'}
                  </b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    color: 'green',
                    width: '15%',
                  }}
                >
                  <b>{formatPriceWithCurrency(getOrderTaxTotalPartial(order, taxRates, customer.subscriptions.base.plan, customer) * -1, currency)}</b>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '85%',
                  }}
                >
                  <b>{t('cart_overview_total_credit')}:</b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    color: 'green',
                    width: '15%',
                  }}
                >
                  <b>{formatPriceWithCurrency(getOrderTotalPartial(order, taxRates, customer.subscriptions.base.plan, customer) * -1, currency)}</b>
                </div>
              </div>
            </div>
          )}
          {!subscriptionRules.allow_partial_upgrades && (
            <div
              style={{
                border: '1px solid #dedede',
                backgroundColor: '#fff',
                borderRadius: 5,
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '85%',
                  }}
                >
                  <b>{t('cart_overview_subtotal')}:</b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '15%',
                  }}
                >
                  <b>{formatPriceWithCurrency(getOrderTotalNoTax(order), currency)}</b>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '85%',
                  }}
                >
                  <b>
                    {t('cart_overview_tax') + ' '}
                    {getTaxRateName(order, taxRates)}
                    {'%:'}
                  </b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '15%',
                  }}
                >
                  <b>{formatPriceWithCurrency(getOrderTaxTotal(order, taxRates), currency)}</b>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '85%',
                  }}
                >
                  <b>{t('cart_overview_total')}:</b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: '15%',
                  }}
                >
                  <b>{formatPriceWithCurrency(getOrderTotal(order, taxRates), currency)}</b>
                </div>
              </div>
              {getCurrencyCreditPrice(customer) > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      width: '85%',
                    }}
                  >
                    <b>{t('cart_overview_credit')}:</b>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      color: 'green',
                      width: '15%',
                    }}
                  >
                    <b>{formatPriceWithCurrency(getCurrencyCreditPrice(customer), customer.billing_currency)}</b>
                  </div>
                </div>
              )}
              {getCurrencyCreditPrice(customer) > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      width: '85%',
                    }}
                  >
                    <b>{t('cart_overview_final_total')}:</b>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      width: '15%',
                    }}
                  >
                    <b>{formatPriceWithCurrency(getFinalPrice(customer, order, customer.billing_taxrate), customer.billing_currency)}</b>
                  </div>
                </div>
              )}
              {getCurrencyCreditPrice(customer) > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      width: '85%',
                    }}
                  >
                    <b>{t('cart_overview_credits_left')}:</b>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      width: '15%',
                      color: 'green',
                    }}
                  >
                    <b>{formatPriceWithCurrency(getFinalCredits(customer, order, customer.billing_taxrate), currency)}</b>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

type ProductRowProps = {
  product: Product;
  plan: Plan;
  onProductDelete: () => void;
  allowActions: boolean;
  isCredit?: boolean;
};

function ProductRow({ product, plan, onProductDelete, allowActions = true, isCredit = false }: ProductRowProps) {
  const {t} = useTranslation();
  const { i18n } = useTranslation();
  const name = getTranslatedName(product,i18n.language);
  const { order, subscriptionRules } = useContext(OrderContext);
  const currency = getOrderCurrency(order);
  const { customer } = useContext(AuthContext);
  return (
    <div
      className="highlight"
      key={product._id}
      style={{
        border: '1px solid #dedede',
        backgroundColor: '#fff',
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 5,
      }}
    >
      {!isMobile && (
        <div
          style={{
            width: '25%',
            display: 'flex',
            alignItems: 'center',
            padding: 5,
            paddingLeft: 20,
          }}
        >
          <div className={styles.imageContainer}>
            <Image src={product.external.images[0]?.url} alt={name} layoutClass={styles.image} />
          </div>
        </div>
      )}

      <div
        className={styles.productDetails}
        style={{
          margin: 20,
          width: '35%',
        }}
      >
        <span>{isCredit && <b>({t('cart_current')})</b>}</span>
        <span>{!isCredit && <b>({t('cart_new')})</b>}</span>
        <span>
          <b>
            {name} ({product.type})
          </b>
        </span>
        {!plan && <span>{t('cart_invalid_plan')}</span>}
        {plan && <PlanPricePortal {...{ plan }} />}
        {!subscriptionRules.allow_partial_upgrades && (
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div style={{ textAlign: 'center', marginTop: 10, color: '#fff', paddingLeft: 10, paddingRight: 10, borderRadius: 5, backgroundColor: 'crimson', fontSize: 14, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
              {t('subscriptions_start_from')} {moment.unix(customer.subscriptions.base.expiration_date).format('lll')}
            </div>
          </div>
        )}
        <div>
          {allowActions && product.type != 'BASE' && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ cursor: 'pointer' }} onClick={() => onProductDelete()}>
                <FontAwesomeIcon icon={faTrash} style={{ marginRight: 5, marginTop: 15, fontSize: 16 }} />
                <span style={{ fontSize: 16 }}>{t('cart_buttons_remove')}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          verticalAlign: 'top',
          width: '25%',
        }}
      >
        {plan && subscriptionRules.allow_partial_upgrades && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <div>
              {' ' + formatPriceWithCurrency(getTotalPlanPrice(plan, currency), currency) + ' '}/ {' ' + Math.round(moment().add(plan.duration, plan.duration_period_in).diff(moment(), 'days', true)) + ' '}*{' ' + Math.round(moment.unix(customer.subscriptions.base.expiration_date).diff(moment(), 'days', true))}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          verticalAlign: 'top',
          width: '15%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            margin: 20,
            color: isCredit ? 'green' : '#000',
          }}
        >
          {isCredit && <p>-</p>}
          {plan && !subscriptionRules.allow_partial_upgrades && formatPriceWithCurrency(getTotalPlanPrice(plan, currency), currency)}
          {plan && subscriptionRules.allow_partial_upgrades && formatPriceWithCurrency(getTotalPlanPricePartial(plan, currency, customer), currency)}
        </div>
      </div>
    </div>
  );
}
