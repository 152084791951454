import { useTranslation } from 'react-i18next';
import { DesignContext, HashContext, OrderContext, ShoppingCartContext } from '../Contexts';
import { Title } from '../Components/Title/Title';
import { useContext, useEffect } from 'react';
import { List, Image, Radio, Row, Spin } from 'antd';
import './Checkout.css';
import { PORTAL_VIEWS, useSwitchView } from '../views';
import { startTransaction } from '../Services/Order';
import { Loader } from '../Components/Loader/Loader';
import { isMobile } from 'react-device-detect';
import { v4 } from 'uuid';
import { useEffectOnce } from 'react-use';
import { useLocation } from 'react-router-dom';

// https://stackoverflow.com/a/133997
function post(path: string, params: Record<string, string>, method: string = 'post') {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

export function FinishNoPay() {
  const { t } = useTranslation();
  const { search } = useLocation();
const searchParams = new URLSearchParams(search);
  let code = searchParams.get('code');
  if (code == null) {
    code = '';
  } else {
    code = '?code=' + code;
  }
  const { client, deployment, service, portalId } = useContext(HashContext);
  const { order } = useContext(OrderContext);

  useEffect(() => {
    const action = async () => {
      const { data } = await startTransaction(client, deployment, service, portalId, v4(), order);

      if (data.postFormData) {
        post(data.redirectUrl, data.postFormData, 'post');
      } else if (window !== window.parent) {
        window.parent.location.href = data.redirectUrl;
      } else {
        window.location.href = data.redirectUrl;
      }
    };
    action();
  }, []);

  return (
    <>
      <Row style={{ height: '100vh' }} align="top" justify="center">
        <Loader text={t('checkout_pay_redirecting')} />
      </Row>
    </>
  );
}

export function Pay() {
  const { t } = useTranslation();
  const { search } = useLocation();
const searchParams = new URLSearchParams(search);

  let code = searchParams.get('code');
  if (code == null) {
    code = '';
  } else {
    code = '?code=' + code;
  }
  const { client, deployment, service, portalId } = useContext(HashContext);
  const { order } = useContext(OrderContext);
  const {
    order: { payment },
  } = useContext(OrderContext);
  const internalProviders = ['cybersource', 'mercadopago', 'authorizenet', 'momoapi'];
  const { switchView } = useSwitchView();

  useEffect(() => {
    const action = async () => {
      if (payment != null && internalProviders.includes(payment.gateway.payment_provider_id)) {
        switchView((payment.gateway.payment_provider_id + '-payment') as PORTAL_VIEWS);
      } else {
        try {
          const { data } = await startTransaction(client, deployment, service, portalId, v4(), order);

          if (data.postFormData) {
            post(data.redirectUrl, data.postFormData, 'post');
          } else if (window !== window.parent) {
            window.parent.location.href = data.redirectUrl;
          } else {
            window.location.href = data.redirectUrl;
          }
        } catch (e) {}
      }
    };

    action();
  }, []);

  return (
    <>
      {!internalProviders.includes(payment.gateway.payment_provider_id) && (
        <Row style={{ height: '100vh' }} align="top" justify="center">
          <Loader text={t('checkout_pay_redirecting')} />
        </Row>
      )}
    </>
  );
}

type PaymentMethodProps = {
  onDone: () => void;
  onPrevious: () => void;
  showActionBar?: Boolean;
};

export function PaymentMethod({ onDone, onPrevious, showActionBar = true }: PaymentMethodProps) {
  const { t } = useTranslation();
  const {
    setPaymentMethod,
    isLoading,
    paymentProviders,
    order: { payment },
  } = useContext(OrderContext);
  const {
    globals,
  } = useContext(DesignContext);

  const { payment_gateways: paymentGateways } = useContext(ShoppingCartContext);

  useEffectOnce(() => {
    if (payment?.gateway?._id == undefined && paymentGateways.length > 0) {
      setPaymentMethod(paymentGateways[0]);
    }
  });

  const handleGatewayChange = (gateway) => {
    setPaymentMethod(gateway);
  };

  const paymentProvidersForGateways = paymentGateways?.map((g) => paymentProviders?.find((p) => p.id === g.payment_provider_id));

  if (isLoading) {
    return (
      <Row align="middle" justify="center">
        <Spin />
      </Row>
    );
  }

  const ProviderLogo = (method, index) => (
    <Image
      key={index}
      src={method.logo}
      alt={method.name}
      preview={false}
      width={70}
      style={{
        objectFit: 'contain',
      }}
    />
  );

  return (
    <>
      <Title level={1} title={t('payment_method_form_title')} />
      <List
        itemLayout="vertical"
        dataSource={paymentGateways}
        renderItem={(item, index) => (
          <List.Item.Meta
            key={index}
            title={
              <Radio.Button
                checked={payment?.gateway._id === item._id ? true : index == 0 ? true : false}
                onChange={() => handleGatewayChange(item)}
                style={{
                  alignItems: 'center',
                  margin: 5,
                  height: '100%',
                  width: '100%',
                  borderRadius: 5,
                }}
              >
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', margin: '10px', width: '100%' }}>
                  <div style={{ width: 150 }}>
                    <div>
                      <Image width={150} preview={false} src={'https://cloudtv.akamaized.net/donotremove/tvms/billing/payment-methods/' + paymentProvidersForGateways[index].id.replace(' ', '') + '.png'}></Image>
                    </div>
                  </div>
                  <div
                    style={{
                      fontFamily: globals['font-family'],
                      border: '1px #dedede solid',
                      padding: 20,
                      borderRadius: 5,
                      backgroundColor: '#ffffff',
                      textAlign: isMobile ? 'center' : 'start',
                      width: '100%',
                      marginRight: 5,
                    }}
                  >
                    <div>
                      <b>{t('payment_method_form_supported')}</b>
                    </div>
                    <div>{paymentProvidersForGateways[index].methods.map(ProviderLogo)}</div>
                  </div>
                </div>
              </Radio.Button>
            }
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          />
        )}
      />
    </>
  );
}
