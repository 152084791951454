import classNames from 'classnames';
import { SiteHeaderPortal } from '../Components/SiteHeaderPortal/SiteHeaderPortalContainer';
import styles from './Layout.module.scss';
import { ReactNode } from 'react';
//import { SiteHeaderCart } from '../Components/SiteHeaderCart/SiteHeaderCart';

type LayoutProps = {
  children?: ReactNode;
  layoutClass?: string;
  showHeader: boolean;
};

export const CartLayout = ({ children, layoutClass }: LayoutProps) => (
  <div className={classNames(styles.layout, { [`${layoutClass}`]: layoutClass })}>
    {/* <SiteHeaderCart /> */}
    <main className={styles.layoutMain}>{children}</main>
  </div>
);

export const PortalLayout = ({ children, layoutClass, showHeader }: LayoutProps) => (
  <div className={classNames(styles.layout, { [`${layoutClass}`]: layoutClass })}>
    {showHeader && <SiteHeaderPortal />}
    <main className={styles.layoutMain}>{children}</main>
  </div>
);
