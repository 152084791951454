import { DesignContext } from '../../Contexts';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SiteHeaderPortal.module.scss';
import { colorShade } from '../../Utilities/ColorShade';
import LanguageButton from './LanguageButton';
import { ILanguage } from './SiteHeaderPortalContainer';

type LanguageSelectorDropdownProps = {
  layoutClass?: string;
  languages: ILanguage[];

};

const LanguageSelectorDropdown = ({ layoutClass, languages }: LanguageSelectorDropdownProps) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { globals, navbar } = useContext(DesignContext);

  return (
    <div
      className={classNames(styles.languageDropdown, {
        [`${layoutClass}`]: layoutClass,
      })}
    >
      <button
        onClick={() => setOpen(!open)}
        className={classNames(styles.languageDropdownButton)}
        style={{
          fontFamily: globals['font-family'],
          color: navbar['text-color'],
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div style={{ width: '100%', marginRight: 5, fontSize: 16 }}>{t('navigation_language')}:</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 16,
            }}
          >
           {languages.find(l=>l.code == i18n.language)?.name}
          </div>
        </div>
      </button>
      <ul
        className={styles.dropdownItems}
        style={{
          paddingTop: 6,
        }}
      >
        {languages.map((lang, index) => {
          return (
            <li
              key={index}
              style={{
                fontFamily: globals['font-family'],
                color: navbar['text-color'],
                backgroundColor: colorShade(navbar['background-color'], 20),
                padding: 10,
                width:200
              }}
            >
              <LanguageButton language={lang}>
                {lang.name}
              </LanguageButton>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LanguageSelectorDropdown;
