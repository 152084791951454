import React from 'react'

import { Container } from '../Components/Container/Container'

export function PageNotFound() {
  return (
    <Container>
      <h1>404 - Page Not Found</h1>
      <p>We are sorry, this page could not be found</p>
    </Container>
  )
}
