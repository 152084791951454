import styles from './SiteHeaderPortal.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from '../Link/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useContext, useEffect, useState } from 'react';
import { PORTAL_VIEWS, useSwitchView } from '../../views';
import { OrderContext, AuthContext, DesignContext, HashContext } from '../../Contexts';
import axios from 'axios';
import { faBoxOpen, faCoins, faUser, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import SiteHeaderPortalDrawer from './SiteHeaderPortalDrawer';
import CountryCurrencySelectorDropDown from './CountrySelectorDropdown';
import LanguageSelectorDropdown from './LanguageSelectorDropdown';
import { useLocation } from 'react-router';

export interface ICountry {
  _id: string;
  mapping_id: number;
  name: string;
  iso3: string;
  iso2: string;
  currency: string;
  emoji: string;
}

export interface ILanguage {
  _id: string;
  key: number;
  code: string;
  name: string;
}

export function SiteHeaderPortal() {
  const { globals, navbar } = useContext(DesignContext);
  const { isLoggedIn, logout } = useContext(AuthContext);
  const { client, deployment, service } = useContext(HashContext);
  const { setShopBillingData } = useContext(OrderContext);
  const { t } = useTranslation();
  const { switchViewUrl, switchView } = useSwitchView();
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [billingCountry, setBillingCountry] = useState<ICountry | undefined>();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const isInHome = params.get('o') === PORTAL_VIEWS.dashboard;

  const updateShopCountry = (country: ICountry) => {
    //  console.log(country);
    setBillingCountry(country);
    setShopBillingData(country.currency, country.iso3);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      const { data: deploymentData } = await axios.get(
        `${process.env.REACT_APP_TVMS_API_BASE}/api/v1/deployments/find?client=${client}&s=${JSON.stringify({
          name: deployment,
        })}`
      );
      const serviceCountries = (deploymentData.services as any[]).find(({ name }) => name === service).countries as ICountry[];
      const serviceLanguages = (deploymentData.services as any[]).find(({ name }) => name === service).languages as ILanguage[];
      setLanguages(serviceLanguages);
      setCountries(serviceCountries);
      updateShopCountry(serviceCountries[0]);
    };

    fetchCountries();
  }, [client, deployment, service]);

  return (
    <>
      <MobileView>
        <SiteHeaderPortalDrawer billingCountry={billingCountry} countries={countries} languages={languages} updateShopCountry={updateShopCountry} />
      </MobileView>
      <BrowserView>
        <header
          style={{
            fontFamily: globals['font-family'],
            backgroundColor: navbar['background-color'],
            color: navbar['text-color'],
            display: 'flex',
            justifyContent: 'flex-start',
            padding: 5,
            paddingLeft: 20,
            fontSize: '1.5rem',
          }}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            <div className={styles.navList}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  marginRight: 30,
                }}
              >
                {!isLoggedIn ? (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginRight: 20,
                        }}
                      >
                        <CountryCurrencySelectorDropDown countries={countries} billingCountry={billingCountry} setBillingCountry={updateShopCountry} layoutClass={styles.menuItem} />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <LanguageSelectorDropdown languages={languages} layoutClass={styles.menuItem} />
                      </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Link
                          style={{
                            color: navbar['text-color'],
                            textAlign: 'center',
                          }}
                          to={switchViewUrl(PORTAL_VIEWS.shop)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              height: 30,
                              alignItems: 'center',
                            }}
                          >
                            <FontAwesomeIcon size={'xs'} icon={faBoxOpen as IconProp} />
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 30,
                                marginLeft: 10,
                                marginRight: 20,
                                fontSize: 16,
                              }}
                            >
                              {t('navigation_products')}
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Link
                          style={{
                            color: navbar['text-color'],
                            textAlign: 'center',
                          }}
                          to={switchViewUrl(PORTAL_VIEWS.home)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              height: 30,
                              alignItems: 'center',
                            }}
                          >
                            <FontAwesomeIcon size={'xs'} icon={faUser as IconProp} />
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 30,
                                marginLeft: 10,
                                fontSize: 16,
                              }}
                            >
                              {t('navigation_login')}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginRight: 25,
                      }}
                    >
                      <LanguageSelectorDropdown languages={languages} layoutClass={styles.menuItem} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {!isInHome && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginRight: 20,
                          }}
                        >
                          <div
                            style={{
                              color: navbar['text-color'],
                              textAlign: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              switchView(PORTAL_VIEWS.home);
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: 30,
                                alignItems: 'center',
                              }}
                            >
                              <FontAwesomeIcon size={'xs'} icon={faUserEdit as IconProp} />
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: 30,
                                  marginLeft: 10,
                                  fontSize: 16,
                                }}
                              >
                                {t('navigation_customerportal')}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {isInHome && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginRight: 20,
                          }}
                        >
                          <div
                            style={{
                              color: navbar['text-color'],
                              textAlign: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              switchView(PORTAL_VIEWS.transactions);
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: 30,
                                alignItems: 'center',
                              }}
                            >
                              <FontAwesomeIcon size={'xs'} icon={faCoins as IconProp} />
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: 30,
                                  marginLeft: 10,
                                  fontSize: 16,
                                }}
                              >
                                {t('navigation_transactions')}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {isInHome && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginRight: 20,
                          }}
                        >
                          <div
                            style={{
                              color: navbar['text-color'],
                              textAlign: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              switchView(PORTAL_VIEWS.account);
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: 30,
                                alignItems: 'center',
                              }}
                            >
                              <FontAwesomeIcon size={'xs'} icon={faUserEdit as IconProp} />
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: 30,
                                  marginLeft: 10,
                                  fontSize: 16,
                                }}
                              >
                                {t('navigation_my_account')}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <div
                          style={{
                            color: navbar['text-color'],
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (isLoggedIn) logout();
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              height: 30,
                              alignItems: 'center',
                            }}
                          >
                            <FontAwesomeIcon size={'xs'} icon={faUserCheck as IconProp} />
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 30,
                                marginLeft: 10,
                                fontSize: 16,
                              }}
                            >
                              {t('navigation_logout')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
      </BrowserView>
    </>
  );
}
