import { DesignContext } from '../../Contexts';
import classNames from 'classnames';
import { ButtonHTMLAttributes, MouseEventHandler, ReactNode, useContext } from 'react';
import styles from './Button.module.scss';

type ButtonProps = {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  layoutClass?: string;
};

export function PrimaryButton({ children, type, onClick, disabled, layoutClass }: ButtonProps) {
  const {
    globals,
    buttons: { primary },
  } = useContext(DesignContext);

  return (
    <BaseButton
      {...{ children, type, onClick, disabled, layoutClass }}
      style={{
        fontFamily: globals['font-family'],
        backgroundColor: primary['background-color'],
        fontSize: 16,
        color: primary['font-color'],
        borderWidth: primary['border-width'],
        borderRadius: primary['border-radius'],
        borderColor: primary['border-color'],
        fontWeight: 'bold',
        minHeight: 45,
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 2,
      }}
    />
  );
}
export function PrimaryButtonMaxWidthVariableHeight({ children, type, onClick, disabled, layoutClass }: ButtonProps) {
  const {
    globals,
    buttons: { primary },
  } = useContext(DesignContext);

  return (
    <BaseButton
      {...{ children, type, onClick, disabled, layoutClass }}
      style={{
        fontFamily: globals['font-family'],
        backgroundColor: primary['background-color'],
        fontSize: 16,
        color: primary['font-color'],
        borderWidth: primary['border-width'],
        borderRadius: primary['border-radius'],
        borderColor: primary['border-color'],
        fontWeight: 'bold',
        height:'100%',
        width:200,
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 2,
      }}
    />
  );
}

export function SecondaryButton({ children, type, onClick, disabled, layoutClass }: ButtonProps) {
  const {
    globals,
    buttons: { secondary },
  } = useContext(DesignContext);

  return (
    <BaseButton
      {...{ children, type, onClick, disabled, layoutClass }}
      style={{
        fontFamily: globals['font-family'],
        backgroundColor: secondary['background-color'],
        fontSize: 16,
        color: secondary['font-color'],
        borderWidth: secondary['border-width'],
        borderRadius: secondary['border-radius'],
        borderColor: secondary['border-color'],
        fontWeight: 'bold',
        minHeight: 45,
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 2,
      }}
    />
  );
}

export function TertiereButton({ children, type, onClick, disabled, layoutClass }: ButtonProps) {
  const {
    globals,
    buttons: { primary },
  } = useContext(DesignContext);

  return (
    <BaseButton
      {...{ children, type, onClick, disabled, layoutClass }}
      style={{
        fontFamily: globals['font-family'],
        backgroundColor: 'crimson',
        fontSize: 16,
        color: primary['font-color'],
        borderWidth: primary['border-width'],
        borderRadius: primary['border-radius'],
        borderColor: primary['border-color'],
        width: '100%',
        fontWeight: 'bold',
        minHeight: 45,
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 2,
      }}
    />
  );
}

type BaseButtonProps = ButtonProps & {
  style?: React.CSSProperties;
};

function BaseButton({ children, type, onClick, disabled, style, layoutClass }: BaseButtonProps) {
  return (
    <button
      className={classNames(styles.baseComponent, {
        [`${layoutClass}`]: layoutClass,
      })}
      {...{ onClick, type, disabled, style }}
    >
      {children}
    </button>
  );
}
