import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslatedDescription, getTranslatedName } from '../../Utilities/ProductUtilities';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import { Image } from '../Image/Image';
import styles from './ProductCardBase.module.scss';
import { Title } from '../Title/Title';
import { PageProduct } from '../../Pages/Product';
import { ChangeBaseSubscription } from '../ChangeBaseSubscription/ChangeBaseSubscription';
import { AddExtraSubscription } from '../AddExtraSubscription/AddExtraSubscription';
import { Product } from '../../interfaces';

type ProductCardProps = {
  product: Product;
  disabled?: boolean;
  showStartOptions?: boolean;
  allowStartImmediate?: boolean;
  handleNext: Function;
};

export function ProductCard({ product, disabled = false, showStartOptions = false, allowStartImmediate = false, handleNext }: ProductCardProps) {
  const {t} = useTranslation();
  const { i18n } = useTranslation();
  const name = getTranslatedName(product, i18n.language);
  const [showProductModal, setShowProductModal] = useState(false);
  const [productId, setProductId] = useState('');

  return (
    <div
      className={styles.component}
      style={{
        border: '2px solid #dedede',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#fff',
        margin: 10,
      }}
    >
      {showProductModal && <PageProduct id={productId} onCancel={() => setShowProductModal(false)}></PageProduct>}
      <VerticalSpacer size="sm">
        <Title level={3} title={name} />
        <div
          onClick={() => {
            setProductId(product._id);
            setShowProductModal(true);
          }}
          className={styles.imageContainer}
          style={{ cursor: 'pointer' }}
        >
          <Image layoutClass={styles.image} src={product.external.images[0]?.url} alt={name} />
        </div>
        <div className="ere">{getTranslatedDescription(product, i18n.language) || t('product_card_no_description')}</div>
      </VerticalSpacer>
      <div className={styles.addToCart}>
        {product.type == 'BASE' && (
          <ChangeBaseSubscription
            {...{
              product,
              disabled,
              showStartOptions,
              allowStartImmediate,
              handleNext,
            }}
          />
        )}
        {product.type == 'EXTRA' && (
          <AddExtraSubscription
            {...{
              product,
              disabled,
              showStartOptions,
              allowStartImmediate,
              handleNext,
            }}
          />
        )}
      </div>
    </div>
  );
}
