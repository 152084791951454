import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { PaymentMethod } from '../interfaces/paymentMethod';
import { IPaymentProvider } from '@mwaretv/payment';

const getPaymentMethodSettings = (client: string, deployment: string, service: string) =>
  axios.get<PaymentMethod[]>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/payment-method-settings`, {
    params: { instance: client, cms: deployment, crm: service },
  });

export const usePaymentMethodSettings = (client: string, deployment: string, service: string) =>
  useQuery([client, service, 'paymentMethods'], async () => {
    return getPaymentMethodSettings(client, deployment, service)
      .then((r) => Promise.resolve(r.data))
      .catch((e) => Promise.reject(e));
  });

export const getPaymentProviderSchemas = () => axios.get<IPaymentProvider[]>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/payment-provider-schemas`);

export const usePaymentProviderSchemas = () =>
  useQuery(
    ['paymentSchemas'],
    async () => {
      const response = await axios.get<IPaymentProvider[]>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/payment-provider-schemas`);

      return response.data;
    },
    {
      retry: true,
    }
  );
