import { ChangeEvent, FocusEvent, useState, useContext, useEffect } from 'react';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import CreditCard, { Focused } from 'react-credit-cards-2';
//import 'antd/dist/antd.css';
import { Card, Form, Input, Space, notification } from 'antd';
import axios from 'axios';
import { HashContext, OrderContext } from '../../Contexts';
import { PrimaryButton } from '../Button/Button';
import { validateCVV, validateCardNumber, validateExpirationDate } from './CreditCardValidator';
import { v4 } from 'uuid';

export const PaymentForm = () => {
  const { client, deployment, service, portalId } = useContext(HashContext);
  const { order } = useContext(OrderContext);

  const [notifier, contextHolder] = notification.useNotification();
  const [payButtonVisible, setPayButtonVisible] = useState(false);

  const [cardState, setCardState] = useState({
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    issuer: '',
    focused: '',
  });

  const [validationState, setValidationState] = useState({
    number: false,
    expiry: false,
    cvc: false,
  });

  useEffect(() => {
    if (validationState.number === true && validationState.expiry === true && validationState.cvc === true) {
      setPayButtonVisible(true);
    } else {
      setPayButtonVisible(false);
    }
  }, [validationState]);

  const displayError = (error: any) => {
    notifier.open({
      message: 'Payment Error',
      description: error.response.data?.message,
      duration: 5,
    });
  };

  const urlInternalTransaction = `${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/shopping-carts/${portalId}/orders/sessions/${v4()}/start-transaction`;
  const submitData = async (data) => {
    try {
      const { number, cvc, expiry, issuer } = data;

      const dataToSend = {
        card_number: number,
        card_csc: cvc,
        card_expiry_month: expiry.slice(0, 2),
        card_expiry_year: expiry.slice(-2),
        card_type: issuer,
        orderData: order
      };

      const response = await axios.post(urlInternalTransaction, dataToSend, {
        params: { instance: client, cms: deployment, crm: service },
      });

      window.location.replace(response.data.redirectUrl);
    } catch (error: unknown) {
      displayError(error as any);
    }
  };

  const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setCardState((prev) => ({ ...prev, [name]: value }));

    switch (name) {
      case 'number':
        const creditCard = validateCardNumber(value);
        setCardState((prev) => ({ ...prev, issuer: creditCard?.card?.niceType }));
        setValidationState((prev) => ({ ...prev, [name]: creditCard.isValid }));
        break;
      case 'expiry':
        setValidationState((prev) => ({ ...prev, [name]: validateExpirationDate(value) }));
        break;
      case 'cvc':
        setValidationState((prev) => ({ ...prev, [name]: validateCVV(value) }));
        break;
    }
  };

  const handleInputFocus = (evt: FocusEvent<HTMLInputElement>) => {
    setCardState((prev) => ({ ...prev, focused: evt.target.name as Focused }));
  };

  return (
    <Card>
      {contextHolder}
      <CreditCard number={cardState.number} name={cardState.name} expiry={cardState.expiry} cvc={cardState.cvc} issuer={cardState.issuer} focused={cardState.focused as Focused} />
      <br />
      <Form name="paymentForm">
        <Form.Item>
          <Input name="number" placeholder="1234 5678 9012 3456" autoComplete="off" onChange={handleInputChange} onFocus={handleInputFocus} />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'Please enter your name!' }]}>
          <Input name="name" placeholder="Jon Doe" onChange={handleInputChange} onFocus={handleInputFocus} />
        </Form.Item>

        <Form.Item>
          <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 9px)' }}>
            <Input name="expiry" placeholder="03/26" autoComplete="off" onChange={handleInputChange} onFocus={handleInputFocus} />
          </Form.Item>

          <Form.Item style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 9px' }}>
            <Input name="cvc" placeholder="123" autoComplete="off" onChange={handleInputChange} onFocus={handleInputFocus} />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Space>
            <PrimaryButton onClick={() => window.history.back()}>Back</PrimaryButton>
            {payButtonVisible && (
              <PrimaryButton
                onClick={async () => {
                  submitData(cardState);
                }}
              >
                Pay
              </PrimaryButton>
            )}
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};
