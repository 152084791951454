import { useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../Button/Button';
import styles from './ExtraSubscriptionCard.module.scss';
import { PlanPricePortal } from '../PlanPrice/PlanPricePortal';
import { Modal } from 'antd';
import { PortalCartFlowExtra } from '../PortalCartFlowExtra/PortalCartFlowExtra';
import { PortalCartFlowExtraRemove } from '../PortalCartFlowExtraRemove/PortalCartFlowExtraRemove';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { Product, Subscription } from '../../interfaces';
import { AuthContext, HashContext } from '../../Contexts';
import { useCartProducts } from '../../Services/CartProducts';
import dayjs from 'dayjs';

type ExtraSubscriptionCardProps = {
  subscription: Subscription;
  showActionButtons?: boolean;
  showPrice?: boolean;
  allowChangingBase?: boolean;
  type: string;
};

export function ExtraSubscriptionCard({ subscription, type, showActionButtons, showPrice, allowChangingBase = true }: ExtraSubscriptionCardProps) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenRemove, setModalOpenRemove] = useState(false);
  const { start_date: startDate, activation_date: activationDate, expiration_date: expirationDate, end_date: endDate } = subscription;
  const product = subscription.product as Product;
  const [image] = product.external.images;
  const plan = subscription.plan;
  const { customer } = useContext(AuthContext);
  const removeExtraProduct = () => {
    setModalOpenRemove(true);
  };

  const now = dayjs();
  const expiration = dayjs.unix(customer.subscriptions.base.expiration_date ?? now.unix());

  const isActuallyExpired = customer.subscriptions.base?.expiration_date != null && now.isAfter(expiration);
  
  const trueSubscriptionStatus = isActuallyExpired ? 'EXPIRED' : customer.subscriptionStatus.toLocaleUpperCase();


  return (
    <>
      <DisplayExtraProductsModal
        open={modalOpen}
        onOk={(data) => {
          setModalOpen(false);
        }}
        onCancel={() => setModalOpen(false)}
        productType={'EXTRA'}
      />
      <DisplayExtraRemoveProductsModal
        activationDate={subscription.activation_date}
        expirationDate={subscription.expiration_date}
        plan={subscription.plan}
        product={product}
        open={modalOpenRemove}
        onOk={(data) => {
          setModalOpenRemove(false);
        }}
        onCancel={() => setModalOpenRemove(false)}
        productType={'EXTRA'}
      />
      <div
        style={{
          border: '2px solid #dedede',
          padding: 20,
          borderRadius: 5,
          backgroundColor: '#fff',
          flexDirection: `${isMobile ? 'column' : 'row'}`,
        }}
        className={classNames(styles.component)}
      >
        <div className={styles.imageContainer}>{image && <img className={styles.image} src={image.url} alt={product.name} />}</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 2,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <b>{product.name}</b>
            {customer.productStatus.toUpperCase() == 'TRIAL' && (
              <div style={{ marginLeft: 10, color: '#fff', paddingLeft: 10, paddingRight: 10, borderRadius: 100, backgroundColor: '#28b463', fontSize: 14, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                {product.settings.trial_days} {t('plan_price_day_plural')} {t('trial')}
              </div>
            )}
          </div>

          {!(activationDate || expirationDate) && (
            <div style={{ fontSize: 16 }}>
              {t('subscription_card_subscription_starts_on', {
                start_date: moment.unix(startDate).format('lll'),
              })}
            </div>
          )}

          {(activationDate || expirationDate) && expirationDate !== startDate && (
            <div style={{ fontSize: 16 }}>{`
            ${activationDate ? moment.unix(activationDate).format('lll') : '-'} - ${expirationDate ? moment.unix(expirationDate).format('lll') : '-'}`}</div>
          )}
          <div
            className={styles.actionContainer}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'row',
              fontSize: 16,
            }}
          >
            <PlanPricePortal {...{ plan: plan }} />
          </div>
          {customer.subscriptions.base.expiration_date === endDate && expirationDate !== startDate && expirationDate > moment().unix() && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
              <div style={{ textAlign: 'center', backgroundColor: 'coral', fontSize: 14, fontWeight: 'bold', color: '#fff', borderRadius: 5, paddingLeft: 10, paddingRight: 10 }}>
                {t('subscriptions_end_on') + ': '}
                {moment.unix(customer.subscriptions.base.expiration_date).format('lll')}
              </div>
            </div>
          )}
          {customer.subscriptions.base.expiration_date !== endDate && expirationDate !== startDate && expirationDate < moment().unix() && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
              <div style={{ textAlign: 'center', backgroundColor: 'crimson', fontSize: 14, fontWeight: 'bold', color: '#fff', borderRadius: 5, paddingLeft: 10, paddingRight: 10 }}>
                {t('subscriptions_expired_at') + ': '}
                {moment.unix(customer.subscriptions.base.expiration_date).format('lll')}
              </div>
            </div>
          )}
          {expirationDate === startDate && expirationDate == activationDate && expirationDate > moment().unix() && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
              <div style={{ textAlign: 'center', backgroundColor: 'mediumseagreen', fontSize: 14, fontWeight: 'bold', color: '#fff', borderRadius: 5, paddingLeft: 10, paddingRight: 10 }}>
                {t('subscriptions_start_on') + ': '}
                {moment.unix(startDate).format('lll')}
              </div>
            </div>
          )}
          {expirationDate === startDate && expirationDate == activationDate && expirationDate < moment().unix() && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
              <div style={{ textAlign: 'center', backgroundColor: 'coral', fontSize: 14, fontWeight: 'bold', color: '#fff', borderRadius: 5, paddingLeft: 10, paddingRight: 10 }}>{t('subscriptions_starts_after_renewal')}</div>
            </div>
          )}
        </div>
        {showActionButtons === true && type === 'EXTRA' && customer.productStatus.toLocaleUpperCase() !== 'TRIAL' && trueSubscriptionStatus !== 'EXPIRED' && customer.subscriptions.base.expiration_date !== endDate && expirationDate > moment().unix() && (
          <div>
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'flex-end',
              }}
              className={styles.actionContainer}
            >
              <PrimaryButton onClick={removeExtraProduct}>{t('subscription_card_remove')}</PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

interface DisplayProductModalProps {
  open: boolean;
  onOk: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  productType: 'EXTRA';
}

const DisplayExtraProductsModal = ({ open, onOk, onCancel }: DisplayProductModalProps) => {
  const { client, deployment, service, portalId } = useContext(HashContext);
  const { data } = useCartProducts(client, deployment, service, portalId, {
    type: { $eq: 'EXTRA' },
  });

  return (
    <Modal {...{ open, onCancel, onOk }} width={1000}>
      {data && <PortalCartFlowExtra showStartOptions={true} items={data.data} />}
    </Modal>
  );
};

interface DisplayProductRemoveModalProps {
  open: boolean;
  onOk: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  productType: 'EXTRA';
  product: Product;
  activationDate;
  expirationDate;
  plan;
}

const DisplayExtraRemoveProductsModal = ({ open, onOk, onCancel, product, activationDate, expirationDate, plan }: DisplayProductRemoveModalProps) => {
  return (
    <Modal footer={false} {...{ open, onCancel, onOk }} width={1000}>
      {product && <PortalCartFlowExtraRemove showStartOptions={true} product={product} activationDate={activationDate} expirationDate={expirationDate} plan={plan} />}
    </Modal>
  );
};
