import { useContext } from 'react';
import { getOrderCurrency } from '../../Utilities/OrderUtilities';
import { useTranslation } from 'react-i18next';
import { OrderContext, ShoppingCartContext } from '../../Contexts';
import { getTotalPlanPrice, getTranslatedName } from '../../Utilities/ProductUtilities';
import { Image } from '../Image/Image';
import { PlanPriceCart } from '../PlanPrice/PlanPriceCart';
import { formatPriceWithCurrency, getDiscountName, getOrderDiscount, getOrderTaxTotal, getOrderTotal, getOrderTotalDiscount, getOrderTotalNoTax, getTaxRateName } from '../../Utilities/PriceUtilities';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import styles from './CartOverview.module.scss';
import { Order } from '../../interfaces';
import { Plan, Product } from '../../interfaces';

type CartOverviewProps = {
  allowActions?: boolean;
  isCompleted?: boolean;
  existingOrder?: Order;
};

export function CartOverview({ allowActions = true, isCompleted = false }: CartOverviewProps) {
  let { order, lastOrder, removeProduct, taxRates } = useContext(OrderContext);
  order = isCompleted ? lastOrder : order;
  const { t } = useTranslation();
  const currency = getOrderCurrency(order);
  const { enable_coupons } = useContext(ShoppingCartContext);

  if (!order) {
    return null;
  }

  const isCartEmpty = !order.products || order.products.length === 0;
  const discount = order.discount || 0;

  return (
    <div style={{ marginTop: 20 }}>
      {isCartEmpty ? (
        <span>{t('cart_overview_empty_cart')}</span>
      ) : (
        <div>
          {order.products.map(({ product, plan }, index) => (
            <ProductRow
              key={product._id}
              {...{
                product,
                plan,
                allowActions,
              }}
              onProductDelete={() => {
                removeProduct(order.products[index].product, plan);
              }}
            />
          ))}
          {order.products[0].product.settings.trial_days == 0 && (
            <div
              style={{
                border: '1px solid #dedede',
                backgroundColor: '#fff',
                borderRadius: 5,
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                  }}
                >
                  <b>{t('cart_overview_subtotal')}:</b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: 100,
                  }}
                >
                  <b>{formatPriceWithCurrency(getOrderTotalNoTax(order), currency)}</b>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                  }}
                >
                  <b>
                    {t('cart_overview_tax') + ' '}
                    {getTaxRateName(order, taxRates)}
                    {'%:'}
                  </b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: 100,
                  }}
                >
                  <b>+{formatPriceWithCurrency(getOrderTaxTotal(order, taxRates), currency)}</b>
                </div>
              </div>
              {(!enable_coupons || discount == undefined || discount == 0) && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                    }}
                  >
                    <b>{t('cart_overview_total')}:</b>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 20,
                      width: 100,
                    }}
                  >
                    <b>{formatPriceWithCurrency(getOrderTotal(order, taxRates), currency)}</b>
                  </div>
                </div>
              )}
              {enable_coupons && discount != undefined && discount != 0 && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: 20,
                      }}
                    >
                      <b>
                        {t('cart_overview_coupon_discount')} {getDiscountName(discount)}
                        {'%:'}
                      </b>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: 20,
                        width: 100,
                      }}
                    >
                      <b>-{formatPriceWithCurrency(getOrderDiscount(order, taxRates, discount), currency)}</b>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: 20,
                      }}
                    >
                      <b>{t('cart_overview_total')}:</b>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: 20,
                        width: 100,
                      }}
                    >
                      <b>{formatPriceWithCurrency(getOrderTotalDiscount(order, taxRates, discount), currency)}</b>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {order.products[0].product.settings.trial_days > 0 && (
            <div
              style={{
                border: '1px solid #dedede',
                backgroundColor: '#fff',
                borderRadius: 5,
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                  }}
                >
                  <b>{t('cart_overview_subtotal')}:</b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: 100,
                  }}
                >
                  <b>{formatPriceWithCurrency(0, currency)}</b>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                  }}
                >
                  <b>
                    {t('cart_overview_tax') + ' '}
                    {getTaxRateName(order, taxRates)}
                    {'%:'}
                  </b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: 100,
                  }}
                >
                  <b>{formatPriceWithCurrency(0, currency)}</b>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                  }}
                >
                  <b>{t('cart_overview_total')}:</b>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 20,
                    width: 100,
                  }}
                >
                  <b>{formatPriceWithCurrency(0, currency)}</b>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

type ProductRowProps = {
  product: Product;
  plan: Plan;
  onProductDelete: () => void;
  allowActions: boolean;
};

function ProductRow({ product, plan, onProductDelete, allowActions = true }: ProductRowProps) {
  const {t} = useTranslation();
  const { i18n } = useTranslation();
  const name = getTranslatedName(product, i18n.language);
  const { order } = useContext(OrderContext);
  const currency = getOrderCurrency(order);

  const checkIfTrailApplies = (product) => {
    if (order.products != undefined && order.products.find((p) => p.product.type == 'BASE') != undefined) {
      var test = order.products.find((p) => p.product.type == 'BASE').product.settings.trial_days;
      var trial = product.settings.trial_days;
      if (test == trial && trial > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false; 
    }
  };
  return (
    <div
      className="highlight"
      key={product._id}
      style={{
        border: '1px solid #dedede',
        backgroundColor: '#fff',
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 5,
      }}
    >
      {!isMobile && (
        <div
          style={{
            width: '20%',
            display: 'flex',
            alignItems: 'center',
            padding: 5,
            paddingLeft: 20,
          }}
        >
          <div className={styles.imageContainer}>
            <Image src={product.external.images[0]?.url} alt={name} layoutClass={styles.image} />
          </div>
        </div>
      )}

      <div
        className={styles.productDetails}
        style={{
          margin: 20,
          width: '60%',
        }}
      >
        <div style={{ display: 'flex' }}>
          {checkIfTrailApplies(product) && (
            <div style={{ color: '#fff', paddingLeft: 10, paddingRight: 10, borderRadius: 5, backgroundColor: '#28b463', fontSize: 14, fontWeight: 'bold', display: 'flex', justifyContent: 'flex-end' }}>
              {t('try')} {product.settings.trial_days} {t('daysforfree')}
            </div>
          )}
        </div>

        <span style={{ fontSize: 22 }}>
          <b>
            {name} ({product.type})
          </b>
        </span>

        <div style={{ marginTop: 0 }}>{!plan && <span>{t('cart_invalid_plan')}</span>}</div>

        {plan && <PlanPriceCart {...{ plan, product }} />}

        {allowActions && (
          <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <div style={{ cursor: 'pointer' }} onClick={() => onProductDelete()}>
              <FontAwesomeIcon icon={faXmark} style={{ marginRight: 5, fontSize: 16 }} />
              <span>{t('cart_buttons_remove')}</span>
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          verticalAlign: 'top',
          width: '20%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            margin: 20,
          }}
        >
          {checkIfTrailApplies(product) && formatPriceWithCurrency(0, currency)}
          {!checkIfTrailApplies(product) && plan && formatPriceWithCurrency(getTotalPlanPrice(plan, currency), currency)}
        </div>
      </div>
    </div>
  );
}
