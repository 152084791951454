import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../Components/Container/Container';
import { Title } from '../Components/Title/Title';
import { CartOverview } from '../Components/CartOverview/CartOverview';
import { CheckoutButton } from '../Components/CheckoutButton/CheckoutButton';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { OrderContext } from '../Contexts';
import styles from './Cart.module.scss';

export function PageCart() {
  const { t } = useTranslation();
  const {
    order: { products },
  } = React.useContext(OrderContext);
  const hasBaseProduct = products.some((p) => p.product.type === 'BASE');
  return (
    <Container size="sm">
      <VerticalSpacer>
        <Title level={1} title={t('cart_title')} />
        {hasBaseProduct ? (
          <>
            <div className={styles.checkoutButtonContainer}>
              <CheckoutButton />
            </div>
            <div style={{ width: '100%' }}>
              <CartOverview />
            </div>

            <div className={styles.checkoutButtonContainer}>
              <CheckoutButton />
            </div>
          </>
        ) : (
          <div style={{ color: 'red' }}>
            <b>{t('cart_nobaseproduct')}</b>
          </div>
        )}
      </VerticalSpacer>
    </Container>
  );
}
