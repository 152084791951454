import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../Components/Container/Container';
import { Title } from '../Components/Title/Title';
import { Paragraph } from '../Components/Paragraph/Paragraph';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { useParams } from 'react-router';
import { sprintf } from 'sprintf-js';
import { PrimaryButton } from '../Components/Button/Button';
import { AuthContext, HashContext } from '../Contexts';
import { Product } from '../interfaces';
import { useCustomerPatchMutation } from '../Services/Customer';

export function PageCancelSubscription() {
  const { client, deployment, service } = React.useContext(HashContext);
  const { customer } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const  id  = useParams(); ///this could be any issue was {id}
  const { isLoading, mutate } = useCustomerPatchMutation({ instance: client, cms: deployment, crm: service });

  if (!customer || typeof customer.subscriptions.base.product !== 'object') {
    return <span>{t('renew_now_no_customer')}</span>;
  }

  const subscriptions = [...customer.subscriptions.extras.map((x) => ({ ...x, type: 'EXTRA' })), ...[{ ...customer.subscriptions.base, type: 'BASE' }]];
  const subscription = subscriptions.find((x) => x._id === id);
  const product = subscription?.product as Product;

  return (
    <>
      <Container>
        <VerticalSpacer>
          <Title level={1} title={sprintf(t('cancel_subscription_title'), product.name)} />
          {!subscription && <Paragraph text={'cancel_subscription_no_subscription_found'} />}
          {subscription && (
            <>
              <Paragraph text={sprintf(t('cancel_subscription_text'), product.name)} />
              <PrimaryButton
                disabled={isLoading}
                onClick={() => { 
                  if (subscription?.type === 'BASE') {
                    customer.subscriptions.base.cancelled = true;
                    return mutate({ id: customer._id, values: customer });
                  }

                  customer.subscriptions.extras = customer.subscriptions.extras.map((x) => ({
                    ...x,
                    cancelled: subscription._id === x._id,
                  }));
                }}
              >
                {t('cancel_subscription_cancel_subscription')}
              </PrimaryButton>
            </>
          )}
        </VerticalSpacer>
      </Container>
    </>
  );
}
