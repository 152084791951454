import { useTranslation } from 'react-i18next';
import { OrderContext } from '../../Contexts';

import { PrimaryButton } from '../Button/Button';
import { useContext } from 'react';
import { PORTAL_VIEWS, useSwitchView } from '../../views';

export function CheckoutButton() {
  const { t } = useTranslation();

  const { switchView } = useSwitchView();
  const {
    order: { products },
    isLoading,
  } = useContext(OrderContext);

  return (
    <>
      {!isLoading && (
        <PrimaryButton
          disabled={products.length === 0}
          onClick={() => {
            switchView(PORTAL_VIEWS.checkout);
          }}
        >
          {t('checkout_button_to_checkout')}
        </PrimaryButton>
      )}
    </>
  );
}
