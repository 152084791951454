import classNames from 'classnames'
import React from 'react'

import styles from './VerticalSpacer.module.scss'

type VerticalSpacerProps = {
  children : React.ReactNode,
  size? : 'md' | 'sm' | 'xs' | 'lg' | 'xl' | 'xxl'
}

export function VerticalSpacer({ children, size } : VerticalSpacerProps) {
  return (
    <div className={classNames(styles.component, {
      [styles.componentSm] : size === 'sm',
      [styles.componentXs] : size === 'xs',
      [styles.componentLg] : size === 'lg',
      [styles.componentXl] : size === 'xl',
      [styles.componentXxl] : size === 'xxl',
    })}>
      { children }
    </div>
  )
}
