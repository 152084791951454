import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Affiliates } from '../interfaces/affiliates';
import { Coupons } from '../interfaces/coupons';

export const getAffiliate = (client: string, deployment: string, service: string, vanity: string) =>
  axios.get<Affiliates>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/marketing/affiliates/affiliates/vanity/${vanity}`, {
    params: { instance: client, cms: deployment, crm: service },
  });

export const useAffiliate = (client: string, deployment: string, service: string, vanity: string) =>
  useQuery(['affiliates', client, deployment, service, vanity], async () => {
    return getAffiliate(client, deployment, service, vanity)
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  });

export const getCoupon = (client: string, deployment: string, service: string, vanity: string) =>
  axios.get<Coupons>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/marketing/coupons/coupons/vanity/${vanity}`, {
    params: { instance: client, cms: deployment, crm: service },
  });

export const useCopuon = (client: string, deployment: string, service: string, vanity: string) =>
  useQuery(['coupons', client, deployment, service, vanity], async () => {
    return getCoupon(client, deployment, service, vanity)
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  });
