import axios from 'axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Order } from '../interfaces';

export const getOrderSession = (client: string, deployment: string, service: string, cart: string, sessionId: string) =>
  axios.get<Order>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/shopping-carts/${cart}/orders/sessions/${sessionId}`, {
    params: { instance: client, cms: deployment, crm: service },
  });

export const startTransaction = (client: string, deployment: string, service: string, cart: string, sessionId: string, orderData: any) =>
  axios.post<{ redirectUrl: string, postFormData?: Record<string, string> }>(
    `${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/shopping-carts/${cart}/orders/sessions/${sessionId}/start-transaction`,
    { orderData },
    {
      params: { instance: client, cms: deployment, crm: service },
    }
  );

export const useOrderSession = (client: string, deployment: string, service: string, cart: string, sessionId: string, options?: Omit<UseQueryOptions<unknown, unknown, Order, string[]>, 'initialData' | 'queryFn' | 'queryKey'>) =>
  useQuery(
    ['cart', client, service, sessionId],
    async () => {
      return getOrderSession(client, deployment, service, cart, sessionId)
        .then((r) => Promise.resolve(r.data))
        .catch((e) => Promise.reject(e));
    },
    options
  );

export const updateOrderSession = (client: string, deployment: string, service: string, cart: string, sessionId: string, session: Order) =>
  axios.patch<Order>(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/shopping-carts/${cart}/orders/sessions/${sessionId}`, session, {
    params: { instance: client, cms: deployment, crm: service },
  });

export const useUpdateMutation = (client: string, deployment: string, service: string, cart: string, sessionId: string, options?: Omit<UseMutationOptions<Order, unknown, Order, unknown>, 'mutationFn'>) =>
  useMutation(async (values: Order) => {
    return updateOrderSession(client, deployment, service, cart, sessionId, values)
      .then((r) => Promise.resolve(r.data))
      .catch((e) => Promise.reject(e));
  }, options);
