import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Components/Container/Container';
import { Title } from '../Components/Title/Title';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { ErrorMessage, Form, PasswordField } from '../Components/Form/Form';
import {  SubmitButton } from '../Components/Form/Form';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { notification } from 'antd';
import { PORTAL_VIEWS, useSwitchView } from '../views';
import { AuthContext, HashContext } from '../Contexts';

export const ChangePassword = () => {
  const [notifier, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const { switchView } = useSwitchView();
  const { client, deployment, service } = React.useContext(HashContext);
  const {
    customer: { _id },
  } = React.useContext(AuthContext);

  const { handleSubmit, register, formState: { errors } } = useForm<{
    oldPassword: string;
    newPassword: string;
  }>();

  return (
    <Container>
      {contextHolder}
      <VerticalSpacer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          <div style={{ width: '50%', marginTop: 25 }}>
            <Title level={1} title={t('change_password_form_title')} />
            <div style={{ marginTop: 20 }}>{t('change_password_form_info')}</div>
            <Form
              onSubmit={handleSubmit(async (data: {}) => {
                const url = new URL(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/customers/customers/password-change`);
                url.searchParams.set('instance', client);
                url.searchParams.set('cms', deployment);
                url.searchParams.set('crm', service);
                try {
                  await axios.post(url.toString(), { ...data, cust: _id });
                  switchView(PORTAL_VIEWS.home);
                } catch (e) {
                  notifier.error({ message: t('change_password_form_incorrect_password') });
                }
              })}
            >
              <div
                style={{
                  marginTop: 50,
                  justifyContent: 'center',
                }}
              >
                <div style={{ marginTop: 25 }}>
                  <PasswordField {...register('oldPassword', { required: true })} label={t('change_password_form_old_password')} />
                </div>
                <div style={{ marginTop: 25 }}>
                  <PasswordField {...register('newPassword', { required: true })} label={t('change_password_form_new_password')} />
                  {/* <ErrorMessage error={errors.newPassword?.message} /> */}
                </div>
                <div style={{ marginTop: 40 }}>
                  <SubmitButton>{t('change_password_form_submit')}</SubmitButton>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </VerticalSpacer>
    </Container>
  );
};
