import axios, { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

type Params = { instance: string; cms: string; crm: string };

 const cancelSubscription = (params: Params, id: string) =>
  axios.patch(`${process.env.REACT_APP_TVMS_API_BASE}/api/v1/shopping-carts/cancel-subscriptions'/${id}`, {
    params,
    withCredentials: true,
  });

export const patchSubscription = cancelSubscription;

export const useCancelSubscriptionMutation = (params: Params, options?: Omit<UseMutationOptions<AxiosResponse, unknown, { id: string;}, unknown>, 'mutationFn'>) =>
  useMutation(async ({ id }: { id: string}) => {
    return patchSubscription(params, id)
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e)); 
  }, options);


  