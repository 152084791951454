import { useContext, useEffect } from 'react';
//import 'antd/dist/antd.css';
import { OrderContext, ShoppingCartContext, HashContext } from '../Contexts';
import { useLocation } from 'react-router-dom';

export const PayfastPaymentContainer = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { order } = useContext(OrderContext);
  const { payment_gateways } = useContext(ShoppingCartContext);
  const { portalId, client, deployment, service } = useContext(HashContext);
  const orderId = new URLSearchParams(search).get('mws');

  const gateway = payment_gateways.find((x) => x._id === order?.payment?.gateway?._id);
  const environment = gateway?.environment ?? 'sandbox';

  const callbackBaseUrl = `${process.env.REACT_APP_TVMS_API_BASE}/api/v1/billing/shopping-carts/${portalId}/orders/sessions/${orderId}/complete-transaction?instance=${client}&cms=${deployment}&crm=${service}&orderId=${searchParams.get('orderId')}`;
  const successUrl = `${callbackBaseUrl}&success=true`;
  const cancelUrl = `${callbackBaseUrl}&success=false`;

  const onScriptLoaded = () => {
    console.log({
      successUrl,
      cancelUrl,
    });
    (window as any).payfast_do_onsite_payment({
      uuid: searchParams.get('uuid'),
      return_url: successUrl,
      cancel_url: cancelUrl,
    });
  };

  useEffect(() => {
    const isLive = environment === 'live';

    const script = document.createElement('script');
    script.src = isLive ? 'https://www.payfast.co.za/onsite/engine.js' : 'https://sandbox.payfast.co.za/onsite/engine.js';
    script.async = true;
    script.onload = onScriptLoaded;

    document.body.appendChild(script);
  }, []);

  return <div></div>;
};
