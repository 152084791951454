/**
 * TODO Check for duplicates
 */
//import { ProductPlansPlan } from '@mwaretv/database/build/backend/models/billing/product/plans';
//import { getTotalPlanPrice } from './ProductUtilities';

import { Order } from '../interfaces';
import { getCartTaxRate } from './PriceUtilities';

export const getOrderCurrency = (order: Order) => {
  //TODO this can never happen
  if (order == undefined) {
    return 'USD';
  }
  return order.customer?.billing_currency ?? order.billingCurrency ?? 'USD';
};

export const getOrderCountry = (order: Order) => {
  //TODO this can never happen
  if (order == undefined) {
    return 'USA';
  }
  return order.customer?.billing_country ?? order.billingCountry ?? 'USA';
};

export const getOrderCountryTaxRate = (order: Order) => {
  //TODO this can never happen
  if (order == undefined) {
    return 0;
  }
  return order.billingTaxrate ?? order.billingTaxrate ?? 0;
};

// export const getOrderSubtotal = (order: Order) => {
//   let price = 0;

//   order.products.forEach((p) => {
//     const product = p.product as any as Product;
//     const plan = product.plans.plans.find((x) => (x as any as ProductPlansPlan & { _id: string })._id === p.plan_id);

//     if (plan) {
//       price += getTotalPlanPrice(plan, getOrderCurrency(order));
//     }
//   });

//   return price;
// };

// export const getOrderTotalWithVat = (order : Order, country: string) => {
//   const totalWithoutVat = getOrderSubtotal(order)
//   const vatRates = getOrderVATRates(order, country)

//   let total = totalWithoutVat

//   Object.values(vatRates).forEach((r) => total += r)

//   return total
// }

// export const getOrderVATRates = (order : Order, country: string) => {
//   let taxRates : {[key : string] : number} = {}

//   order.products.forEach((p) => {
//     const product = p.product as any as Product
//     const plan = product.plans.plans.find(x => (x as any as ProductPlansPlan & { _id : string })._id === p.plan_id)
//     const rate = getCartTaxRate(country) as any;
//     const applicableRate = getApplicableTaxRate(rate)

//     if ( ! Object.keys(taxRates).includes(rate.rates[0].name)) {
//       taxRates[`${rate.rates[0].name}`] = 0
//     }

//     if (plan) {
//       taxRates[`${rate.rates[0].name}`] += (getTotalPlanPrice(plan, getOrderCurrency(order)) * ( applicableRate.percentage / 100 ))
//     }
//   })

//   return taxRates
// }
