import { useTranslation } from 'react-i18next';
import { Container } from '../Components/Container/Container';
import { Title } from '../Components/Title/Title';
import { Paragraph } from '../Components/Paragraph/Paragraph';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { AuthContext, ShoppingCartContext, OrderContext } from '../Contexts';
import { PrimaryButton, TertiereButton } from '../Components/Button/Button';
import { BaseSubscriptionOverview } from '../Components/BaseSubscriptionOverview/BaseSubscriptionOverview';
import { ExtraSubscriptionOverview } from '../Components/ExtraSubscriptionOverview/ExtraSubscriptionOverview';
import dayjs from 'dayjs';
import { DeviceGrid } from '../Components/DeviceGrid/DeviceGrid';
import amazon from '../Assets/Images/amazon.png';
import apple from '../Assets/Images/apple.png';
import google from '../Assets/Images/google.png';
import roku from '../Assets/Images/roku.png';
import lg from '../Assets/Images/lg.png';
import samsung from '../Assets/Images/samsung.png';
import vidaa from '../Assets/Images/vidaa.png';
import apk from '../Assets/Images/apk.png';
import { useContext, useState } from 'react';
import { formatPriceWithCurrency } from '../Utilities/PriceUtilities';
import { Modal } from 'antd';
import { PortalCartFlowExtra } from '../Components/PortalCartFlowExtra/PortalCartFlowExtra';
import { PortalCartFlowRenew } from '../Components/PortalCartFlowRenew/PortalCartFlowRenew';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { Periods, Product } from '../interfaces';
import { AppStore } from '../Components/AppStore/AppStore';

const getRenewalDetails = ({ expiration_date = 0 }) => {
  let daysUntilRenewal = dayjs.unix(expiration_date).endOf('day').diff(dayjs(), 'days');
  if (daysUntilRenewal < 0) {
    daysUntilRenewal = 0;
  }
  const colorRenewal = daysUntilRenewal < 1 ? 'crimson' : daysUntilRenewal < 7 && daysUntilRenewal > 0 ? 'orangered' : 'mediumseagreen';
  return { colorRenewal, daysUntilRenewal };
};

export function DashboardPage() {
  const { customer } = useContext(AuthContext);
  const { baseProducts } = useContext(OrderContext);
  const { app_stores } = useContext(ShoppingCartContext);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { extraProducts } = useContext(OrderContext);
  const [modalOpenRenew, setModalOpenRenew] = useState(false);
  const { colorRenewal, daysUntilRenewal } = getRenewalDetails(customer.subscriptions.base);
  const isInTrialMode = customer.productStatus.toLocaleUpperCase() === 'TRIAL';
  const now = dayjs();
  const expiration = dayjs.unix(customer.subscriptions.base.expiration_date ?? now.unix());
  const isActuallyExpired = customer.subscriptions.base?.expiration_date != null && now.isAfter(expiration);
  const trueSubscriptionStatus = isActuallyExpired ? 'EXPIRED' : customer.subscriptionStatus.toLocaleUpperCase();
  const isExpiredSubscription = trueSubscriptionStatus === 'EXPIRED';
  const isPendingSubscription = trueSubscriptionStatus === 'PENDING';
  const isActiveSubscription = trueSubscriptionStatus === 'ACTIVE';

  const convertRenewModal = () => {
    setModalOpenRenew(true);
  };

  const openAddExtraProductsModal = () => {
    setModalOpen(true);
  };

  const extraSubscriptions = customer.subscriptions.extras.map((x) => ({
    ...x,
    type: 'EXTRA',
  }));

  return (
    <Container>
      <VerticalSpacer>
        <DisplayRenewModal
          open={modalOpenRenew}
          onOk={() => {
            setModalOpenRenew(false);
          }}
          onCancel={() => setModalOpenRenew(false)}
          products={baseProducts}
        />
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
          <div
            style={{
              display: 'flex',
              flex: 2,
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Title level={1} title={t('dashboard_title') + `${customer.first_name} ${customer.last_name}`} />
              <Paragraph text={t('dashboard_welcome_text')} />
            </div>
          </div>
          {!isPendingSubscription && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flex: 1,
              }}
            >
              {(isInTrialMode || customer.subscriptions.base.plan.duration_period_in !== Periods.DAY) && (
                <div
                  style={{
                    display: 'flex',
                    border: '3px solid ' + (isInTrialMode ? 'mediumseagreen' : colorRenewal),
                    backgroundColor: isInTrialMode ? 'mediumseagreen' : colorRenewal,
                    borderRadius: 50,
                    height: 100,
                    width: 100,
                    fontSize: isMobile ? 1125 : 20,
                    justifyContent: 'center',
                    textAlign: 'center',
                    verticalAlign: 'center',
                    alignItems: 'center',
                    color: '#fff',
                  }}
                >
                  <div>
                    <b>
                      {Math.floor(daysUntilRenewal)}
                      <span style={{ fontSize: 12 }}> {t('dashboard_days')}</span>
                    </b>
                    <p style={{ fontSize: 10, marginTop: -5 }}>
                      {!isInTrialMode && <b>{t('dashboard_renewal')} </b>}
                      {isInTrialMode && <b>{t('dashboard_trial')} </b>}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <VerticalSpacer size="sm">
            <div
              style={{
                border: '2px solid #dedede',
                padding: 20,
                marginTop: 20,
                borderRadius: 5,
              }}
            >
              <div style={{ display: 'flex', flexDirection: `${isMobile ? 'column' : 'row'}`, width: '100%', gap: '1rem' }}>
                <div
                  style={{
                    justifyContent: `${isMobile ? 'space-between' : 'center'} `,
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    fontSize: 14,
                  }}
                >
                  <Title level={5} title={t('dashboard_account_status') + ': '} />
                  <div
                    style={{
                      marginLeft: 10,
                      backgroundColor: customer.status === 'active' ? 'mediumseagreen' : 'orange',
                      color: '#fff',
                      fontWeight: 'bold',
                      padding: 10,
                      borderRadius: 5,
                      fontSize: 16,
                      minWidth: '90px',
                      textAlign: 'center',
                    }}
                  >
                    {customer.status.toLocaleUpperCase()}
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: `${isMobile ? 'space-between' : 'center'} `,
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    fontSize: 14,
                  }}
                >
                  <Title level={5} title={t('dashboard_subscription_status') + ': '} />
                  <div
                    style={{
                      marginLeft: 10,
                      backgroundColor: isActiveSubscription ? 'mediumseagreen' : isPendingSubscription ? 'LightSkyBlue' : 'Coral',
                      color: '#fff',
                      fontWeight: 'bold',
                      padding: 10,
                      borderRadius: 5,
                      fontSize: 16,
                      minWidth: '90px',
                      textAlign: 'center',
                    }}
                  >
                    {trueSubscriptionStatus}
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: `${isMobile ? 'space-between' : 'center'} `,
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    fontSize: 14,
                  }}
                >
                  <Title level={5} title={t('dashboard_product_status') + ': '} />
                  <div
                    style={{
                      marginLeft: 10,
                      backgroundColor: 'mediumseagreen',
                      color: '#fff',
                      fontWeight: 'bold',
                      padding: 10,
                      borderRadius: 5,
                      fontSize: 16,
                      minWidth: '90px',
                      textAlign: 'center',
                    }}
                  >
                    {customer.productStatus.toLocaleUpperCase()}
                  </div>
                </div>
              </div>
            </div>
            {daysUntilRenewal < 4 && !isInTrialMode && !isPendingSubscription && (
              <div
                style={{
                  border: '2px solid #dedede',
                  padding: 20,
                  marginTop: 20,
                  borderRadius: 5,
                }}
              >
                <div>
                  <Title level={5} title={t('dashboard_renew')} />
                  <Paragraph text={t('dashboard_renew_text')} />
                  <div style={{ width: `${isMobile ? '100%' : '35%'}`, marginTop: 25 }}>
                    <TertiereButton
                      onClick={() => {
                        convertRenewModal();
                      }}
                    >
                      <div style={{ justifyContent: 'center' }}>
                        <div>{t('account_navigation_renew_now')}</div>
                      </div>
                    </TertiereButton>
                  </div>
                </div>
              </div>
            )}

            {customer.credit_amount !== 0 && (
              <div
                style={{
                  border: '2px solid #dedede',
                  padding: 20,
                  marginTop: 20,
                  borderRadius: 5,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                        fontSize: 16,
                        marginRight: 20,
                      }}
                    >
                      <Title level={5} title={t('dashboard_account_wallet') + ': '} />
                      <div
                        style={{
                          marginLeft: 10,
                          backgroundColor: 'mediumseagreen',
                          color: '#fff',
                          fontWeight: 'bold',
                          padding: 10,
                          borderRadius: 5,
                        }}
                      >
                        100 CREDS
                      </div>
                    </div>
                  </div> */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      textAlign: 'center',
                      alignItems: 'center',
                      fontSize: 16,
                    }}
                  >
                    <Title level={4} title={t('dashboard_account_credits') + ': '} />
                    <div
                      style={{
                        marginLeft: 10,
                        backgroundColor: 'mediumseagreen',
                        color: '#fff',
                        fontWeight: 'bold',
                        padding: 10,
                        borderRadius: 5,
                      }}
                    >
                      {formatPriceWithCurrency(customer.credit_amount, customer.billing_currency)}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              style={{
                border: '2px solid #dedede',
                padding: 20,
                marginTop: 20,
                borderRadius: 5,
              }}
            >
              {/* {moment().unix() < customer.subscriptions.base.activation_date + 86500 && (
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <b>{t('subscriptions_no_changes')}</b>
                  </div>
                )} */}
              <div style={{ marginTop: 20 }}>
                <div style={{ marginBottom: 25 }}>
                  <b>{t('subscriptions_title_base')}</b>
                  <Paragraph text={t('dashboard_base_text')} />
                </div>

                {customer.subscriptions.base && <BaseSubscriptionOverview subscriptions={[customer.subscriptions.base]} showActionButtons allowRenewBase={isInTrialMode} allowChangingBase={!isInTrialMode} />}
              </div>

              {extraProducts.length > 0 && (
                <div style={{ marginTop: 20 }}>
                  {customer.subscriptions.extras.length > 0 && (
                    <div style={{ marginBottom: 25 }}>
                      <b>{t('subscriptions_title_extra')}</b>
                      <Paragraph text={t('dashboard_extra_text')} />
                    </div>
                  )}
                  {extraSubscriptions && <ExtraSubscriptionOverview subscriptions={customer.subscriptions.extras} showActionButtons />}
                  {!isInTrialMode && !isExpiredSubscription && customer.subscriptions.base.expiration_date > moment().unix() && (
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                      <div style={{ marginBottom: 5 }}>
                        <b>{t('dashboard_extra_packages')}</b>
                        <Paragraph text={t('dashboard_addextra_text')} />
                      </div>
                      <div style={{ display: 'flex', flexDirection: `${isMobile ? 'column' : 'row'}`, marginTop: 5 }}>
                        <PrimaryButton
                          onClick={() => {
                            openAddExtraProductsModal();
                          }}
                        >
                          <div style={{ justifyContent: 'center' }}>
                            <div>{t('account_navigation_buy_packages')}</div>
                          </div>
                        </PrimaryButton>
                      </div>
                    </div>
                  )}
                  <DisplayExtraProductsModal
                    open={modalOpen}
                    onOk={() => {
                      setModalOpen(false);
                    }}
                    onCancel={() => setModalOpen(false)}
                    productType={'EXTRA'}
                    products={extraProducts}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                border: '2px solid #dedede',
                padding: 20,
                marginTop: 20,
                borderRadius: 5,
              }}
            >
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <div style={{ marginBottom: 25 }}>
                  <b>{t('devices_title')}</b>
                  <Paragraph text={t('devices_subtext')} />
                </div>
                <DeviceGrid />
              </div>
            </div>
          </VerticalSpacer>
          <div
            style={{
              border: '2px solid #dedede',
              padding: 20,
              marginTop: 20,
              marginBottom: 40,
              borderRadius: 5,
            }}
          >
            <VerticalSpacer size="sm">
              <b>{t('order_complete_install_apps')}</b>
              <Paragraph text={t('order_complete_install_apps_info')} />

              <AppStore text={app_stores.google_text} url={app_stores.google_url} logo={google} />
              <AppStore text={app_stores.apple_text} url={app_stores.apple_url} logo={apple} />
              <AppStore text={app_stores.amazon_text} url={app_stores.amazon_url} logo={amazon} />
              <AppStore text={app_stores.vidaa_text} url={app_stores.vidaa_url} logo={vidaa} />
              <AppStore text={app_stores.lg_text} url={app_stores.lg_url} logo={lg} />
              <AppStore text={app_stores.samsung_text} url={app_stores.samsung_url} logo={samsung} />
              <AppStore text={app_stores.roku_text} url={app_stores.roku_url} logo={roku} />
              <AppStore text={app_stores.apk_text} url={app_stores.apk_url} logo={apk} />
            </VerticalSpacer>
          </div>
        </div>
      </VerticalSpacer>
    </Container>
  );
}
interface DisplayProductModalProps {
  open: boolean;
  onOk: Function;
  onCancel: Function;
  products: Product[];
  productType: 'EXTRA';
}

const DisplayExtraProductsModal = ({ open, onOk, onCancel, products }: DisplayProductModalProps) => {
  return (
    <Modal {...{ open }} footer={null} width={1200} onOk={() => onOk()} onCancel={() => onCancel()}>
      {products && <PortalCartFlowExtra showStartOptions items={products} />}
    </Modal>
  );
};

interface DisplayRenewProductModalProps {
  open: boolean;
  onOk: Function;
  onCancel: Function;
  products: Product[];
}

const DisplayRenewModal = ({ open, onOk, onCancel, products }: DisplayRenewProductModalProps) => {
  return (
    <Modal {...{ open }} footer={null} width={1200} onOk={() => onOk()} onCancel={() => onCancel()}>
      {products && <PortalCartFlowRenew showStartOptions items={products} />}
    </Modal>
  );
};
