import { Payment, initMercadoPago } from '@mercadopago/sdk-react';
import { useContext } from 'react';
import axios from 'axios';
//import 'antd/dist/antd.css';
import { notification } from 'antd';
import { HashContext, OrderContext, ShoppingCartContext } from '../Contexts';
import { v4 } from 'uuid';

export const MercadoPagoPayment = () => {
  const { order } = useContext(OrderContext);
  const { payment_gateways } = useContext(ShoppingCartContext);

  const gateway = payment_gateways.find((x) => x._id === order?.payment?.gateway?._id);
  const publicKey = gateway?.environment_settings[gateway.environment]?.publicKey;

  initMercadoPago(publicKey, {
    locale: 'en-US',
  });

  const [notifier, contextHolder] = notification.useNotification();

  const displayError = () => {
    notifier.open({
      message: 'Payment Error',
      description: `Failed to execute payment.`,
      duration: 5,
    });
  };
  const { client, deployment, service, portalId } = useContext(HashContext);

  const baseUrl = process.env.REACT_APP_TVMS_API_BASE;
  const formSubmitUrl = `${baseUrl}/api/v1/billing/shopping-carts/${portalId}/orders/sessions/${v4()}/start-transaction`;

  const submitData = async (data: any) => {
    try {
      const response = await axios.post(
        formSubmitUrl,
        { orderData: order, mercadopago: data },
        {
          params: { instance: client, cms: deployment, crm: service },
        }
      );
      window.location.replace(response.data.redirectUrl);
    } catch {
      displayError();
    }
  };

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
        {contextHolder}
        <Payment
          initialization={{ amount: 10 }}
          customization={{
            paymentMethods: {
              maxInstallments: 1,
              mercadoPago: 'all',
              creditCard: 'all',
              debitCard: 'all',
              ticket: 'all',
              bankTransfer: 'all',
              atm: 'all',
            },
          }}
          onSubmit={async (param) => {
            submitData(param.formData);
          }}
        />
      </div>
    </div>
  );
};
