import { DesignContext } from '../../Contexts';
import { useContext } from 'react';

type TitleProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  title: string;
};

export function Title({ level, title }: TitleProps) {
  const { headings } = useContext(DesignContext);
  const HeadingTag = `h${level.toString()}` as keyof JSX.IntrinsicElements;

  return (
    <HeadingTag
      style={{
        fontSize: `${headings[`h${level}`]['font-size']}px`,
        
      }}
    >
      {title}
    </HeadingTag>
  );
}
